import { makeAutoObservable, action, runInAction, observable } from 'mobx';
import * as ExcelJS from 'exceljs';

export class SheetStore {
    selectedSheetIndex = 0;
    MAX_SHEETS = 5;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
            selectedSheetIndex: observable
        });
    }

    addSheet = action(() => {
        console.log('Adding sheet, current workbook:', this.currentWorkbook);
        if (!this.currentWorkbook || this.visibleSheets.length >= this.MAX_SHEETS) return;

        // Generate unique sheet name
        const existingSheets = this.visibleSheets;
        let sheetNumber = existingSheets.length + 1;
        let sheetName = `Sheet ${sheetNumber}`;
        
        while (this.currentWorkbook.getWorksheet(sheetName)) {
            sheetNumber++;
            sheetName = `Sheet ${sheetNumber}`;
        }
        
        // Create new sheet
        const newSheet = this.currentWorkbook.addWorksheet(sheetName);
        newSheet.sheetType = 'unknown';
        
        runInAction(() => {
            this._forceWorkbookUpdate();
            this.selectedSheetIndex = this.visibleSheets.length - 1;
            this.rootStore.fileSystemStore.markUnsaved();
        });

        return newSheet;
    });

    removeSheet = action((sheetIndex) => {
        if (!this.currentWorkbook) return;
        
        const sheets = this.visibleSheets;
        const sheet = sheets[sheetIndex];
        
        if (!sheet || sheets.length <= 1) return;

        runInAction(() => {
            this.currentWorkbook.removeWorksheet(sheet.id);
            
            if (this.selectedSheetIndex >= this.visibleSheets.length) {
                this.selectedSheetIndex = Math.max(0, this.visibleSheets.length - 1);
            }
            
            this._forceWorkbookUpdate();
            this.rootStore.fileSystemStore.markUnsaved();
        });
    });

    setSheetType = action((sheetIndex, type) => {
        if (!this.currentWorkbook) return;

        const sheet = this.visibleSheets[sheetIndex];
        if (!sheet) return;

        runInAction(() => {
            sheet.sheetType = type;
            this._updateMetadataSheet();
            this.rootStore.fileSystemStore.markUnsaved();
        });
    });

    updateSheetName = action((sheetIndex, newName) => {
        if (!this.currentWorkbook) return;
        
        const sheet = this.visibleSheets[sheetIndex];
        if (!sheet) return;

        runInAction(() => {
            sheet.name = newName;
            this._forceWorkbookUpdate();
            this.rootStore.fileSystemStore.markUnsaved();
        });
    });

    selectSheet = action((index) => {
        if (index >= 0 && index < this.visibleSheets.length) {
            this.selectedSheetIndex = index;
        }
    });

    moveSheet = action((fromIndex, toIndex) => {
        if (!this.currentWorkbook) return;
        
        const sheets = this.visibleSheets;
        if (fromIndex < 0 || fromIndex >= sheets.length || 
            toIndex < 0 || toIndex >= sheets.length) {
            return;
        }

        runInAction(() => {
            // ExcelJS doesn't have a direct move method, so we need to modify the worksheet order
            const sheet = sheets[fromIndex];
            const targetPosition = sheets[toIndex].orderNo;
            
            // Adjust the order numbers of sheets between fromIndex and toIndex
            if (fromIndex < toIndex) {
                for (let i = fromIndex + 1; i <= toIndex; i++) {
                    sheets[i].orderNo--;
                }
            } else {
                for (let i = toIndex; i < fromIndex; i++) {
                    sheets[i].orderNo++;
                }
            }
            
            sheet.orderNo = targetPosition;
            
            // Update selected index if needed
            if (this.selectedSheetIndex === fromIndex) {
                this.selectedSheetIndex = toIndex;
            } else if (fromIndex < this.selectedSheetIndex && toIndex >= this.selectedSheetIndex) {
                this.selectedSheetIndex--;
            } else if (fromIndex > this.selectedSheetIndex && toIndex <= this.selectedSheetIndex) {
                this.selectedSheetIndex++;
            }

            this._forceWorkbookUpdate();
            this.rootStore.fileSystemStore.markUnsaved();
        });
    });

    // Helper methods
    get currentWorkbook() {
        return this.rootStore.workbookStore.currentWorkbook;
    }

    get visibleSheets() {
        if (!this.currentWorkbook) return [];
        return this.currentWorkbook.worksheets.filter(
            sheet => sheet.name !== '_React2Excel_META_x7z9' && 
                    sheet.state !== 'hidden' && 
                    sheet.state !== 'veryHidden'
        );
    }

    get currentSheet() {
        return this.visibleSheets[this.selectedSheetIndex];
    }

    // Internal methods (prefixed with underscore by convention)
    _forceWorkbookUpdate() {
        const currentIndex = this.rootStore.workbookStore.selectedWorkbookIndex;
        const workbookCopy = this.rootStore.workbookStore.workbooks[currentIndex];
        this.rootStore.workbookStore.workbooks[currentIndex] = Object.assign(new ExcelJS.Workbook(), workbookCopy);
    }

    _updateMetadataSheet() {
        let metaSheet = this.currentWorkbook.getWorksheet('_React2Excel_META_x7z9');
        if (!metaSheet) {
            metaSheet = this.currentWorkbook.addWorksheet('_React2Excel_META_x7z9', { state: 'veryHidden' });
        }

        metaSheet.getCell('A1').value = 'SheetTypes';
        this.visibleSheets.forEach((sheet, index) => {
            metaSheet.getCell(`B${index + 1}`).value = sheet.sheetType || 'unknown';
        });
    }
}