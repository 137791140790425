import React, { useRef, useEffect, useState } from 'react';

const InputDate = ({ value, onChange, onBlur, onEnter, onTab, initialValue }) => {
  const editorRef = useRef(null);
  const lastInternalValue = useRef('');
  const [isValid, setIsValid] = useState(true);
  const isInternalChange = useRef(false);
  const [inputValue, setInputValue] = useState(initialValue || '');

  const parseDate = (text) => {
    // Remove any leading/trailing whitespace
    text = text.trim();
    if (!text) return null;

    // Normalize separators to handle various inputs
    text = text.replace(/[.,\s]/g, '/').replace(/-/g, '/');

    try {
      // Common date patterns
      const patterns = [
        // DD/MM/YYYY or MM/DD/YYYY
        {
          regex: /^(\d{1,2})\/(\d{1,2})\/(\d{4}|\d{2})$/,
          parse: (m) => {
            let [_, n1, n2, year] = m;
            year = year.length === 2 ? '20' + year : year;
            // Try both DD/MM and MM/DD
            const date1 = new Date(year, n2 - 1, n1);
            const date2 = new Date(year, n1 - 1, n2);
            if (date1.getMonth() === n2 - 1 && date1.getDate() === Number(n1)) return date1;
            if (date2.getMonth() === n1 - 1 && date2.getDate() === Number(n2)) return date2;
            return null;
          }
        },
        // YYYY/MM/DD
        {
          regex: /^(\d{4})\/(\d{1,2})\/(\d{1,2})$/,
          parse: (m) => new Date(m[1], m[2] - 1, m[3])
        },
        // Natural language dates
        {
          regex: /^(today|tomorrow|yesterday)$/i,
          parse: (m) => {
            const today = new Date();
            switch (m[1].toLowerCase()) {
              case 'today': return today;
              case 'tomorrow': return new Date(today.setDate(today.getDate() + 1));
              case 'yesterday': return new Date(today.setDate(today.getDate() - 1));
            }
          }
        },
        // Month names: 23 Jan 2024, Jan 23 2024, etc.
        {
          regex: /^(\d{1,2})?[\s/]*([A-Za-z]{3,9})[\s/]*(\d{1,2})?[\s/]*(\d{4}|\d{2})?$/,
          parse: (m) => {
            const months = {
              jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
              jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11,
              january: 0, february: 1, march: 2, april: 3, june: 5,
              july: 6, august: 7, september: 8, october: 9, november: 10, december: 11
            };

            let [_, d1, month, d2, year] = m;
            month = months[month.toLowerCase()];
            if (month === undefined) return null;

            const day = d1 || d2;
            if (!day) return null;

            year = year || new Date().getFullYear();
            year = year.length === 2 ? '20' + year : year;

            return new Date(year, month, day);
          }
        },
        // Simple numbers: 23, 2303, 230324
        {
          regex: /^(\d{1,6})$/,
          parse: (m) => {
            const num = m[1];
            const today = new Date();
            const currentYear = today.getFullYear();

            if (num.length <= 2) {
              // Assume it's today's month and year
              return new Date(currentYear, today.getMonth(), Number(num));
            } else if (num.length === 4) {
              // MMDD format
              const month = Number(num.substring(0, 2)) - 1;
              const day = Number(num.substring(2));
              return new Date(currentYear, month, day);
            } else if (num.length === 6) {
              // YYMMDD format
              const year = 2000 + Number(num.substring(0, 2));
              const month = Number(num.substring(2, 4)) - 1;
              const day = Number(num.substring(4));
              return new Date(year, month, day);
            }
            return null;
          }
        },
        // Add this new pattern before the existing patterns
        {
          regex: /^(monday|tuesday|wednesday|thursday|friday|saturday|sunday)$/i,
          parse: (m) => {
            const weekdays = {
              sunday: 0, monday: 1, tuesday: 2, wednesday: 3,
              thursday: 4, friday: 5, saturday: 6
            };
            
            const today = new Date();
            const targetDay = weekdays[m[1].toLowerCase()];
            const currentDay = today.getDay();
            
            // Calculate days to add
            let daysToAdd = targetDay - currentDay;
            if (daysToAdd <= 0) { // If target day is today or already passed this week
              daysToAdd += 7; // Move to next week
            }
            
            return new Date(today.setDate(today.getDate() + daysToAdd));
          }
        }
      ];

      // Try each pattern
      for (const pattern of patterns) {
        const match = text.match(pattern.regex);
        if (match) {
          const date = pattern.parse(match);
          if (date && !isNaN(date.getTime())) {
            return date;
          }
        }
      }

      // Last resort: try native Date parsing
      const date = new Date(text);
      if (!isNaN(date.getTime())) {
        return date;
      }
    } catch (e) {
      console.log('Date parsing error:', e);
    }

    return null;
  };

  const formatDate = (date) => {
    if (!date) return '';
    // Create date at noon to avoid timezone issues
    const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
    const day = String(localDate.getDate()).padStart(2, '0');
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const year = localDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const isValidDate = (text) => {
    if (!text || text.trim() === '') return true;
    return parseDate(text) !== null;
  };

  const submitChanges = () => {
    if (editorRef.current) {
        const text = editorRef.current.textContent || '';
        
        if (text.trim() === '') {
            onChange(null);
            setIsValid(true);
            return;
        }

        const date = parseDate(text);
        if (date) {
            // Create a date-only ISO string by truncating the time part
            const isoDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            const formattedText = formatDate(date);
            editorRef.current.textContent = formattedText;
            
            onChange({
                text: formattedText,
                date: isoDate,
                isDateOnly: true // Make sure this flag is always set for date-only inputs
            });
            setIsValid(true);
        }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (isValid) {
        submitChanges();
        e.target.blur();
        onEnter?.();
      }
      return;
    }

    if (['Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
      e.stopPropagation();
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (isValid) {
        submitChanges();
        onTab?.(e);
      }
      return;
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      const text = editorRef.current.textContent || '';
      lastInternalValue.current = text;
      setIsValid(isValidDate(text));
    }
  };

  const handleBlur = () => {
    if (isValid) {
      submitChanges();
    }
    onBlur?.();
  };

  useEffect(() => {
    if (editorRef.current && value?.text !== undefined) {
      editorRef.current.textContent = value.text || '';
      lastInternalValue.current = value.text || '';
    }
  }, [value?.text]);

  useEffect(() => {
    if (editorRef.current) {
        editorRef.current.focus();
        // If we're mounting because of a keystroke, add it to the field
        if (!value?.text && window.initialKeystroke) {
            editorRef.current.textContent = window.initialKeystroke;
            window.initialKeystroke = null; // Clear it after use
            handleInput(); // Trigger validation
        } else {
            editorRef.current.textContent = value?.text || '';
        }
        // Position cursor at the end
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(editorRef.current);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
    }
  }, []);

  useEffect(() => {
    if (initialValue && editorRef.current) {
      editorRef.current.textContent = initialValue;
      editorRef.current.focus();
      
      // Set cursor at the end for contentEditable
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editorRef.current);
      range.collapse(false); // collapse to end
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [initialValue]);

  return (
    <div
      ref={editorRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onBlur={handleBlur}
      suppressContentEditableWarning
      style={{
        backgroundColor: !isValid ? '#fee2e2' : 'transparent',
        transition: 'background-color 0.2s'
      }}
      className="w-full p-0 outline-none resize-none whitespace-nowrap overflow-hidden"
    />
  );
};

export default InputDate;