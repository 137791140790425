import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import { BaseViewModel } from './BaseViewModel';
import { ColumnTypes } from '../models/SheetModel';

export class ListViewModel extends BaseViewModel {
    selectedItems = new Set();
    searchTerm = '';
    isProcessing = false;

    constructor(sheetDataStore) {
        super(sheetDataStore);
        
        // Bind methods that need 'this' context
        this.addItem = this.addItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.updateItemText = this.updateItemText.bind(this);
        this.indentItem = this.indentItem.bind(this);
        this.outdentItem = this.outdentItem.bind(this);
        this.toggleSelection = this.toggleSelection.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.setSearchTerm = this.setSearchTerm.bind(this);

        makeObservable(this, {
            selectedItems: observable,
            searchTerm: observable,
            isProcessing: observable,
            items: computed,
            filteredItems: computed,
            isReady: computed,
            isEmpty: computed,
            initializeSheet: action,
            addItem: action,
            toggleItem: action,
            updateItemText: action,
            indentItem: action,
            outdentItem: action,
            setSearchTerm: action,
            toggleSelection: action,
            clearSelection: action,
            deleteSelectedItems: action,
            moveItemUp: action,
            moveItemDown: action,
            addItemAbove: action,
            addItemBelow: action,
            duplicateItem: action,
            deleteItem: action,
            addChildItem: action,
            repairOutlineLevels: action,
            syncOutlineLevels: action
        });

        // Load initial state if needed
        if (this.sheetDataStore.currentSheet === null && !this.sheetDataStore.isLoading) {
            console.log('ListViewModel: Triggering initial load');
            const selectedSheet = this.sheetDataStore.rootStore.sheetStore.selectedSheet;
            if (selectedSheet) {
                this.sheetDataStore.loadSheet(selectedSheet.name);
            }
        }
    }

    get isReady() {
        const hasWorkbook = !!this.sheetDataStore?.service;
        const ready = hasWorkbook && !this.isLoading && this.sheetDataStore?.currentSheet !== null;
        return ready;
    }

    get isEmpty() {
        return this.isReady && this.items.length === 0;
    }

    get items() {
        if (!this.sheetDataStore?.currentSheet) {
            console.log('ListViewModel: No current sheet available');
            return [];
        }
        
        const rows = Array.from(this.sheetDataStore.currentSheet.rows.entries());
        const columns = this.sheetDataStore.currentSheet.columns;
        
        console.log('ListViewModel: Processing rows:', rows);
        console.log('ListViewModel: Available columns:', columns);

        const items = rows.map(([id, data]) => {
            console.log('ListViewModel: Processing row:', id, 'with data:', data);
            
            // Create base item with columnData first
            const item = {
                id,
                text: '',
                done: null,
                outlineLevel: data._outlineLevel || 0,
                columnData: {}
            };

            // Store all column data for reference
            columns.forEach(column => {
                item.columnData[column.heading] = {
                    value: data[column.heading]?.value,
                    type: column.type,
                    columnId: column.id
                };
            });

            console.log('ListViewModel: Row columnData:', item.columnData);

            // Now find first text and checkbox values from columnData
            for (const [heading, cellData] of Object.entries(item.columnData)) {
                if (!item.text && cellData.type?.id === 'SINGLE_LINE_TEXT' && cellData.value) {
                    item.text = cellData.value;
                    console.log('ListViewModel: Found text value:', item.text, 'in column:', heading);
                }
                if (item.done === null && cellData.type?.id === 'CHECKBOX') {
                    item.done = cellData.value ?? false;
                    console.log('ListViewModel: Found checkbox value:', item.done, 'in column:', heading);
                }

                // Break early if we found both
                if (item.text && item.done !== null) break;
            }

            console.log('ListViewModel: Processed item:', item);
            return item;
        });

        console.log('ListViewModel: All items processed:', items);
        return items;
    }

    get filteredItems() {
        if (!this.searchTerm) return this.items;
        const search = this.searchTerm.toLowerCase();
        return this.items.filter(item => 
            item.text.toLowerCase().includes(search)
        );
    }

    async initializeSheet() {
        if (!this.sheetDataStore?.currentSheet) return;
        
        try {
            // Add required columns if no single line text column exists
            const columns = this.sheetDataStore.currentSheet.columns;
            if (!columns.find(col => col.type === ColumnTypes.SINGLE_LINE_TEXT)) {
                const newHeading = `Column ${columns.length + 1}`;
                // await this.sheetDataStore.addColumn(newHeading, ColumnTypes.SINGLE_LINE_TEXT);
            }
        } catch (error) {
            console.error('Error initializing sheet:', error);
            throw error;
        }
    }

    async addItem(text, options = { relativeTo: null, position: 'below' }) {
        if (!this.sheetDataStore) return;
        try {
            console.log('Available columns:', this.sheetDataStore.currentSheet.columns);
            console.log('Looking for column type:', ColumnTypes.SINGLE_LINE_TEXT.id);
            
            const textColumn = this.sheetDataStore.currentSheet.columns.find(col => {
                console.log('Checking column:', col.heading, 'type:', col.type?.id);
                return col.type?.id === ColumnTypes.SINGLE_LINE_TEXT.id;
            });
            
            if (!textColumn) {
                console.error('No text column found');
                return;
            }

            console.log('Adding item with text:', text, 'to column:', textColumn.heading);
            const initialData = {
                [textColumn.id]: text
            };
            const rowId = await this.sheetDataStore.addRow(initialData, options);
            console.log('Row added:', rowId);
            return rowId;
        } catch (error) {
            console.error('Error adding item:', error);
            throw error;
        }
    }

    async toggleItem(itemId) {
        if (!this.sheetDataStore) return;
        try {
            const item = this.items.find(i => i.id === itemId);
            if (item) {
                const column = this.sheetDataStore.currentSheet.columns.find(col => 
                    col.type?.id === ColumnTypes.CHECKBOX.id
                );
                if (column) {
                    // Update local state immediately
                    runInAction(() => {
                        const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
                        if (rowData) {
                            rowData[column.heading] = {
                                value: !item.done,
                                type: column.type
                            };
                        }
                    });
                    // Then persist to backend
                    await this.sheetDataStore.updateCell(itemId, column.id, !item.done);
                }
            }
        } catch (error) {
            console.error('Error toggling item:', error);
            throw error;
        }
    }

    async updateItemText(itemId, text) {
        if (!this.sheetDataStore) return;
        try {
            const textColumn = this.sheetDataStore.currentSheet.columns.find(col => 
                col.type?.id === ColumnTypes.SINGLE_LINE_TEXT.id
            );
            if (textColumn) {
                // Update local state first to avoid UI flicker
                runInAction(() => {
                    const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
                    if (rowData) {
                        // Ensure we're replacing the value, not adding to it
                        rowData[textColumn.heading] = {
                            value: text,
                            type: textColumn.type
                        };
                    }
                });
                
                // Then update backend
                await this.sheetDataStore.updateCell(itemId, textColumn.id, text);
            }
        } catch (error) {
            console.error('Error updating item text:', error);
            throw error;
        }
    }

    async indentItem(itemId) {
        if (!this.sheetDataStore) return;
        try {
            const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
            const currentLevel = rowData._outlineLevel || 0;
            // Limit to Excel's maximum outline level of 7
            if (currentLevel < 7) {
                await this.sheetDataStore.setRowOutlineLevel(itemId, currentLevel + 1);
            }
        } catch (error) {
            console.error('Error indenting item:', error);
            throw error;
        }
    }

    async outdentItem(itemId) {
        if (!this.sheetDataStore) return;
        try {
            const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
            const currentLevel = rowData._outlineLevel || 0;
            if (currentLevel > 0) {
                await this.sheetDataStore.setRowOutlineLevel(itemId, currentLevel - 1);
            }
        } catch (error) {
            console.error('Error outdenting item:', error);
            throw error;
        }
    }

    setSearchTerm(term) {
        console.log('Setting search term:', term);
        this.searchTerm = term;
    }

    toggleSelection(itemId) {
        if (this.selectedItems.has(itemId)) {
            this.selectedItems.delete(itemId);
        } else {
            this.selectedItems.add(itemId);
        }
    }

    clearSelection() {
        this.selectedItems.clear();
    }

    async indentSelectedItems() {
        if (!this.sheetDataStore) return;
        try {
            // Sort items by their position to process them in order
            const sortedItems = Array.from(this.selectedItems)
                .map(id => this.items.find(item => item.id === id))
                .filter(Boolean)
                .sort((a, b) => this.items.indexOf(a) - this.items.indexOf(b));

            for (const item of sortedItems) {
                const itemIndex = this.items.findIndex(i => i.id === item.id);
                if (itemIndex <= 0) continue; // Skip first item

                const previousItem = this.items[itemIndex - 1];
                const currentLevel = item.outlineLevel || 0;
                const previousLevel = previousItem.outlineLevel || 0;

                // Only allow indenting one level deeper than the previous item
                // And respect Excel's maximum outline level of 7
                if (currentLevel <= previousLevel + 1 && currentLevel < 7) {
                    const newLevel = Math.min(previousLevel + 1, currentLevel + 1, 7);
                    runInAction(() => {
                        const rowData = this.sheetDataStore.currentSheet.rows.get(item.id);
                        if (rowData) {
                            rowData._outlineLevel = newLevel;
                        }
                    });
                    await this.sheetDataStore.setRowOutlineLevel(item.id, newLevel);
                }
            }
        } catch (error) {
            console.error('Error indenting selected items:', error);
            throw error;
        }
    }

    async outdentSelectedItems() {
        if (!this.sheetDataStore) return;
        try {
            for (const itemId of this.selectedItems) {
                const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
                const currentLevel = rowData._outlineLevel || 0;
                if (currentLevel > 0) {
                    runInAction(() => {
                        rowData._outlineLevel = currentLevel - 1;
                    });
                    await this.sheetDataStore.setRowOutlineLevel(itemId, currentLevel - 1);
                }
            }
        } catch (error) {
            console.error('Error outdenting selected items:', error);
            throw error;
        }
    }

    async toggleSelectedItems() {
        if (!this.sheetDataStore) return;
        try {
            const column = this.sheetDataStore.currentSheet.columns.find(col => 
                col.type?.id === ColumnTypes.CHECKBOX.id
            );
            if (column) {
                // Determine the new state based on the first selected item
                const firstItem = this.items.find(item => item.id === Array.from(this.selectedItems)[0]);
                const newState = !firstItem.done;

                for (const itemId of this.selectedItems) {
                    runInAction(() => {
                        const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
                        if (rowData) {
                            rowData[column.heading] = {
                                value: newState,
                                type: column.type
                            };
                        }
                    });
                    await this.sheetDataStore.updateCell(itemId, column.id, newState);
                }
            }
        } catch (error) {
            console.error('Error toggling selected items:', error);
            throw error;
        }
    }

    async deleteSelectedItems() {
        if (!this.sheetDataStore) return;
        try {
            // Get all selected items
            const selectedItemIds = Array.from(this.selectedItems);
            
            // For each selected item, get it and all its children
            const allItemsToDelete = new Set();
            for (const itemId of selectedItemIds) {
                const itemWithChildren = this.getItemWithChildren(itemId);
                itemWithChildren.forEach(id => allItemsToDelete.add(id));
            }
            
            // Delete all items in one operation
            await this.sheetDataStore.deleteRows(Array.from(allItemsToDelete));
            this.clearSelection();
        } catch (error) {
            console.error('Error deleting selected items:', error);
            throw error;
        }
    }

    async moveItemToPosition(itemId, destinationIndex) {
        if (!this.sheetDataStore) return;
        
        try {
            runInAction(() => {
                this.isProcessing = true;
            });
            
            // Simply move the item to the destination position
            // No checks for outline levels or parent-child relationships
            await this.sheetDataStore.moveRowToPosition(itemId, destinationIndex);
            console.log(`Moved item ${itemId} to position ${destinationIndex}`);
            
        } catch (error) {
            console.error('Error moving item:', error);
            throw error;
        } finally {
            runInAction(() => {
                this.isProcessing = false;
            });
        }
    }

    async moveItemUp(itemId) {
        if (!this.sheetDataStore) return;
        try {
            const itemIds = this.filteredItems.map(item => item.id);
            const currentIndex = itemIds.indexOf(itemId);
            if (currentIndex > 0) {
                await this.sheetDataStore.moveRowToPosition(itemId, currentIndex - 1);
                console.log('Item moved up:', itemId);
            }
        } catch (error) {
            console.error('Error moving item up:', error);
            throw error;
        }
    }

    async moveItemDown(itemId) {
        if (!this.sheetDataStore) return;
        try {
            const itemIds = this.filteredItems.map(item => item.id);
            const currentIndex = itemIds.indexOf(itemId);
            if (currentIndex < itemIds.length - 1) {
                await this.sheetDataStore.moveRowToPosition(itemId, currentIndex + 1);
                console.log('Item moved down:', itemId);
            }
        } catch (error) {
            console.error('Error moving item down:', error);
            throw error;
        }
    }

    async addItemAbove(itemId) {
        if (!this.sheetDataStore) return;
        try {
            // Get the source item to copy outline level
            const sourceItem = this.items.find(i => i.id === itemId);
            if (!sourceItem) {
                console.error('Source item not found:', itemId);
                return;
            }
            
            // Find the first text column to add content to
            const textColumn = this.sheetDataStore.currentSheet.columns.find(col => 
                col.type?.id === ColumnTypes.SINGLE_LINE_TEXT.id
            );
            
            if (!textColumn) {
                console.error('No text column found');
                return;
            }
            
            const initialData = {
                [textColumn.id]: "New item"
            };
            
            const newItemId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: itemId,
                position: 'above'
            });
            
            // Set the same outline level as the source item
            await this.sheetDataStore.setRowOutlineLevel(newItemId, sourceItem.outlineLevel || 0);
            
            console.log('Item added above:', newItemId, 'with outline level:', sourceItem.outlineLevel);
            return newItemId;
        } catch (error) {
            console.error('Error adding item above:', error);
            throw error;
        }
    }

    async addItemBelow(itemId) {
        if (!this.sheetDataStore) return;
        try {
            // Get the source item to copy outline level
            const sourceItem = this.items.find(i => i.id === itemId);
            if (!sourceItem) {
                console.error('Source item not found:', itemId);
                return;
            }
            
            // Find the first text column to add content to
            const textColumn = this.sheetDataStore.currentSheet.columns.find(col => 
                col.type?.id === ColumnTypes.SINGLE_LINE_TEXT.id
            );
            
            if (!textColumn) {
                console.error('No text column found');
                return;
            }
            
            const initialData = {
                [textColumn.id]: "New item"
            };
            
            const newItemId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: itemId,
                position: 'below'
            });
            
            // Set the same outline level as the source item
            await this.sheetDataStore.setRowOutlineLevel(newItemId, sourceItem.outlineLevel || 0);
            
            console.log('Item added below:', newItemId, 'with outline level:', sourceItem.outlineLevel);
            return newItemId;
        } catch (error) {
            console.error('Error adding item below:', error);
            throw error;
        }
    }

    async duplicateItem(itemId) {
        if (!this.sheetDataStore) return;
        try {
            runInAction(() => {
                this.isProcessing = true;
            });
            
            // Get the item to duplicate
            const item = this.items.find(i => i.id === itemId);
            if (!item) {
                console.error('Item not found for duplication:', itemId);
                return;
            }
            
            // Create a copy of the item's data
            const rowData = this.sheetDataStore.currentSheet.rows.get(itemId);
            if (!rowData) {
                console.error('Row data not found for duplication:', itemId);
                return;
            }
            
            // Prepare data for the new item
            const initialData = {};
            this.sheetDataStore.currentSheet.columns.forEach(column => {
                if (rowData[column.heading]?.value !== undefined) {
                    initialData[column.id] = rowData[column.heading].value;
                }
            });
            
            // Add the duplicated item below the original
            const newItemId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: itemId,
                position: 'below'
            });
            
            // Set the same outline level
            await this.sheetDataStore.setRowOutlineLevel(newItemId, item.outlineLevel || 0);
            
            console.log('Item duplicated:', { original: itemId, new: newItemId });
            return newItemId;
        } catch (error) {
            console.error('Error duplicating item:', error);
            throw error;
        } finally {
            runInAction(() => {
                this.isProcessing = false;
            });
        }
    }

    async deleteItem(itemId) {
        try {
            this.isProcessing = true;
            
            // Check if the item has children
            const itemIndex = this.items.findIndex(item => item.id === itemId);
            if (itemIndex === -1) {
                console.error('Item not found for deletion:', itemId);
                return;
            }
            
            const item = this.items[itemIndex];
            const itemLevel = item.outlineLevel || 0;
            
            // Find all children of this item (items that come after with higher outline level)
            const itemsToDelete = [itemId];
            
            for (let i = itemIndex + 1; i < this.items.length; i++) {
                const currentItem = this.items[i];
                const currentLevel = currentItem.outlineLevel || 0;
                
                // If we find an item with lower or equal level than the target, we've gone past all children
                if (currentLevel <= itemLevel) break;
                
                // Add child to deletion list
                itemsToDelete.push(currentItem.id);
            }
            
            console.log(`Deleting item ${itemId} with ${itemsToDelete.length - 1} children:`, itemsToDelete);
            
            // Delete all items at once using the consolidated method
            if (this.sheetDataStore && typeof this.sheetDataStore.deleteRows === 'function') {
                await this.sheetDataStore.deleteRows(itemsToDelete);
            } else {
                console.error('deleteRows method not available on sheetDataStore');
            }
            
            console.log('Item and children deleted successfully');
        } catch (error) {
            console.error('Error deleting item and children:', error);
            throw error;
        } finally {
            this.isProcessing = false;
        }
    }

    // Helper method to get an item and all its children
    getItemWithChildren(itemId) {
        const items = this.items;
        const itemsToDelete = [];
        
        // Find the index of the target item
        const itemIndex = items.findIndex(item => item.id === itemId);
        if (itemIndex === -1) return [itemId]; // Item not found, just return the ID
        
        const targetItem = items[itemIndex];
        const targetLevel = targetItem.outlineLevel || 0;
        
        // Add the target item
        itemsToDelete.push(itemId);
        
        // Add all children (items that come after with higher outline level)
        for (let i = itemIndex + 1; i < items.length; i++) {
            const currentItem = items[i];
            const currentLevel = currentItem.outlineLevel || 0;
            
            // If we find an item with lower or equal level than the target, we've gone past all children
            if (currentLevel <= targetLevel) break;
            
            itemsToDelete.push(currentItem.id);
        }
        
        return itemsToDelete;
    }

    async addChildItem(itemId) {
        if (!this.sheetDataStore) return;
        try {
            // Get the source item to determine the child's outline level
            const sourceItem = this.items.find(i => i.id === itemId);
            if (!sourceItem) {
                console.error('Source item not found:', itemId);
                return;
            }
            
            // Find the first text column to add content to
            const textColumn = this.sheetDataStore.currentSheet.columns.find(col => 
                col.type?.id === ColumnTypes.SINGLE_LINE_TEXT.id
            );
            
            if (!textColumn) {
                console.error('No text column found');
                return;
            }
            
            // Calculate the new outline level (parent level + 1), respecting Excel's maximum of 7
            const parentLevel = sourceItem.outlineLevel || 0;
            if (parentLevel >= 7) {
                console.warn('Cannot add child: parent already at maximum outline level (7)');
                return;
            }
            const childLevel = Math.min(parentLevel + 1, 7);
            
            // Find the last child of this item to determine insertion position
            const allItems = this.items;
            const sourceIndex = allItems.findIndex(i => i.id === itemId);
            let insertAfterIndex = sourceIndex;
            
            // Look for the last child or descendant
            for (let i = sourceIndex + 1; i < allItems.length; i++) {
                const currentItem = allItems[i];
                // If we find an item with lower or equal level than the source, we've gone past all children
                if ((currentItem.outlineLevel || 0) <= (sourceItem.outlineLevel || 0)) {
                    break;
                }
                insertAfterIndex = i;
            }
            
            // Get the ID of the item to insert after
            const insertAfterId = allItems[insertAfterIndex].id;
            
            const initialData = {
                [textColumn.id]: "New child item"
            };
            
            // Add the new item after the last child (or after the parent if no children)
            const newItemId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: insertAfterId,
                position: 'below'
            });
            
            // Set the outline level to be one more than the parent
            await this.sheetDataStore.setRowOutlineLevel(newItemId, childLevel);
            
            console.log('Child item added:', newItemId, 'with outline level:', childLevel);
            return newItemId;
        } catch (error) {
            console.error('Error adding child item:', error);
            throw error;
        }
    }

    async repairOutlineLevels(applyFixes = false) {
        if (!this.sheetDataStore) return;
        
        try {
            this.isProcessing = true;
            const inconsistencies = await this.sheetDataStore.validateOutlineLevels({ 
                applyFixes: applyFixes 
            });
            
            if (inconsistencies > 0) {
                console.log(`Found ${inconsistencies} outline level inconsistencies`);
                if (applyFixes) {
                    console.log(`Repaired ${inconsistencies} outline level inconsistencies`);
                    // Optionally show a notification to the user
                }
            }
            
            return inconsistencies;
        } catch (error) {
            console.error('Error checking outline levels:', error);
            throw error;
        } finally {
            this.isProcessing = false;
        }
    }

    // New method that returns full item info with levels
    getItemWithChildrenInfo(itemId) {
        const items = this.items;
        const result = [];
        
        // Find the index of the target item
        const itemIndex = items.findIndex(item => item.id === itemId);
        if (itemIndex === -1) return [{ id: itemId, level: 0, isParent: true }];
        
        const targetItem = items[itemIndex];
        const targetLevel = targetItem.outlineLevel || 0;
        
        // Add the target item with its level info
        result.push({
            id: itemId,
            level: targetLevel,
            isParent: true
        });
        
        // Add all children (items that come after with higher outline level)
        for (let i = itemIndex + 1; i < items.length; i++) {
            const currentItem = items[i];
            const currentLevel = currentItem.outlineLevel || 0;
            
            // If we find an item with lower or equal level than the target, we've gone past all children
            if (currentLevel <= targetLevel) break;
            
            // Add child with its relative level difference from parent
            result.push({
                id: currentItem.id,
                level: currentLevel,
                relativeLevelDiff: currentLevel - targetLevel,
                isParent: false
            });
        }
        
        return result;
    }

    // Add this method to force sync outline levels
    async syncOutlineLevels() {
        if (!this.sheetDataStore) return;
        
        try {
            this.isProcessing = true;
            await this.sheetDataStore.syncOutlineLevelsToWorksheet();
            console.log('Outline levels synced successfully');
        } catch (error) {
            console.error('Error syncing outline levels:', error);
            throw error;
        } finally {
            this.isProcessing = false;
        }
    }
}