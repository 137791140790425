import React from 'react';

const stringToColorPair = (text) => {
  // Define semantic color mappings
  const statusColors = {
    completion: {
      terms: [
        'done', 'complete', 'completed', 'finished', 'success',
        'deployed', 'released', 'shipped', 'delivered', 'merged',
        'approved', 'accepted', 'resolved', 'closed', 'implemented', 
        'pass', 'passed', 'ok', 'yes', 'true', 'correct', 'valid',
         'validated'
      ],
      colors: { backgroundColor: '#ecfdf5', textColor: '#047857' }
    },
    inProgress: {
      terms: [
        'progress', 'ongoing', 'working', 'pending',
        'active', 'developing', 'implementing',
        'building', 'coding', 'writing', 'processing', 'running'
      ],
      colors: { backgroundColor: '#eff6ff', textColor: '#1d4ed8' }
    },
    review: {
      terms: [
        'review', 'testing', 'test', 'checking', 'validate',
        'reviewing', 'ready to test', 'feedback', 'qa',
        'verification', 'assessment', 'evaluation', 'inspection',
        'awaiting review', 'needs review', 'under review',
        'awaiting approval','consider'
      ],
      colors: { backgroundColor: '#fefce8', textColor: '#854d0e' }
    },
    blocked: {
      terms: [
        'blocked', 'failed', 'error', 'stop', 'hold',
        'on hold', 'cancelled', 'suspended', 'postponed',
        'delayed', 'interrupted', 'stuck', 'waiting',
        'dependency', 'blocker', 'impediment', 'fail', "nok", 
        'not ok', 'no', 'false', 'incorrect', 'invalid'
      ],
      colors: { backgroundColor: '#fef2f2', textColor: '#b91c1c' }
    },
    planning: {
      terms: [
        'plan', 'todo', 'backlog', 'new', 'draft',
        'proposed', 'idea', 'concept', 'upcoming',
        'scheduled', 'future', 'pending start',
        'to do', 'planned', 'preparation', 'not started'
      ],
      colors: { backgroundColor: '#f8fafc', textColor: '#475569' }
    },
    enhancement: {
      terms: [
        'enhancement', 'feature', 'improvement', 'upgrade',
        'optimize', 'refactor', 'redesign', 'revamp',
        'polish', 'extend', 'expand', 'advanced',
        'premium', 'pro', 'experimental'
      ],
      colors: { backgroundColor: '#faf5ff', textColor: '#7e22ce' }  // Purple variant
    }
  };

  const str = text.toLowerCase().trim();
  
  // First check for exact matches
  for (const category of Object.values(statusColors)) {
    if (category.terms.some(term => str === term)) {
      return category.colors;
    }
  }

  // Then check for partial matches, prioritizing longer terms
  const matches = [];
  for (const [categoryName, category] of Object.entries(statusColors)) {
    for (const term of category.terms) {
      if (str.includes(term)) {
        matches.push({
          term,
          length: term.length,
          colors: category.colors
        });
      }
    }
  }

  // If we found matches, use the longest one
  if (matches.length > 0) {
    matches.sort((a, b) => b.length - a.length);
    return matches[0].colors;
  }

  // If no semantic match, generate color from hash
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 7) - hash) + (hash << 3) - (hash >> 5);
    hash = hash & hash;
  }
  hash = hash + (str.length * 31);

  const hue = Math.abs(hash) % 360;
  const saturation = 65 + (Math.abs(hash >> 6) % 20);
  const bgLightness = 88 + (Math.abs(hash >> 11) % 8);
  const textLightness = 25 + (Math.abs(hash >> 16) % 15);

  const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;
    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = n =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [255 * f(0), 255 * f(8), 255 * f(4)];
  };

  const rgbToHex = (r, g, b) => 
    '#' + [r, g, b].map(x => Math.round(x).toString(16).padStart(2, '0')).join('');

  const [bgR, bgG, bgB] = hslToRgb(hue, saturation, bgLightness);
  const [textR, textG, textB] = hslToRgb(hue, saturation, textLightness);

  return {
    backgroundColor: rgbToHex(bgR, bgG, bgB),
    textColor: rgbToHex(textR, textG, textB)
  };
};

const Badge = ({ text }) => {
  if (!text) return null;
  
  const colorPair = stringToColorPair(text);
  
  const baseStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    borderRadius: '0.375rem',
    fontSize: '0.75rem',
    fontWeight: '500',
    backgroundColor: colorPair.backgroundColor,
    color: colorPair.textColor,
  };

  return (
    <span style={baseStyle}>
      {text}
    </span>
  );
};

export default Badge;
