import React, { useEffect, useCallback } from 'react';

export const InputCheckbox = React.forwardRef(({ 
  checked, 
  onChange, 
  disabled = false, 
  isSelected = false,
  enableKeyboardToggle = true
}, ref) => {
  const handleToggle = useCallback((newValue) => {
    console.log('handleToggle:', { newValue, type: typeof newValue });
    onChange(Boolean(newValue));
  }, [onChange]);

  // Handle keyboard events
  useEffect(() => {
    // Only add the event listener if keyboard toggle is enabled
    if (!enableKeyboardToggle) return;
    
    const handleKeyDown = (e) => {
      if (isSelected && e.key === ' ') {
        e.preventDefault();
        e.stopPropagation(); // Prevent event from bubbling up
        console.log('Space pressed:', { currentValue: checked });
        handleToggle(!checked);
      }
    };

    document.addEventListener('keydown', handleKeyDown, true); // Use capture phase
    return () => document.removeEventListener('keydown', handleKeyDown, true);
  }, [isSelected, checked, handleToggle, enableKeyboardToggle]);

  return (
    <div className="flex h-6 shrink-0 items-center">
      <div className="group grid size-4 grid-cols-1">
        <input
          ref={ref}
          type="checkbox"
          checked={!!checked}
          onChange={(e) => {
            e.stopPropagation(); // Prevent event from bubbling up
            handleToggle(e.target.checked);
          }}
          disabled={disabled}
          className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 cursor-pointer checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
        />
        <svg
          fill="none"
          viewBox="0 0 14 14"
          className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
        >
          <path
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="opacity-0 group-has-[:checked]:opacity-100"
          />
          <path
            d="M3 7H11"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="opacity-0 group-has-[:indeterminate]:opacity-100"
          />
        </svg>
      </div>
    </div>
  );
});
