import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from '@headlessui/react';
import {
    ViewColumnsIcon,
    TableCellsIcon,
    ArrowDownTrayIcon
} from '@heroicons/react/24/outline';
import { SpinnerComponent } from '../Components/General/SpinnerComponent';

export const WorkbookNav = observer(({ 
    workbookName,
    isSaved,
    isSaving,
    onSave,
    onSaveAsCSV,
    view,
    setView,
    hasEditPermission = true,
    onNameChange,
    isCSV,
    isNewWorkbook
}) => {
    console.log('WorkbookNav render:', {
        workbookName,
        isNewWorkbook,
        hasEditPermission,
        isEditable: isNewWorkbook && hasEditPermission
    });

    const [isEditing, setIsEditing] = useState(false);
    const nameRef = useRef(null);

    const displayName = isNewWorkbook 
        ? workbookName 
        : (workbookName || 'Untitled Workbook');

    const isEditable = isNewWorkbook && hasEditPermission;

    const startEditing = () => {
        if (!isEditable) return;
        setIsEditing(true);
    };

    const handleNameChange = (e) => {
        if (!isEditable) return;
        const newName = e.target.innerText.trim();
        if (newName && newName !== workbookName) {
            onNameChange(newName);
        } else {
            e.target.innerText = workbookName;
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (!isEditable) return;
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
        }
        if (e.key === 'Escape') {
            e.target.innerText = workbookName;
            e.target.blur();
        }
    };

    return (
        <nav className="flex items-center px-4 py-2 border-b border-zinc-200">
            <div className="flex items-center">
                <h1 
                    ref={nameRef}
                    className={`
                        text-lg font-semibold text-zinc-700
                        ${isEditable ? 'cursor-text hover:bg-zinc-100/50' : 'cursor-default'}
                        ${isEditing ? 'bg-zinc-100 outline-none' : ''}
                        px-2 py-0.5 -mx-2 rounded
                    `}
                    contentEditable={isEditable}
                    onBlur={handleNameChange}
                    onKeyDown={handleKeyDown}
                    onClick={startEditing}
                    suppressContentEditableWarning
                    spellCheck={false}
                    title={isEditable ? 'Click to edit' : 'File name cannot be changed'}
                >
                    {displayName}
                </h1>
                {isSaving ? (
                    <div className="ml-2">
                        <SpinnerComponent size="sm" color="zinc-400" />
                    </div>
                ) : (
                    !isSaved && <span className="ml-2 text-sm text-zinc-400">(unsaved)</span>
                )}
            </div>

            <div className="flex-grow" />

            {/* View Toggle */}
            <div className="flex items-center mr-4">
                <Switch.Group>
                    <div className="flex items-center">
                        <Switch.Label className="mr-2">
                            <ViewColumnsIcon 
                                className={`h-5 w-5 ${view === 'columns' ? 'text-zinc-700' : 'text-zinc-400'}`}
                            />
                        </Switch.Label>
                        <Switch
                            checked={view === 'columns'}
                            onChange={() => setView(view === 'columns' ? 'grid' : 'columns')}
                            className={`
                                ${view === 'columns' ? 'bg-zinc-700' : 'bg-zinc-200'}
                                relative inline-flex h-6 w-11 items-center rounded-full
                                transition-colors focus:outline-none focus:ring-2
                                focus:ring-zinc-500 focus:ring-offset-2
                            `}
                        >
                            <span
                                className={`
                                    ${view === 'columns' ? 'translate-x-6' : 'translate-x-1'}
                                    inline-block h-4 w-4 transform rounded-full
                                    bg-white transition-transform
                                `}
                            />
                        </Switch>
                        <Switch.Label className="ml-2">
                            <TableCellsIcon 
                                className={`h-5 w-5 ${view === 'grid' ? 'text-zinc-700' : 'text-zinc-400'}`}
                            />
                        </Switch.Label>
                    </div>
                </Switch.Group>
            </div>

            {/* Save Button */}
            <div className="flex items-center space-x-2">
                <button
                    onClick={onSave}
                    disabled={!hasEditPermission}
                    className="px-3 py-1.5 text-sm font-medium text-zinc-600 hover:text-zinc-900"
                >
                    Save
                </button>
                {!isCSV && (
                    <button
                        onClick={onSaveAsCSV}
                        disabled={!hasEditPermission}
                        className="px-3 py-1.5 text-sm font-medium text-zinc-600 hover:text-zinc-900"
                    >
                        Save as CSV
                    </button>
                )}
            </div>
        </nav>
    );
});