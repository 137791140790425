import React from 'react';

// // Basic usage with just initials
// <Avatar name="Tom Cook" />

// // With different sizes
// <Avatar name="Tom Cook" size="xs" />
// <Avatar name="Tom Cook" size="sm" />
// <Avatar name="Tom Cook" size="md" />
// <Avatar name="Tom Cook" size="lg" />
// <Avatar name="Tom Cook" size="xl" />

// // With name and subtitle
// <Avatar 
//   name="Tom Cook" 
//   subtitle="View profile" 
// />

// // Just the avatar without name
// <Avatar 
//   name="Tom Cook" 
//   showName={false} 
// />

// // Single name
// <Avatar name="Thor" />

const stringToColorPair = (text) => {
  // Create a more unique hash by including character positions
  let hash = 0;
  const str = text.toLowerCase();
  
  for (let i = 0; i < str.length; i++) {
    // Include character code, position, and previous hash in calculation
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char + (char * (i + 1));
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Add more entropy based on string characteristics
  hash = hash + 
    (str.length * 31) + 
    (str.split(' ').length * 17) + 
    (str.split('').reverse().join('').charCodeAt(0) * 13);

  // Use different parts of the hash for each component
  const hue = Math.abs(hash) % 360;
  const saturation = 55 + (Math.abs(hash >> 8) % 30); // Range: 55-85
  const bgLightness = 35 + (Math.abs(hash >> 16) % 20); // Range: 35-55

  const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;
    const k = n => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = n =>
      l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [255 * f(0), 255 * f(8), 255 * f(4)];
  };

  const rgbToHex = (r, g, b) => 
    '#' + [r, g, b].map(x => Math.round(x).toString(16).padStart(2, '0')).join('');

  const [bgR, bgG, bgB] = hslToRgb(hue, saturation, bgLightness);

  return rgbToHex(bgR, bgG, bgB);
};

const getInitials = (name) => {
  if (!name) return '';
  
  const parts = name.trim().split(/\s+/);
  if (parts.length === 1) {
    return parts[0].charAt(0).toUpperCase();
  }
  
  return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
};

const sizeClasses = {
  xs: {
    container: 'size-6',
    text: 'text-xs'
  },
  sm: {
    container: 'size-8',
    text: 'text-xs'
  },
  md: {
    container: 'size-10',
    text: 'text-xs'
  },
  lg: {
    container: 'size-12',
    text: 'text-xs'
  },
  xl: {
    container: 'size-14',
    text: 'text-xl'
  }
};

const Avatar = ({ 
  name, 
  size = 'md',
  showName = true,
  subtitle
}) => {
  if (!name) return null;

  const initials = getInitials(name);
  const backgroundColor = stringToColorPair(name);
  const { container: containerSize, text: textSize } = sizeClasses[size];

  const avatarStyle = {
    backgroundColor,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '9999px',
  };

  return (
    <div className="flex items-center">
      <span 
        className={`inline-flex ${containerSize} items-center justify-center rounded-full`}
        style={avatarStyle}
      >
        <span className={`font-medium text-white ${textSize}`}>
          {initials}
        </span>
      </span>
      
      {showName && (
        <div className="ml-1.5">
          <p className="text-xs font-medium text-gray-700">{name}</p>
          {subtitle && (
            <p className="text-xs font-medium text-gray-500">{subtitle}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Avatar;
