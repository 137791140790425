import { makeObservable } from 'mobx';
import { BaseViewModel } from './BaseViewModel';

export class BoardViewModel extends BaseViewModel {
    constructor(sheetDataStore) {
        super(sheetDataStore);
        makeObservable(this, {
            // Add observable properties and actions here
        });
    }

    // get lanes() {
    //     const statusColumn = this.columns.find(col => col.type.id === 'STATUS');
    //     if (!statusColumn) return [];

    //     const lanes = {};
    //     statusColumn.validationOptions.forEach(status => {
    //         lanes[status] = this.data.filter(row => row[statusColumn.id] === status);
    //     });
    //     return lanes;
    // }

    // moveCard = (cardId, newStatus) => {
    //     const statusColumn = this.columns.find(col => col.type.id === 'STATUS');
    //     if (statusColumn) {
    //         this.sheetDataStore.updateCell(cardId, statusColumn.id, newStatus);
    //     }
    // };
}