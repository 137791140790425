import { makeAutoObservable, runInAction, reaction } from 'mobx';
import { Sheet, Column, ColumnTypes } from '../models/SheetModel';
import { v4 as uuidv4 } from 'uuid';
import { CellFormatHelper } from '../helpers/CellFormatHelper';
import { CellValidationHelper } from '../helpers/CellValidationHelper';
import CellOutlineLevelHelper from '../helpers/CellOutlineLevelHelper';

export class SheetDataStore {
    currentSheet = null;
    isLoading = false;
    error = null;

    constructor(rootStore) {
        if (!rootStore) {
            throw new Error('RootStore is required for SheetDataStore');
        }
        
        this.rootStore = rootStore;
        
        // Get the service from workbookStore instead
        this.service = rootStore.workbookStore.currentWorkbook;
        if (!this.service) {
            console.warn('No workbook available yet in SheetDataStore');
        }
        
        makeAutoObservable(this, {
            rootStore: false,
            service: false
        });

        // React to workbook changes
        reaction(
            () => this.rootStore.workbookStore.currentWorkbook,
            (workbook) => {
                console.log('SheetDataStore: Workbook changed:', workbook?.filename);
                this.service = workbook;
            }
        );
    }

    async loadSheet(sheetName, options = { validateOutlineLevels: false }) {
        console.log('SheetDataStore: Start loading sheet:', sheetName);
        
        if (!this.service) {
            console.error('No workbook available to load sheet');
            return;
        }

        runInAction(() => {
            this.isLoading = true;
            this.error = null;
        });

        try {
            const sheet = new Sheet(sheetName);
            const worksheet = this.service.getWorksheet(sheetName);
            
            runInAction(() => {
                if (worksheet) {
                    // Get headers and set up columns with validation
                    const headerRow = worksheet.getRow(1);
                    const columns = [];
                    
                    headerRow.eachCell((cell, colNumber) => {
                        const heading = cell.value?.toString() || `Column ${colNumber}`;
                        const columnType = this._getColumnTypeFromCell(cell);
                        
                        // Get validation options for the column from the first data row
                        let validationOptions = [];
                        if (columnType.hasCustomValidation && worksheet.rowCount > 1) {
                            const firstDataCell = worksheet.getCell(2, colNumber);
                            if (firstDataCell.dataValidation?.formulae?.[0]) {
                                // Parse the validation formula string
                                const formula = firstDataCell.dataValidation.formulae[0];
                                // Remove the outer quotes and split by comma
                                const cleanFormula = formula.replace(/^"(.*)"$/, '$1');
                                validationOptions = cleanFormula.split(',').map(opt => opt.trim());
                            }
                        }

                        // Get column width from Excel
                        const col = worksheet.getColumn(colNumber);
                        const width = col.width || 8.43; // Default Excel column width if not specified

                        const column = new Column(heading, columnType, validationOptions, width);
                        columns.push(column);
                    });

                    // Apply column formats right after creating the columns array
                    this.applyColumnFormats(worksheet, columns);

                    // Now set the columns to the sheet
                    sheet.columns = columns;

                    // Get data rows with validation and outline level
                    worksheet.eachRow((row, rowNumber) => {
                        if (rowNumber > 1) { // Skip header row
                            const rowId = `row-${rowNumber}`;
                            const rowData = {};
                            
                            // Get the outline level directly from Excel without validation
                            const outlineLevel = row.outlineLevel || 0;
                            
                            row.eachCell((cell, colNumber) => {
                                const column = columns[colNumber - 1];
                                if (column) {
                                    let cellValue = cell.value;
                                    
                                    // Handle Date objects regardless of column type
                                    if (cellValue instanceof Date) {
                                        // Check if the time component is midnight or near midnight (timezone variations)
                                        const isDateOnly = cellValue.getHours() === 0 || cellValue.getHours() === 1;
                                        
                                        if (isDateOnly) {
                                            // For date-only values, create a clean date string
                                            const year = cellValue.getFullYear();
                                            const month = String(cellValue.getMonth() + 1).padStart(2, '0');
                                            const day = String(cellValue.getDate()).padStart(2, '0');
                                            
                                            cellValue = {
                                                text: `${day}/${month}/${year}`, // Format as DD/MM/YYYY
                                                date: `${year}-${month}-${day}`,
                                                isDateOnly: true
                                            };
                                        } else {
                                            // For dates with actual time components
                                            const year = cellValue.getFullYear();
                                            const month = String(cellValue.getMonth() + 1).padStart(2, '0');
                                            const day = String(cellValue.getDate()).padStart(2, '0');
                                            const hours = String(cellValue.getHours()).padStart(2, '0');
                                            const minutes = String(cellValue.getMinutes()).padStart(2, '0');
                                            
                                            cellValue = {
                                                text: `${day}/${month}/${year} ${hours}:${minutes}`,
                                                date: cellValue.toISOString(),
                                                isDateOnly: false
                                            };
                                        }
                                    }

                                    if (cell.hyperlink) {
                                        cellValue = {
                                            text: typeof cell.value === 'object' ? 
                                                  cell.value.text || cell.value.toString() : 
                                                  cell.value?.toString() || '',
                                            hyperlink: cell.hyperlink
                                        };
                                    }

                                    // Apply format using helper
                                    CellFormatHelper.applyFormatToCell(cell, column.type.id, cellValue);
                                    
                                    rowData[column.heading] = {
                                        value: cellValue,
                                        type: column.type,
                                        properties: {
                                            comment: cell.note ? {
                                                text: cell.note.text,
                                                author: cell.note.author || null
                                            } : null,
                                            format: {
                                                bold: cell.font?.bold || false,
                                                italic: cell.font?.italic || false,
                                                underline: cell.font?.underline || false,
                                                color: cell.font?.color?.argb || null,
                                                fill: cell.fill?.fgColor?.argb || null,
                                            },
                                            formula: cell.formula || null,
                                            isMerged: cell.isMerged || false,
                                            master: cell.master?.address || null,
                                            validation: cell.dataValidation ? {
                                                type: cell.dataValidation.type,
                                                formulae: cell.dataValidation.formulae,
                                                allowBlank: cell.dataValidation.allowBlank
                                            } : null
                                        }
                                    };
                                }
                            });
                            
                            // Store the outline level exactly as it is in the file
                            rowData._outlineLevel = outlineLevel;
                            
                            // Generate a unique row ID using the row number
                            sheet.rows.set(rowId, rowData);
                        }
                    });
                }

                this.currentSheet = sheet;
            });

            // Only validate outline levels if explicitly requested
            if (options.validateOutlineLevels) {
                await this.validateOutlineLevels(options);
            }
        } catch (error) {
            console.error('Error loading sheet:', error);
            runInAction(() => {
                this.error = error;
            });
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    // Private helpers
    _getSheetData() {
        return {
            columns: this.currentSheet.columns,
            rows: Array.from(this.currentSheet.rows.values())
        };
    }

    async saveChanges() {
        if (!this.service) return;
        
        try {
            const worksheet = this.service.getWorksheet(this.currentSheet.name);
            
            // Save column widths
            this.currentSheet.columns.forEach((column, index) => {
                const excelColumn = worksheet.getColumn(index + 1);
                excelColumn.width = column.width / 7; // Convert pixels back to Excel units
            });

            // Reapply all cell formats based on column types
            Array.from(this.currentSheet.rows.entries()).forEach(([rowId, rowData], rowIndex) => {
                const row = worksheet.getRow(rowIndex + 2); // +2 to skip header
                
                this.currentSheet.columns.forEach((column, colIndex) => {
                    const cell = row.getCell(colIndex + 1);
                    const cellData = rowData[column.heading];

                    // Use CellFormatHelper to apply consistent formatting
                    CellFormatHelper.applyFormatToCell(cell, column.type.id, cellData?.value);
                });
            });

            await this.rootStore.sheetStore._forceWorkbookUpdate();
            console.log('Changes saved successfully');
        } catch (error) {
            console.error('Error saving changes:', error);
            throw error;
        }
    }

    /***************************************
     *              COLUMNS                *
     ***************************************/

    /**
     * Column operations include:
     * - Adding columns
     * - Deleting columns 
     * - Moving columns
     * - Updating column properties
     */

    // Column width settings
    static columnWidthMultiplier = 9.4899169632;
    static defaultColumnWidth = 8.43;
    static minColumnWidth = 4;
    static maxColumnWidth = 30;

    async addColumn(heading, type = ColumnTypes.SINGLE_LINE_TEXT, options = { relativeTo: null, position: 'right' }) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        if (!this.currentSheet.isValidColumnHeading(heading)) {
            throw new Error('Invalid column heading');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const newColumn = new Column(heading, type, [], this.defaultColumnWidth);
        let insertIndex;

        runInAction(() => {
            if (options.relativeTo) {
                const currentIndex = this.currentSheet.columns.findIndex(col => col.id === options.relativeTo);
                if (currentIndex === -1) {
                    throw new Error('Reference column not found');
                }

                insertIndex = options.position === 'left' ? currentIndex : currentIndex + 1;

                // 1. Update local model first
                const columns = [...this.currentSheet.columns];
                columns.splice(insertIndex, 0, newColumn);
                this.currentSheet.columns = columns;

                // 2. Handle Excel worksheet updates and validations (only once)
                CellValidationHelper.handleColumnInsertion(
                    worksheet,
                    insertIndex,
                    this.currentSheet.columns,
                    newColumn
                );

                // 3. Set up validations for the new column
                CellValidationHelper.setupNewColumnValidation(
                    worksheet,
                    insertIndex + 1,
                    type,
                    type.defaultOptions || []
                );

                // 4. Update local row data with preserved types
                this.currentSheet.rows.forEach((rowData, rowId) => {
                    const updatedRowData = {};
                    
                    // Store the original data in order of current columns
                    const originalData = this.currentSheet.columns.map(col => ({
                        heading: col.heading,
                        data: rowData[col.heading] ? { ...rowData[col.heading] } : null
                    }));

                    // Insert new column data at the correct position
                    originalData.splice(insertIndex, 0, {
                        heading: newColumn.heading,
                        data: {
                            value: null,
                            type: type.id
                        }
                    });

                    // Rebuild row data in new order
                    originalData.forEach(({ heading, data }) => {
                        if (data) {
                            updatedRowData[heading] = data;
                        }
                    });

                    // Replace the entire row data
                    this.currentSheet.rows.set(rowId, updatedRowData);
                });
            } else {
                // Add to end
                this.currentSheet.columns = [...this.currentSheet.columns, newColumn];
                insertIndex = this.currentSheet.columns.length - 1;

                // Handle all validation aspects
                CellValidationHelper.handleColumnInsertion(
                    worksheet,
                    insertIndex,
                    this.currentSheet.columns,
                    newColumn
                );

                // Add to Excel worksheet
                const headerCell = worksheet.getCell(1, insertIndex + 1);
                headerCell.value = heading;
                
                // Set validation for new column
                this._setColumnTypeValidation(headerCell, type);

                // Initialize empty cells for all existing rows
                this.currentSheet.rows.forEach((rowData) => {
                    rowData[newColumn.heading] = {
                        value: null,
                        type: type.id
                    };
                });
            }
        });

        // Force workbook update to ensure changes are persisted
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
        return newColumn;
    }

    async deleteColumn(columnId) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const columnIndex = this.currentSheet.columns.indexOf(column);

        runInAction(() => {
            // 1. Handle Excel worksheet updates first
            CellValidationHelper.handleColumnDeletionComplete(
                worksheet,
                columnIndex + 1,  // Excel is 1-based
                this.currentSheet.columns.length
            );

            // 2. Update local model
            this.currentSheet.columns = this.currentSheet.columns.filter(col => col.id !== columnId);

            // 3. Update row data with preserved types
            this.currentSheet.rows.forEach((rowData, rowId) => {
                const updatedRowData = {};
                
                // First, preserve all existing data except deleted column
                Object.keys(rowData).forEach(heading => {
                    if (heading !== column.heading) {
                        updatedRowData[heading] = { ...rowData[heading] };
                    }
                });

                // Replace the entire row data
                this.currentSheet.rows.set(rowId, updatedRowData);
            });
        });

        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    async updateColumnHeading(columnId, newHeading) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const columnIndex = this.currentSheet.columns.indexOf(column) + 1;

        runInAction(() => {
            // Update local model
            const oldHeading = column.heading;
            column.heading = newHeading;

            // Update all row data with new heading
            this.currentSheet.rows.forEach(rowData => {
                if (rowData[oldHeading]) {
                    // Preserve formatting when updating heading
                    rowData[newHeading] = {
                        ...rowData[oldHeading],
                        type: column.type
                    };
                    delete rowData[oldHeading];
                }
            });

            // Update Excel worksheet
            const headerCell = worksheet.getCell(1, columnIndex);
            headerCell.value = newHeading;
            
            // Ensure header cell has standard formatting
            headerCell.alignment = {
                wrapText: false,
                vertical: 'bottom'
            };
            headerCell.font = {
                bold: true, // Headers are typically bold
                italic: false,
                underline: false
            };
        });

        this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    async updateColumnType(columnId, newType) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const columnIndex = this.currentSheet.columns.indexOf(column) + 1;
        const headerCell = worksheet.getCell(1, columnIndex);

        runInAction(() => {
            // Update column type in our model
            column.type = newType;

            // Update data validation in Excel of the header cell
            this._setColumnTypeValidation(headerCell, newType);
            
            // Get column letter for easier cell access
            const columnLetter = worksheet.getColumn(columnIndex).letter;
            
            if (newType.defaultOptions?.length > 0) {
                // Set validation options in our local model first
                column.setValidationOptions(newType.defaultOptions);
                
                // Then apply the validation to Excel
                this.applyDefaultColumnValidation(columnId, newType.defaultOptions);
            } else {
                // Clear validation options in our model
                column.setValidationOptions([]);
                
                // Clear all validations and formatting in this column
                for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
                    const cell = worksheet.getCell(`${columnLetter}${rowNumber}`);
                    cell.dataValidation = null;
                    
                    // Clear rich text formatting
                    if (cell.value && typeof cell.value === 'object' && cell.value.richText) {
                        cell.value = cell.value.richText.map(part => part.text).join('');
                    }
                    
                    // Reset cell formatting
                    cell.alignment = {
                        wrapText: false,
                        vertical: 'bottom'
                    };
                    cell.font = {
                        bold: false,
                        italic: false,
                        underline: false
                    };
                }
            }
            
            // Update all cell types in this column
            this.currentSheet.rows.forEach(rowData => {
                if (rowData[column.heading]) {
                    rowData[column.heading].type = newType;
                    
                    // Clear rich text in our local model if it exists
                    const value = rowData[column.heading].value;
                    if (Array.isArray(value)) {
                        rowData[column.heading].value = value.map(part => part.text).join('');
                    } else if (value && typeof value === 'object' && value.richText) {
                        rowData[column.heading].value = value.richText.map(part => part.text).join('');
                    }
                }
            });
        });

        this.rootStore.fileSystemStore.markUnsaved();
    }

    // New helper method for applying validations directly
    async applyDefaultColumnValidation(columnId, options) {
        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) return;

        const columnIndex = this.currentSheet.columns.indexOf(column) + 1;
        const columnLetter = worksheet.getColumn(columnIndex).letter;

        // Apply validation to all rows except header
        for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
            const cell = worksheet.getCell(`${columnLetter}${rowNumber}`);
            cell.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [`"${options.join(',')}"`]
            };
        }
    }

    async moveColumnToPosition(columnId, targetIndex) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const currentIndex = this.currentSheet.columns.findIndex(col => col.id === columnId);
        if (currentIndex === -1) return;

        // No-op if same position
        if (currentIndex === targetIndex) return;

        runInAction(() => {
            // Handle Excel worksheet updates first
            CellValidationHelper.handleColumnMoveComplete(
                worksheet,
                currentIndex + 1, // Excel is 1-based
                targetIndex + 1,
                this.currentSheet.columns.length
            );

            // Then update local model
            const [movedColumn] = this.currentSheet.columns.splice(currentIndex, 1);
            this.currentSheet.columns.splice(targetIndex, 0, movedColumn);

            // Update row data order
            this.currentSheet.rows.forEach((rowData, rowId) => {
                const updatedRowData = {};
                this.currentSheet.columns.forEach(column => {
                    if (rowData[column.heading]) {
                        updatedRowData[column.heading] = rowData[column.heading];
                    }
                });
                this.currentSheet.rows.set(rowId, updatedRowData);
            });
        });

        this.rootStore.fileSystemStore.markUnsaved();
    }

    async addColumnRelative(columnId, position = 'right') {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const currentIndex = this.currentSheet.columns.findIndex(col => col.id === columnId);
        if (currentIndex === -1) return;

        const insertIndex = position === 'left' ? currentIndex : currentIndex + 1;
        const newHeading = `Column ${this.currentSheet.columns.length + 1}`;
        
        const column = await this.addColumn(newHeading, ColumnTypes.SINGLE_LINE_TEXT, insertIndex);
        return column;
    }

    async updateColumnWidth(columnId, excelUnits) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        runInAction(() => {
            // Update width in our model (store as Excel units)
            column.setWidth(excelUnits);

            // Update Excel worksheet (already in Excel units)
            const columnIndex = this.currentSheet.columns.indexOf(column) + 1;
            const excelColumn = worksheet.getColumn(columnIndex);
            excelColumn.width = excelUnits;
        });

        // Force workbook update to ensure changes are persisted
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    applyColumnFormats(worksheet, columns) {
        if (!worksheet || !columns) return;
        
        columns.forEach((column, index) => {
            const col = worksheet.getColumn(index + 1);
            
            switch (column.type?.id) {
                case 'PERCENTAGE':
                    col.numFmt = '0.00%';
                    break;
                case 'NUMBER':
                    col.numFmt = '#,##0.00';
                    break;
                case 'RATING':
                    col.numFmt = '0';
                    break;
                case 'MULTI_LINE_TEXT':
                case 'SINGLE_LINE_TEXT':
                    col.numFmt = '@';
                    break;
                default:
                    col.numFmt = 'General';
            }
        });
    }

    _getColumnTypeFromCell(cell) {
        try {
            const dataValidation = cell.dataValidation;
            console.log('Checking cell validation:', {
                cellAddress: cell.address,
                hasValidation: !!dataValidation,
                validationType: dataValidation?.type,
                formulaValues: dataValidation?.formulae
            });

            if (!dataValidation || !dataValidation.formulae?.[0]) {
                // No validation exists, set default but don't return yet
                this._setColumnTypeValidation(cell, ColumnTypes.SINGLE_LINE_TEXT);
                return ColumnTypes.SINGLE_LINE_TEXT;
            }

            // Find matching type from our ColumnTypes
            // Remove quotes from the validation value if they exist
            const validationType = dataValidation.formulae[0].replace(/['"]/g, '');
            const matchedType = Object.values(ColumnTypes).find(type => type.label === validationType);

            if (!matchedType) {
                console.warn('Unknown validation type:', validationType);
                // Set default validation but don't return yet
                this._setColumnTypeValidation(cell, ColumnTypes.SINGLE_LINE_TEXT);
                return ColumnTypes.SINGLE_LINE_TEXT;
            }

            return matchedType;
        } catch (error) {
            console.warn('Error getting column type from cell:', error);
            this._setColumnTypeValidation(cell, ColumnTypes.SINGLE_LINE_TEXT);
            return ColumnTypes.SINGLE_LINE_TEXT;
        }
    }

    _setColumnTypeValidation(cell, columnType) {
        try {
            // Create a proper Excel formula for data validation
            // Using quoted string for the validation value
            cell.dataValidation = CellValidationHelper.createColumnTypeValidation(columnType);

            console.log('Set column validation:', {
                cellAddress: cell.address,
                type: columnType.id,
                validationValue: columnType.label,
                formula: `"${columnType.label}"`
            });
        } catch (error) {
            console.error('Error setting column validation:', error);
        }
    }

    // Helper method to get Excel validation for a column type
    _getValidationForType(type) {
        switch (type.id) {
            case ColumnTypes.DATE.id:
                return {
                    type: 'date',
                    operator: 'between',
                    showErrorMessage: true,
                    errorStyle: 'error',
                    errorTitle: 'Invalid Date',
                    error: 'Please enter a valid date'
                };
            case ColumnTypes.NUMBER.id:
                return {
                    type: 'decimal',
                    operator: 'between',
                    showErrorMessage: true,
                    errorStyle: 'error',
                    errorTitle: 'Invalid Number',
                    error: 'Please enter a valid number'
                };
            default:
                return null; // No validation for text columns
        }
    }

    /***************************************
     *               ROWS                  *
     ***************************************/

    /**
     * Row operations include:
     * - Adding rows
     * - Deleting rows
     * - Moving rows
     * - Updating row data
     */
    
    _addRow(sheet, rowData) {
        const rowId = uuidv4();
        sheet.rows.set(rowId, rowData);
        return rowId;
    }

    async addRow(initialData = {}, options = { relativeTo: null, position: 'below' }) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        // Create row data structure with all columns
        const completeRowData = {};
        this.currentSheet.columns.forEach(column => {
            completeRowData[column.heading] = {
                value: initialData[column.id] ?? null,
                type: column.type
            };
        });

        // Generate the row ID outside runInAction
        const newRowId = uuidv4();

        // CRITICAL: Make a complete backup of all row data with their original IDs and outline levels
        // We'll use this to rebuild the map properly
        const originalRows = new Map();
        const allRowIds = Array.from(this.currentSheet.rows.keys());
        
        allRowIds.forEach(id => {
            const rowData = this.currentSheet.rows.get(id);
            // Create a deep copy of each row data
            const rowCopy = {};
            Object.entries(rowData).forEach(([key, value]) => {
                if (typeof value === 'object' && value !== null) {
                    rowCopy[key] = { ...value };
                } else {
                    rowCopy[key] = value;
                }
            });
            originalRows.set(id, rowCopy);
        });
        
        console.log(`[AddRow] Stored complete data for ${originalRows.size} rows before insertion`);

        // Determine where to insert the new row
        let insertIndex = -1;
        let baseOutlineLevel = 0; // Default outline level for new row

        if (options.relativeTo) {
            const currentIndex = allRowIds.indexOf(options.relativeTo);
            if (currentIndex === -1) {
                throw new Error('Reference row not found');
            }
            
            insertIndex = options.position === 'above' ? currentIndex : currentIndex + 1;
            const referenceRowData = originalRows.get(options.relativeTo);
            if (referenceRowData) {
                baseOutlineLevel = referenceRowData._outlineLevel || 0;
            }
        } else {
            // Add to end
            insertIndex = allRowIds.length;
        }

        // Set the outline level for the new row
        completeRowData._outlineLevel = baseOutlineLevel;
        console.log(`[AddRow] Setting new row outline level to ${baseOutlineLevel}`);

        // Update Excel worksheet
        const excelInsertIndex = insertIndex + 2; // +2 for header row
        worksheet.spliceRows(excelInsertIndex, 0, [null]); // Insert empty row
        const newRow = worksheet.getRow(excelInsertIndex);
        newRow.outlineLevel = baseOutlineLevel;
        
        // Set cell values and validations for the new row
        this.currentSheet.columns.forEach((column, columnIndex) => {
            const cell = newRow.getCell(columnIndex + 1);
            
            // Set cell value
            cell.value = completeRowData[column.heading]?.value ?? null;
            
            // Get validation from an existing row if available
            let validation = null;
            if (worksheet.rowCount > 2) {
                // Try to get validation from a nearby row
                const referenceRow = worksheet.getRow(
                    excelInsertIndex === 2 ? 3 : excelInsertIndex - 1
                );
                const referenceCell = referenceRow.getCell(columnIndex + 1);
                if (referenceCell?.dataValidation) {
                    validation = { ...referenceCell.dataValidation };
                }
            }
            
            // Apply validation
            if (validation) {
                cell.dataValidation = validation;
            } else if (column.type.hasCustomValidation && column.type.defaultOptions) {
                cell.dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: [`"${column.type.defaultOptions.join(',')}"`]
                };
            }
        });

        // Now completely rebuild the row map with updated IDs but preserved outline levels
        const newRowMap = new Map();
        const updatedAllRowIds = [...allRowIds];
        updatedAllRowIds.splice(insertIndex, 0, newRowId);
        
        // Create a mapping of original row indices to new indices for renumbering
        const reindexMap = new Map();
        updatedAllRowIds.forEach((id, newIndex) => {
            // Skip the newly added row
            if (id !== newRowId) {
                const oldIndex = allRowIds.indexOf(id);
                reindexMap.set(oldIndex, newIndex);
            }
        });
        
        // First pass: preserve the original IDs and data
        updatedAllRowIds.forEach((id, index) => {
            if (id === newRowId) {
                // Add the new row
                newRowMap.set(newRowId, completeRowData);
            } else {
                // Copy the original row data
                const origData = originalRows.get(id);
                newRowMap.set(id, { ...origData });
            }
        });
        
        // Second pass: handle numeric row IDs (row-X format) that need to be updated
        const finalRowMap = new Map();
        const processedIds = new Set();
        
        updatedAllRowIds.forEach((id, index) => {
            if (processedIds.has(id)) return;
            
            let finalId = id;
            let rowData = newRowMap.get(id);
            
            // If this is a row-X format ID, it may need to be updated
            if (id.startsWith('row-') && id !== newRowId) {
                const newNumericId = `row-${index + 1}`; // +1 because row IDs are 1-based
                
                // Only change the ID if necessary
                if (id !== newNumericId) {
                    console.log(`[AddRow] Updating ID from ${id} to ${newNumericId}`);
                    finalId = newNumericId;
                }
            }
            
            // Important: preserve the outline level exactly as it was in original data
            if (id !== newRowId && originalRows.has(id)) {
                const origLevel = originalRows.get(id)._outlineLevel;
                rowData._outlineLevel = origLevel;
            }
            
            finalRowMap.set(finalId, rowData);
            processedIds.add(id);
        });
        
        // Update the current sheet rows in a single batch
        runInAction(() => {
            this.currentSheet.rows = finalRowMap;
        });

        console.log(`[AddRow] Row map rebuilt with ${finalRowMap.size} rows`);
        
        // Force sync all outline levels to Excel
        await this.syncOutlineLevelsToWorksheet();
        
        // Force workbook update to ensure changes are saved to the file
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        
        this.rootStore.fileSystemStore.markUnsaved();
        return newRowId;
    }

    // Implement deleteRows method that can handle both single and multiple row deletions
    async deleteRows(rowIds) {
        // Handle both single ID and array of IDs
        const idsToDelete = Array.isArray(rowIds) ? rowIds : [rowIds];
        
        if (!this.currentSheet || idsToDelete.length === 0) {
            console.error('No rows specified for deletion');
            return;
        }

        console.log('Deleting rows:', idsToDelete);
        
        try {
            // Sort rowIds in reverse order (highest to lowest)
            // This ensures we delete from bottom to top to avoid shifting issues
            const sortedRowIds = [...idsToDelete].sort((a, b) => {
                const aNum = a.startsWith('row-') ? parseInt(a.substring(4), 10) : Infinity;
                const bNum = b.startsWith('row-') ? parseInt(b.substring(4), 10) : Infinity;
                return bNum - aNum; // Reverse order
            });
            
            // Store the current state of all rows before deletion
            const allRowIds = Array.from(this.currentSheet.rows.keys());
            
            // Store outline levels of all rows
            const outlineLevels = new Map();
            allRowIds.forEach(id => {
                const rowData = this.currentSheet.rows.get(id);
                outlineLevels.set(id, rowData._outlineLevel || 0);
            });
            
            const worksheet = this.service.getWorksheet(this.currentSheet.name);
            if (!worksheet) {
                throw new Error('Worksheet not found');
            }
            
            // Delete each row individually, starting from the bottom
            for (const rowId of sortedRowIds) {
                // Delete from our data model
                this.currentSheet.rows.delete(rowId);
                
                // Find the Excel row number
                let excelRowNumber;
                if (rowId.startsWith('row-')) {
                    excelRowNumber = parseInt(rowId.substring(4), 10);
                } else {
                    // For custom IDs, find the row in Excel
                    let foundRow = null;
                    worksheet.eachRow((row, rowNumber) => {
                        if (rowNumber > 1) { // Skip header row
                            const idCell = row.getCell(1);
                            if (idCell.value === rowId) {
                                foundRow = rowNumber;
                            }
                        }
                    });
                    excelRowNumber = foundRow;
                }
                
                if (!excelRowNumber) {
                    console.warn('Could not find Excel row number for deletion:', rowId);
                    continue;
                }
                
                // Delete the row from Excel
                worksheet.spliceRows(excelRowNumber, 1);
                console.log(`Deleted row ${rowId} (Excel row ${excelRowNumber})`);
            }
            
            // After all deletions, we need to update row IDs and preserve outline levels
            // Get the updated list of row IDs
            const remainingRowIds = Array.from(this.currentSheet.rows.keys());
            
            // Create a mapping of old row IDs to new row IDs
            const updatedRowIds = new Map();
            
            // Update row IDs for rows that start with 'row-'
            remainingRowIds.forEach(id => {
                if (id.startsWith('row-')) {
                    const oldNumber = parseInt(id.substring(4), 10);
                    // Count how many deleted rows were before this one
                    const deletedBefore = sortedRowIds.filter(delId => {
                        if (!delId.startsWith('row-')) return false;
                        const delNumber = parseInt(delId.substring(4), 10);
                        return delNumber < oldNumber;
                    }).length;
                    
                    if (deletedBefore > 0) {
                        const newNumber = oldNumber - deletedBefore;
                        const newId = `row-${newNumber}`;
                        updatedRowIds.set(id, newId);
                    }
                }
            });
            
            // Update the row IDs in our data model and preserve outline levels
            updatedRowIds.forEach((newId, oldId) => {
                if (this.currentSheet.rows.has(oldId)) {
                    const rowData = this.currentSheet.rows.get(oldId);
                    const outlineLevel = outlineLevels.get(oldId);
                    
                    // Create a new entry with the new ID
                    this.currentSheet.rows.set(newId, rowData);
                    this.currentSheet.rows.get(newId)._outlineLevel = outlineLevel;
                    
                    // Delete the old entry
                    this.currentSheet.rows.delete(oldId);
                    
                    console.log(`Updated row ID: ${oldId} -> ${newId} with outline level ${outlineLevel}`);
                }
            });
            
            // Sync the outline levels to the worksheet
            await this.syncOutlineLevelsToWorksheet();
            
            // Mark the file as unsaved
            this.rootStore.fileSystemStore.markUnsaved();
            
            console.log('Rows deleted successfully');
        } catch (error) {
            console.error('Error deleting rows:', error);
            throw error;
        }
    }

    // Remove the deleteRow method since we're consolidating to just deleteRows
    // If deleteRow exists elsewhere in your code, you can keep it as an alias:
    async deleteRow(rowId) {
        return this.deleteRows(rowId);
    }

    async updateRow(rowId, updates) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const rowData = this.currentSheet.rows.get(rowId);
        if (!rowData) {
            throw new Error('Row not found');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        // Validate updates
        for (const [columnId, value] of Object.entries(updates)) {
            const column = this.currentSheet.columns.find(col => col.id === columnId);
            if (column && !column.isValidValue(value)) {
                throw new Error(`Invalid value for column ${column.heading}`);
            }
        }

        const rowIndex = Array.from(this.currentSheet.rows.keys()).indexOf(rowId) + 2; // +2 for header row

        runInAction(() => {
            // Update local model
            Object.entries(updates).forEach(([columnId, value]) => {
                const column = this.currentSheet.columns.find(col => col.id === columnId);
                if (column) {
                    // Use column.heading instead of columnId for accessing rowData
                    rowData[column.heading] = {
                        value,
                        type: column.type
                    };
                }
            });

            // Update Excel worksheet
            const row = worksheet.getRow(rowIndex);
            this.currentSheet.columns.forEach((column, columnIndex) => {
                const cell = row.getCell(columnIndex + 1);
                // Use column.heading to access the value
                cell.value = rowData[column.heading]?.value ?? null;
            });
        });

        this.rootStore.fileSystemStore.markUnsaved();
    }

    async duplicateRows(rowIds) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        // Convert single rowId to array for consistency
        const rowIdsArray = Array.isArray(rowIds) ? rowIds : [rowIds];
        if (rowIdsArray.length === 0) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }
        
        // Store outline levels of ALL rows to ensure completeness
        const allRowIds = Array.from(this.currentSheet.rows.keys());
        const outlineLevels = new Map();
        
        allRowIds.forEach(id => {
            const rowData = this.currentSheet.rows.get(id);
            outlineLevels.set(id, rowData._outlineLevel || 0);
        });

        // Get new row IDs before main operations
        const newRowIds = Array(rowIdsArray.length).fill(0).map(() => uuidv4());

        // Batch operations for better performance
        runInAction(() => {
            // 1. Pre-calculate all new row data
            const newRows = rowIdsArray.map((rowId, index) => {
                const originalData = this.currentSheet.rows.get(rowId);
                if (!originalData) return null;

                // Create a deep copy of the row data, including outline level
                const rowCopy = {};
                
                // Deep copy all properties except special ones
                Object.entries(originalData).forEach(([heading, data]) => {
                    if (heading !== '_outlineLevel') {
                        rowCopy[heading] = {
                            value: data.value,
                            type: data.type
                        };
                    }
                });
                
                // Explicitly copy outline level
                rowCopy._outlineLevel = originalData._outlineLevel || 0;
                
                return {
                    id: newRowIds[index],
                    data: rowCopy
                };
            }).filter(Boolean);

            // 2. Calculate insertion point
            const selectedIndices = rowIdsArray.map(id => allRowIds.indexOf(id)).sort((a, b) => a - b);
            
            let insertAfterIndex;
            if (rowIdsArray.length === 1) {
                // Single row - insert below
                insertAfterIndex = selectedIndices[0];
            } else {
                // Check if rows are consecutive
                const isConsecutive = selectedIndices.every((val, idx, arr) => 
                    idx === 0 || val === arr[idx - 1] + 1
                );
                
                if (isConsecutive) {
                    // Multiple consecutive rows - insert below last selected
                    insertAfterIndex = selectedIndices[selectedIndices.length - 1];
                } else {
                    // Multiple non-consecutive rows - insert at bottom
                    insertAfterIndex = allRowIds.length - 1;
                }
            }

            // 3. Prepare Excel worksheet operations
            const startRow = insertAfterIndex + 3; // +2 for header row, +1 to insert after
            
            // Create empty rows in Excel
            worksheet.spliceRows(startRow, 0, ...Array(newRows.length).fill(null));

            // 4. Batch update Excel cells with values and outline levels
            newRows.forEach((row, index) => {
                const rowNumber = startRow + index;
                const excelRow = worksheet.getRow(rowNumber);
                
                // Set outline level in Excel
                excelRow.outlineLevel = row.data._outlineLevel;

                this.currentSheet.columns.forEach((column, colIndex) => {
                    const cell = excelRow.getCell(colIndex + 1);
                    cell.value = row.data[column.heading]?.value ?? null;
                    
                    // Copy validation from original row if exists
                    const originalRowNumber = selectedIndices[Math.min(index, selectedIndices.length - 1)] + 2;
                    const originalCell = worksheet.getCell(originalRowNumber, colIndex + 1);
                    if (originalCell?.dataValidation) {
                        cell.dataValidation = { ...originalCell.dataValidation };
                    }
                });
            });

            // 5. Insert new rows into our data model
            const updatedRows = new Map();
            let inserted = false;
            
            allRowIds.forEach((id, index) => {
                // Add to map
                updatedRows.set(id, this.currentSheet.rows.get(id));
                
                // If this is where we insert the new rows
                if (index === insertAfterIndex && !inserted) {
                    inserted = true;
                    // Add all new rows
                    newRows.forEach(row => {
                        updatedRows.set(row.id, row.data);
                    });
                }
            });
            
            // In case we're adding to the end
            if (!inserted) {
                newRows.forEach(row => {
                    updatedRows.set(row.id, row.data);
                });
            }
            
            // Update the model with our new map
            this.currentSheet.rows = updatedRows;
            
            // Calculate new IDs for shifted rows
            const updatedRowIds = new Map(); // Map of old ID to new ID for shifted rows
            allRowIds.slice(insertAfterIndex + 1).forEach(id => {
                if (id.startsWith('row-')) {
                    const oldNumber = parseInt(id.substring(4), 10);
                    const newNumber = oldNumber + newRows.length; // Increment by number of inserted rows
                    const newId = `row-${newNumber}`;
                    updatedRowIds.set(id, newId);
                }
            });
            
            // Update the row IDs and preserve outline levels
            updatedRowIds.forEach((newId, oldId) => {
                if (this.currentSheet.rows.has(oldId)) {
                    const rowData = { ...this.currentSheet.rows.get(oldId) };
                    
                    // Get outline level from our stored map
                    const level = outlineLevels.get(oldId);
                    if (level !== undefined) {
                        rowData._outlineLevel = level;
                    }
                    
                    // Add with new ID
                    this.currentSheet.rows.set(newId, rowData);
                    // Delete old ID
                    this.currentSheet.rows.delete(oldId);
                }
            });
            
            // Ensure all rows after insertion have proper Excel outline levels
            for (let i = 0; i < newRows.length; i++) {
                const rowNumber = startRow + i;
                const excelRow = worksheet.getRow(rowNumber);
                excelRow.outlineLevel = newRows[i].data._outlineLevel;
            }
            
            // Update all following rows
            const allRowIdsAfterInsert = Array.from(this.currentSheet.rows.keys());
            for (let i = insertAfterIndex + 1 + newRows.length; i < allRowIdsAfterInsert.length; i++) {
                const rowId = allRowIdsAfterInsert[i];
                const rowData = this.currentSheet.rows.get(rowId);
                const excelRowNumber = i + 2; // +2 for header row
                
                if (rowData) {
                    const excelRow = worksheet.getRow(excelRowNumber);
                    excelRow.outlineLevel = rowData._outlineLevel || 0;
                }
            }
        });
        
        // Perform a full outline level sync to ensure everything is correct
        await this.syncOutlineLevelsToWorksheet();
        
        // Force update in one batch
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
        
        return newRowIds;
    }

    async moveRowToPosition(rowId, targetIndex) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const rowIds = Array.from(this.currentSheet.rows.keys());
        const currentIndex = rowIds.indexOf(rowId);
        if (currentIndex === -1) return;

        // No-op if same position
        if (currentIndex === targetIndex) return;

        // Store outline levels of both rows before moving
        const sourceExcelRow = currentIndex + 2;
        const targetExcelRow = targetIndex + 2;
        const sourceRow = worksheet.getRow(sourceExcelRow);
        let sourceOutlineLevel = CellOutlineLevelHelper.getOutlineLevel(sourceRow);

        // Validate outline level for the new position
        sourceOutlineLevel = CellOutlineLevelHelper.validateMovingRowLevel(
            worksheet,
            targetExcelRow,
            sourceOutlineLevel
        );

        runInAction(() => {
            // Update local model
            const [movedId] = rowIds.splice(currentIndex, 1);
            rowIds.splice(targetIndex, 0, movedId);

            const newRows = new Map();
            rowIds.forEach(id => {
                newRows.set(id, this.currentSheet.rows.get(id));
            });
            this.currentSheet.rows = newRows;

            // Update Excel worksheet
            const sourceRowData = sourceRow.values;

            // If moving down
            if (currentIndex < targetIndex) {
                // Move all rows between source and target up one position
                for (let i = sourceExcelRow; i < targetExcelRow; i++) {
                    const nextRow = worksheet.getRow(i + 1);
                    worksheet.getRow(i).values = nextRow.values;
                    CellOutlineLevelHelper.copyOutlineLevel(nextRow, worksheet.getRow(i));
                }
                // Place source row at target position with validated outline level
                worksheet.getRow(targetExcelRow).values = sourceRowData;
                worksheet.getRow(targetExcelRow).outlineLevel = sourceOutlineLevel;
            }
            // If moving up
            else {
                // Move all rows between target and source down one position
                for (let i = sourceExcelRow; i > targetExcelRow; i--) {
                    const prevRow = worksheet.getRow(i - 1);
                    worksheet.getRow(i).values = prevRow.values;
                    CellOutlineLevelHelper.copyOutlineLevel(prevRow, worksheet.getRow(i));
                }
                // Place source row at target position with validated outline level
                worksheet.getRow(targetExcelRow).values = sourceRowData;
                worksheet.getRow(targetExcelRow).outlineLevel = sourceOutlineLevel;
            }
        });

        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    async moveRowsToPosition(rowIds, targetIndex) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const allRowIds = Array.from(this.currentSheet.rows.keys());
        const totalRows = allRowIds.length;

        // Validate target index
        if (targetIndex < 0 || targetIndex > totalRows) {
            throw new Error('Invalid target position');
        }

        // Sort rowIds based on their current position to maintain relative order
        const sortedRowIds = rowIds.sort((a, b) => {
            return allRowIds.indexOf(a) - allRowIds.indexOf(b);
        });

        // Store outline levels of moving rows
        const movingRowLevels = new Map();
        sortedRowIds.forEach(rowId => {
            const currentIndex = allRowIds.indexOf(rowId);
            const excelRow = worksheet.getRow(currentIndex + 2);
            movingRowLevels.set(rowId, CellOutlineLevelHelper.getOutlineLevel(excelRow));
        });

        // Calculate adjusted target index
        let adjustedTargetIndex = targetIndex;
        sortedRowIds.forEach(rowId => {
            const currentIndex = allRowIds.indexOf(rowId);
            if (currentIndex < targetIndex) {
                adjustedTargetIndex--;
            }
        });

        adjustedTargetIndex = Math.max(0, Math.min(adjustedTargetIndex, totalRows - sortedRowIds.length));

        // Add this logging before processing
        console.log('[OutlineLevel] Moving rows:', {
            rowIds,
            targetIndex,
            movingRowLevels: Array.from(movingRowLevels.entries())
        });

        runInAction(() => {
            // 1. Store the rows to be moved
            const movingRows = new Map();
            sortedRowIds.forEach(rowId => {
                movingRows.set(rowId, this.currentSheet.rows.get(rowId));
            });

            // 2. Create new row order
            const newRowIds = allRowIds.filter(id => !movingRows.has(id));
            newRowIds.splice(adjustedTargetIndex, 0, ...sortedRowIds);

            // 3. Create new rows Map with the updated order
            const newRows = new Map();
            newRowIds.forEach(rowId => {
                newRows.set(rowId, movingRows.has(rowId) 
                    ? movingRows.get(rowId) 
                    : this.currentSheet.rows.get(rowId)
                );
            });

            // 4. Update Excel worksheet
            // Store all existing rows' data and outline levels (except moving rows)
            const existingRows = new Map();
            allRowIds.forEach((rowId, index) => {
                if (!movingRows.has(rowId)) {
                    const row = worksheet.getRow(index + 2);
                    existingRows.set(rowId, {
                        values: row.values,
                        outlineLevel: CellOutlineLevelHelper.getOutlineLevel(row)
                    });
                }
            });

            // Clear all rows except header
            for (let i = worksheet.rowCount; i > 1; i--) {
                worksheet.spliceRows(i, 1);
            }

            // Add rows in new order
            newRowIds.forEach((rowId, index) => {
                const rowNumber = index + 2; // +2 for header row
                const rowData = newRows.get(rowId);
                const values = this.currentSheet.columns.map(column => 
                    rowData[column.heading]?.value ?? null
                );
                
                worksheet.insertRow(rowNumber, values);
                const row = worksheet.getRow(rowNumber);
                
                // Set outline level - use stored level for moving rows, or keep existing level
                let outlineLevel;
                if (movingRowLevels.has(rowId)) {
                    outlineLevel = movingRowLevels.get(rowId);
                    
                    // Special handling for top-level items (level 0)
                    if (outlineLevel === 0) {
                        // Level 0 items can be placed anywhere
                        row.outlineLevel = 0;
                    } else {
                        // For non-top-level items, validate the level
                        if (index === 0 || !movingRowLevels.has(newRowIds[index - 1])) {
                            outlineLevel = CellOutlineLevelHelper.validateMovingRowLevel(
                                worksheet,
                                rowNumber,
                                outlineLevel
                            );
                            
                            // Store the adjustment factor to apply to all children
                            const adjustmentFactor = outlineLevel - movingRowLevels.get(rowId);
                            
                            // Apply the same adjustment to all subsequent moved rows to maintain relative levels
                            if (adjustmentFactor !== 0) {
                                for (let i = index + 1; i < newRowIds.length; i++) {
                                    const nextRowId = newRowIds[i];
                                    if (movingRowLevels.has(nextRowId)) {
                                        movingRowLevels.set(
                                            nextRowId, 
                                            Math.max(0, movingRowLevels.get(nextRowId) + adjustmentFactor)
                                        );
                                    } else {
                                        break; // Stop at the first non-moved row
                                    }
                                }
                            }
                        } 
                        // For subsequent moved rows, ensure they maintain proper parent-child relationship
                        else {
                            const prevRowLevel = row.worksheet.getRow(rowNumber - 1).outlineLevel || 0;
                            outlineLevel = Math.min(outlineLevel, prevRowLevel + 1);
                        }
                        row.outlineLevel = outlineLevel;
                    }
                } else if (existingRows.has(rowId)) {
                    outlineLevel = existingRows.get(rowId).outlineLevel;
                    row.outlineLevel = outlineLevel || 0;
                } else {
                    row.outlineLevel = 0; // Default to level 0 for new rows
                }
            });

            // 5. Update the store
            this.currentSheet.rows = newRows;
        });

        // After all rows have been moved and updated
        // Ensure outline levels are correctly synced to the worksheet
        await this.syncOutlineLevelsToWorksheet();
        
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    async setRowOutlineLevel(rowId, level) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        // Get row index and check if it exists
        const rowIds = Array.from(this.currentSheet.rows.keys());
        const rowIndex = rowIds.indexOf(rowId);
        if (rowIndex === -1) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const excelRowIndex = rowIndex + 2; // +2 for header row
        const validatedLevel = CellOutlineLevelHelper.validateMovingRowLevel(
            worksheet,
            excelRowIndex,
            level
        );

        runInAction(() => {
            // Update Excel worksheet
            const row = worksheet.getRow(excelRowIndex);
            row.outlineLevel = validatedLevel;

            // Update local model
            const rowData = this.currentSheet.rows.get(rowId);
            rowData._outlineLevel = validatedLevel;
        });

        this.rootStore.fileSystemStore.markUnsaved();
    }

    // Helper method to check if rows are consecutive
    _areRowsConsecutive(rowIds) {
        const allRowIds = Array.from(this.currentSheet.rows.keys());
        const indices = rowIds.map(id => allRowIds.indexOf(id)).sort((a, b) => a - b);
        
        // Check if each index is exactly one more than the previous
        return indices.every((val, idx, arr) => 
            idx === 0 || val === arr[idx - 1] + 1
        );
    }

    async batchSetRowOutlineLevels(rowIds, operation) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        // Validate rows are consecutive
        if (!this._areRowsConsecutive(rowIds)) {
            throw new Error('Can only perform batch indent/outdent on consecutive rows');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const allRowIds = Array.from(this.currentSheet.rows.keys());
        
        // Sort rowIds by their position in the sheet
        const sortedRowIds = rowIds.sort((a, b) => 
            allRowIds.indexOf(a) - allRowIds.indexOf(b)
        );

        // Get the row above the first selected row for indent validation
        const firstRowIndex = allRowIds.indexOf(sortedRowIds[0]);
        const rowAboveId = firstRowIndex > 0 ? allRowIds[firstRowIndex - 1] : null;
        const rowAboveLevel = rowAboveId 
            ? (this.currentSheet.rows.get(rowAboveId)._outlineLevel || 0)
            : -1;

        runInAction(() => {
            let previousLevel = rowAboveLevel;

            for (const rowId of sortedRowIds) {
                const rowData = this.currentSheet.rows.get(rowId);
                const currentLevel = rowData._outlineLevel || 0;
                const rowIndex = allRowIds.indexOf(rowId);
                
                let newLevel;
                if (operation === 'indent') {
                    // Can only indent one level more than previous row
                    if (currentLevel <= previousLevel + 1) {
                        newLevel = Math.min(currentLevel + 1, previousLevel + 1);
                    } else {
                        continue; // Skip this row if it can't be indented
                    }
                } else {
                    // Outdent
                    newLevel = Math.max(0, currentLevel - 1);
                }

                // Apply the change if valid
                if (newLevel >= 0 && newLevel <= 8) {
                    const excelRowIndex = rowIndex + 2;
                    const row = worksheet.getRow(excelRowIndex);
                    row.outlineLevel = newLevel;
                    rowData._outlineLevel = newLevel;
                    previousLevel = newLevel; // Update previous level for next iteration
                }
            }
        });

        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    /***************************************
     *               CELLS                 *
     ***************************************/

    /**
     * Cell operations include:
     * - Updating cell values
     * - Validating cell data
     * - Getting cell metadata
     * - Setting cell formatting
     */

    async updateCell(rowId, columnOrId, value, properties = {}) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const rowData = this.currentSheet.rows.get(rowId);
        if (!rowData) return;

        // Find the column object if we were passed an ID
        let column = columnOrId;
        if (typeof columnOrId === 'string') {
            column = this.currentSheet.columns.find(c => c.id === columnOrId);
            if (!column) {
                console.error('Column not found by ID:', columnOrId);
                throw new Error('Column not found in sheet');
            }
        }

        // Get the exact column index (0-based)
        const columnIndex = this.currentSheet.columns.indexOf(column);
        if (columnIndex === -1) {
            console.error('Column not found in sheet array:', column);
            throw new Error('Column not found in sheet');
        }

        // Get the exact row index (0-based, excluding header)
        const rowIndex = Array.from(this.currentSheet.rows.keys()).indexOf(rowId) + 2; // +2 for header and 1-based
        if (rowIndex < 2) {
            console.error('Invalid row index:', rowIndex);
            throw new Error('Invalid row index');
        }

        console.log('Updating cell:', {
            rowId,
            rowIndex,
            columnIndex,
            columnHeading: column.heading,
            value
        });

        runInAction(() => {
            // Update local model
            rowData[column.heading] = {
                value,
                type: column.type,
                properties: {
                    ...rowData[column.heading]?.properties,
                    ...properties
                }
            };

            // Update Excel worksheet - get row first, then cell
            const row = worksheet.getRow(rowIndex);
            const cell = row.getCell(columnIndex + 1); // +1 for Excel's 1-based indexing
            
            console.log('Accessing cell:', {
                address: cell.address,
                rowNumber: cell.row.number,
                colNumber: cell.col
            });
            
            // Set the cell value
            if (value && (column?.type?.id === 'DATE' || column?.type?.id === 'START_DATE' || column?.type?.id === 'END_DATE')) {
                if (value.isDateOnly) {
                    const [year, month, day] = value.date.split('-').map(Number);
                    const date = new Date(Date.UTC(year, month - 1, day));
                    cell.value = date;
                } else {
                    cell.value = new Date(value.date);
                }
            } else if (value && (column?.type?.id === 'URL' || column?.type?.id === 'EMAIL')) {
                cell.value = {
                    text: value.text,
                    hyperlink: value.hyperlink
                };
            } else if (Array.isArray(value)) {
                cell.value = {
                    richText: value.map(part => ({
                        text: part.text,
                        font: part.font
                    }))
                };
            } else {
                cell.value = value;
            }

            // Apply format using helper
            CellFormatHelper.applyFormatToCell(cell, column.type.id, value);

            // Apply additional properties
            if (properties.comment) {
                cell.note = properties.comment;
            }

            if (properties.format) {
                cell.font = {
                    bold: properties.format.bold,
                    italic: properties.format.italic,
                    underline: properties.format.underline,
                    color: properties.format.color ? { argb: properties.format.color } : undefined
                };

                if (properties.format.fill) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: properties.format.fill }
                    };
                }
            }

            if (properties.validation) {
                cell.dataValidation = properties.validation;
            }
        });

        this.rootStore.fileSystemStore.markUnsaved();
    }

    async updateColumnValidationOptions(columnId, options) {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const column = this.currentSheet.columns.find(col => col.id === columnId);
        if (!column || !column.type.hasCustomValidation) return;

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        const columnIndex = this.currentSheet.columns.indexOf(column);

        runInAction(() => {
            // Update column validation options in our model
            column.setValidationOptions(options);

            // Clear any invalid values and their formatting
            this.currentSheet.rows.forEach((rowData, rowId) => {
                const cellData = rowData[column.heading];
                if (cellData && !column.isValidValue(cellData.value)) {
                    // Reset invalid values and clear formatting
                    cellData.value = null;
                    
                    // Update Excel cell
                    const rowIndex = Array.from(this.currentSheet.rows.keys()).indexOf(rowId) + 2;
                    const cell = worksheet.getRow(rowIndex).getCell(columnIndex + 1);
                    cell.value = null;
                    cell.alignment = {
                        wrapText: false,
                        vertical: 'bottom'
                    };
                    cell.font = {
                        bold: false,
                        italic: false,
                        underline: false
                    };
                }
            });
        });

        // Update Excel worksheet
        const columnLetter = worksheet.getColumn(columnIndex + 1).letter;
        
        // Create the validation formula string
        // Excel expects: "option1,option2,option3" (single quotes around the entire list)
        const validationFormula = `"${options.join(',')}"`;
        
        // Skip header row (row 1) when applying validation
        for (let rowNumber = 2; rowNumber <= worksheet.rowCount; rowNumber++) {
            const cell = worksheet.getCell(`${columnLetter}${rowNumber}`);
            cell.dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [validationFormula]
            };
        }

        // Use the workbook store to force an update
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        this.rootStore.fileSystemStore.markUnsaved();
    }

    async validateOutlineLevels(options = { applyFixes: false }) {
        if (!this.currentSheet) return 0;
        
        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) return 0;
        
        console.log('Validating outline levels for all rows...');
        let inconsistencies = 0;
        
        // Process rows in order
        const rowIds = Array.from(this.currentSheet.rows.keys());
        let previousLevel = 0;
        
        for (let i = 0; i < rowIds.length; i++) {
            const rowId = rowIds[i];
            const rowData = this.currentSheet.rows.get(rowId);
            const currentLevel = rowData._outlineLevel || 0;
            
            // A row can only be one level deeper than the previous row
            const validLevel = Math.min(currentLevel, previousLevel + 1);
            
            if (validLevel !== currentLevel) {
                console.log(`Found outline level inconsistency for row ${i+1}: ${currentLevel} (should be ${validLevel})`);
                inconsistencies++;
                
                // Only fix if explicitly requested
                if (options.applyFixes) {
                    console.log(`Fixing outline level for row ${i+1}: ${currentLevel} -> ${validLevel}`);
                    
                    // Update Excel worksheet
                    const excelRowIndex = i + 2; // +2 for header row
                    const row = worksheet.getRow(excelRowIndex);
                    row.outlineLevel = validLevel;
                    
                    // Update local model
                    rowData._outlineLevel = validLevel;
                }
            }
            
            // Use the original level for the next comparison if not fixing
            previousLevel = options.applyFixes ? validLevel : currentLevel;
        }
        
        if (inconsistencies > 0) {
            console.log(`Found ${inconsistencies} outline level inconsistencies`);
            if (options.applyFixes) {
                console.log(`Fixed ${inconsistencies} outline level inconsistencies`);
                await this.rootStore.sheetStore._forceWorkbookUpdate();
                this.rootStore.fileSystemStore.markUnsaved();
            }
        } else {
            console.log('All outline levels are consistent');
        }
        
        return inconsistencies;
    }

    // Improve the syncOutlineLevelsToWorksheet method to be more robust
    async syncOutlineLevelsToWorksheet() {
        if (!this.currentSheet) {
            throw new Error('No sheet selected');
        }

        const worksheet = this.service.getWorksheet(this.currentSheet.name);
        if (!worksheet) {
            throw new Error('Worksheet not found');
        }

        // Get all row IDs in the current sheet order
        const allRowIds = Array.from(this.currentSheet.rows.keys());
        
        console.log('[OutlineSync] Starting outline level sync for', allRowIds.length, 'rows');
        
        // Ensure Excel worksheet has enough rows
        if (worksheet.rowCount < allRowIds.length + 1) {
            worksheet.addRows(new Array(allRowIds.length + 1 - worksheet.rowCount).fill(null));
        }
        
        // Synchronize from model to Excel worksheet
        for (let i = 0; i < allRowIds.length; i++) {
            const rowId = allRowIds[i];
            const rowData = this.currentSheet.rows.get(rowId);
            const excelRowNumber = i + 2; // +2 for header row
            
            if (!rowData) {
                console.warn(`[OutlineSync] Missing row data for ID ${rowId}`);
                continue;
            }
            
            // Get the outline level, default to 0
            const level = typeof rowData._outlineLevel === 'number' ? rowData._outlineLevel : 0;
            
            try {
                // Get or create the Excel row
                const row = worksheet.getRow(excelRowNumber);
                
                // Set Excel row outline level directly
                row.outlineLevel = level;
                
                // Log for diagnosis
                if (level > 0) {
                    console.log(`[OutlineSync] Set row ${excelRowNumber} (${rowId}) to level ${level}`);
                }
            } catch (error) {
                console.error(`[OutlineSync] Error setting outline level for row ${excelRowNumber}:`, error);
            }
        }
        
        // Force the workbook to update
        await this.rootStore.sheetStore._forceWorkbookUpdate();
        
        console.log('[OutlineSync] Outline level sync completed');
    }

}