import { makeAutoObservable, action, runInAction, observable } from 'mobx';
import * as ExcelJS from 'exceljs';
import { v4 as uuidv4 } from 'uuid';

export class WorkbookStore {
    workbooks = [];
    selectedWorkbookIndex = -1;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
            workbooks: observable,
            selectedWorkbookIndex: observable
        });
    }

    addWorkbook = action((workbook) => {
        console.log('Adding workbook:', workbook);
        
        // Ensure workbook has an ID
        if (!workbook.id) {
            workbook.id = uuidv4();
        }
        
        // Ensure workbook has a title in properties
        if (!workbook.properties.title) {
            workbook.properties.title = 'Untitled Workbook';
        }

        // Add metadata sheet if it doesn't exist (for other metadata, not workbook name)
        if (!workbook.getWorksheet('_React2Excel_META_x7z9')) {
            const metaSheet = workbook.addWorksheet('_React2Excel_META_x7z9', { state: 'veryHidden' });
            // Add any other metadata you need here
            // For example, sheet types:
            metaSheet.getCell('A1').value = 'SheetTypes';
            workbook.worksheets.forEach((sheet, index) => {
                if (sheet.name !== '_React2Excel_META_x7z9') {
                    metaSheet.getCell(`B${index + 1}`).value = sheet.sheetType || 'unknown';
                }
            });
        }

        runInAction(() => {
            this.workbooks.push(workbook);
            this.selectedWorkbookIndex = this.workbooks.length - 1;
            this.rootStore.fileSystemStore.markUnsaved();
        });

        console.log('Current workbooks:', this.workbooks);
        return workbook;
    });

    get currentWorkbook() {
        return this.workbooks[this.selectedWorkbookIndex];
    }
}