export class CellValidationHelper {
    /**
     * Creates a validation rule for a column type
     */
    static createColumnTypeValidation(columnType) {
        return {
            type: 'list',
            allowBlank: true,
            formulae: [`"${columnType.label}"`]
        };
    }

    /**
     * Creates a validation rule for column options
     */
    static createOptionsValidation(options) {
        return {
            type: 'list',
            allowBlank: true,
            formulae: [`"${options.join(',')}"`]
        };
    }

    /**
     * Handles validation setup for a new column
     */
    static setupNewColumnValidation(worksheet, columnIndex, columnType, options = []) {
        // Set header cell validation (column type)
        const headerCell = worksheet.getCell(1, columnIndex);
        headerCell.dataValidation = this.createColumnTypeValidation(columnType);

        // Set data validation for the column if it has custom validation
        if (columnType.hasCustomValidation) {
            const validation = this.createOptionsValidation(
                options.length > 0 ? options : columnType.defaultOptions || []
            );
            this.applyColumnValidation(worksheet, columnIndex, validation);
        }
    }

    /**
     * Handles validation preservation when deleting columns
     */
    static handleColumnDeletion(worksheet, columnIndex) {
        const validations = new Map();
        
        // Store validations for all columns after the deleted one
        for (let row = 1; row <= worksheet.rowCount; row++) {
            for (let col = columnIndex + 1; col <= worksheet.columnCount; col++) {
                const cell = worksheet.getCell(row, col);
                if (cell.dataValidation) {
                    validations.set(`${row}-${col}`, { ...cell.dataValidation });
                }
            }
        }

        // Shift validations left
        validations.forEach((validation, key) => {
            const [row, col] = key.split('-').map(Number);
            const cell = worksheet.getCell(row, col - 1);
            cell.dataValidation = validation;
        });
    }

    /**
     * Handles validation preservation when moving columns
     */
    static handleColumnMove(worksheet, fromIndex, toIndex) {
        // Store all validations in the affected range
        const validations = new Map();
        const start = Math.min(fromIndex, toIndex);
        const end = Math.max(fromIndex, toIndex);
        
        // Collect all validations in the range
        for (let row = 1; row <= worksheet.rowCount; row++) {
            for (let col = start; col <= end; col++) {
                const cell = worksheet.getCell(row, col);
                if (cell.dataValidation) {
                    validations.set(`${row}-${col}`, { ...cell.dataValidation });
                }
            }
        }

        // Calculate new positions and apply validations
        validations.forEach((validation, key) => {
            const [row, col] = key.split('-').map(Number);
            let newCol;
            
            if (col === fromIndex) {
                newCol = toIndex;
            } else if (fromIndex < toIndex) {
                newCol = col > fromIndex && col <= toIndex ? col - 1 : col;
            } else {
                newCol = col >= toIndex && col < fromIndex ? col + 1 : col;
            }
            
            const cell = worksheet.getCell(row, newCol);
            cell.dataValidation = validation;
        });
    }

    /**
     * Handles validation preservation when moving rows
     */
    static handleRowMove(worksheet, fromIndex, toIndex) {
        const validations = new Map();
        
        // Store validations for the moving row
        for (let col = 1; col <= worksheet.columnCount; col++) {
            const cell = worksheet.getCell(fromIndex, col);
            if (cell.dataValidation) {
                validations.set(col, { ...cell.dataValidation });
            }
        }

        // Apply validations at new position
        validations.forEach((validation, col) => {
            const cell = worksheet.getCell(toIndex, col);
            cell.dataValidation = validation;
        });
    }

    /**
     * Handles validation preservation when moving multiple rows
     */
    static handleMultiRowMove(worksheet, rowIds, targetIndex) {
        const validations = new Map();
        
        // Store validations for all moving rows
        rowIds.forEach(rowIndex => {
            for (let col = 1; col <= worksheet.columnCount; col++) {
                const cell = worksheet.getCell(rowIndex, col);
                if (cell.dataValidation) {
                    validations.set(`${rowIndex}-${col}`, { ...cell.dataValidation });
                }
            }
        });

        // Apply validations at new positions
        let newRowIndex = targetIndex;
        rowIds.forEach(oldRowIndex => {
            for (let col = 1; col <= worksheet.columnCount; col++) {
                const validation = validations.get(`${oldRowIndex}-${col}`);
                if (validation) {
                    const cell = worksheet.getCell(newRowIndex, col);
                    cell.dataValidation = validation;
                }
            }
            newRowIndex++;
        });
    }

    /**
     * Applies validation to a range of cells in a column
     */
    static applyColumnValidation(worksheet, columnIndex, validation, skipHeader = true) {
        const startRow = skipHeader ? 2 : 1;
        for (let rowNumber = startRow; rowNumber <= worksheet.rowCount; rowNumber++) {
            const cell = worksheet.getCell(rowNumber, columnIndex);
            cell.dataValidation = { ...validation };
        }
    }

    /**
     * Updates column validation options
     */
    static updateColumnOptions(worksheet, columnIndex, options, columnType) {
        const validation = columnType.hasCustomValidation ? 
            this.createOptionsValidation(options) :
            this.createColumnTypeValidation(columnType);

        this.applyColumnValidation(worksheet, columnIndex, validation);
        return validation;
    }

    /**
     * Validates a value against column type and options
     */
    static validateValue(value, columnType, options = []) {
        if (value === null || value === undefined || value === '') {
            return true;
        }

        if (columnType.hasCustomValidation) {
            return options.includes(value.toString());
        }

        return columnType.validate(value);
    }

    /**
     * Preserves validations during batch operations
     */
    static preserveValidations(worksheet, operation) {
        const validations = new Map();
        
        // Store current validations
        for (let row = 1; row <= worksheet.rowCount; row++) {
            for (let col = 1; col <= worksheet.columnCount; col++) {
                const cell = worksheet.getCell(row, col);
                if (cell.dataValidation) {
                    validations.set(`${row}-${col}`, { ...cell.dataValidation });
                }
            }
        }

        // Perform the operation
        operation();

        // Restore validations with any necessary adjustments
        return validations;
    }

    /**
     * Handles all validation aspects of adding a new column
     */
    static handleNewColumn(worksheet, insertIndex, type, existingColumns) {
        // Store existing validations that need to shift
        const validations = new Map();
        
        // Store validations for columns that will shift
        for (let i = insertIndex; i < existingColumns.length; i++) {
            // Store header validation
            const headerCell = worksheet.getCell(1, i + 1);
            if (headerCell?.dataValidation) {
                validations.set(`1-${i}`, { ...headerCell.dataValidation });
            }

            // Store data validations
            for (let row = 2; row <= worksheet.rowCount; row++) {
                const cell = worksheet.getCell(row, i + 1);
                if (cell?.dataValidation) {
                    validations.set(`${row}-${i}`, { ...cell.dataValidation });
                }
            }
        }

        // Shift existing validations right
        validations.forEach((validation, key) => {
            const [row, col] = key.split('-').map(Number);
            const cell = worksheet.getCell(row, col + 1);
            cell.dataValidation = validation;
        });

        // Set up new column validation
        this.setupNewColumnValidation(worksheet, insertIndex + 1, type);
    }

    /**
     * Handles all validation aspects of deleting a column
     */
    static handleColumnDeletionComplete(worksheet, columnIndex, totalColumns) {
        console.log('Deleting column at index:', columnIndex);
        
        // Shift all columns left
        worksheet.eachRow((row, rowNumber) => {
            for (let i = columnIndex; i < totalColumns; i++) {
                const currentCell = row.getCell(i + 1);
                const targetCell = row.getCell(i);
                
                // Copy all cell properties
                targetCell.value = currentCell.value;
                targetCell.dataValidation = currentCell.dataValidation ? 
                    { ...currentCell.dataValidation } : null;
                Object.assign(targetCell.style, currentCell.style);
            }

            // Clear the last cell
            const lastCell = row.getCell(totalColumns);
            lastCell.value = null;
            lastCell.dataValidation = null;
            lastCell.style = {
                font: {},
                alignment: { vertical: 'bottom', horizontal: 'left' }
            };
        });

        console.log('Column deletion complete');
    }

    /**
     * Handles all validation aspects of moving a column
     */
    static handleColumnMoveComplete(worksheet, fromIndex, toIndex, totalColumns) {
        console.log('Moving column from', fromIndex, 'to', toIndex);
        
        // Store the entire column's data
        const columnData = [];
        worksheet.eachRow((row, rowNumber) => {
            const cell = row.getCell(fromIndex);
            columnData.push({
                value: cell.value,
                validation: cell.dataValidation ? { ...cell.dataValidation } : null,
                style: { ...cell.style }
            });
        });

        if (fromIndex < toIndex) {
            // Moving right: shift cells left in the gap
            worksheet.eachRow((row, rowNumber) => {
                for (let i = fromIndex; i < toIndex; i++) {
                    const currentCell = row.getCell(i + 1);
                    const targetCell = row.getCell(i);
                    
                    targetCell.value = currentCell.value;
                    targetCell.dataValidation = currentCell.dataValidation ? 
                        { ...currentCell.dataValidation } : null;
                    Object.assign(targetCell.style, currentCell.style);
                }
            });
        } else {
            // Moving left: shift cells right in the gap
            worksheet.eachRow((row, rowNumber) => {
                for (let i = fromIndex - 1; i >= toIndex; i--) {
                    const currentCell = row.getCell(i);
                    const targetCell = row.getCell(i + 1);
                    
                    targetCell.value = currentCell.value;
                    targetCell.dataValidation = currentCell.dataValidation ? 
                        { ...currentCell.dataValidation } : null;
                    Object.assign(targetCell.style, currentCell.style);
                }
            });
        }

        // Place stored column data in new position
        worksheet.eachRow((row, rowNumber) => {
            const targetCell = row.getCell(toIndex);
            const data = columnData[rowNumber - 1];
            
            targetCell.value = data.value;
            targetCell.dataValidation = data.validation;
            Object.assign(targetCell.style, data.style);
        });

        console.log('Column move complete');
    }

    /**
     * Handles all validation aspects of moving rows
     */
    static handleRowMoveComplete(worksheet, fromIndices, toIndex, totalRows) {
        const validations = this.preserveValidations(worksheet, () => {
            if (Array.isArray(fromIndices)) {
                this.handleMultiRowMove(worksheet, fromIndices, toIndex);
            } else {
                this.handleRowMove(worksheet, fromIndices, toIndex);
            }
        });

        // Ensure proper validation state after move
        for (let row = 1; row <= totalRows; row++) {
            for (let col = 1; col <= worksheet.columnCount; col++) {
                const validation = validations.get(`${row}-${col}`);
                if (validation) {
                    const cell = worksheet.getCell(row, col);
                    cell.dataValidation = validation;
                }
            }
        }
    }

    /**
     * Handles all aspects of column insertion including validations
     */
    static handleColumnInsertion(worksheet, insertIndex, columns, newColumn) {
        console.log('DEBUG - Column insertion:', {
            insertIndex,
            columnsLength: columns.length,
            newColumnHeading: newColumn.heading,
            existingHeadings: worksheet.getRow(1).values
        });
        
        worksheet.eachRow((row, rowNumber) => {
            // Store all values and validations from insert point to end
            const storedCells = [];
            
            // Store data from insert point to end
            for (let i = insertIndex + 1; i <= columns.length; i++) {
                const cell = row.getCell(i);
                storedCells.push({
                    value: cell.value,
                    validation: cell.dataValidation ? { ...cell.dataValidation } : null,
                    style: { ...cell.style }
                });
            }

            // DEBUG - Log stored cells for first row
            if (rowNumber === 1) {
                console.log('DEBUG - Stored cells:', storedCells.map(c => c.value));
            }

            // Shift existing values right
            for (let i = storedCells.length - 1; i >= 0; i--) {
                const sourceData = storedCells[i];
                const targetCell = row.getCell(i + insertIndex + 2);
                
                // Apply stored data
                targetCell.value = sourceData.value;
                
                // Only copy validation if it's the header row or if the validation exists
                if (rowNumber === 1) {
                    // For header row, always set column type validation
                    targetCell.dataValidation = sourceData.validation;
                } else if (sourceData.validation) {
                    // For content cells, only copy if validation exists
                    targetCell.dataValidation = sourceData.validation;
                }
                
                Object.assign(targetCell.style, sourceData.style);
            }

            // Set up the new cell
            const newCell = row.getCell(insertIndex + 1);
            
            if (rowNumber === 1) {
                // Set heading for the first row
                newCell.value = newColumn.heading;
                newCell.dataValidation = {
                    type: 'list',
                    allowBlank: true,
                    formulae: [`"${newColumn.type.label}"`]
                };
                console.log('DEBUG - After setting new cell:', {
                    rowNumber,
                    cellValue: newCell.value,
                    allHeadings: worksheet.getRow(1).values
                });
            } else {
                // Clear content cells
                newCell.value = null;
                newCell.dataValidation = null;
            }
            
            newCell.style = {
                font: {},
                alignment: { vertical: 'bottom', horizontal: 'left' }
            };
        });

        console.log('DEBUG - Final headings:', worksheet.getRow(1).values);
    }
}