export class CellFormatHelper {
    static getFormatForColumnType(columnTypeId, value = null) {
        // Initialize format with default values
        const format = {
            numFmt: '@', // Default text format
            alignment: { 
                horizontal: 'left',
                vertical: 'bottom',
                wrapText: false
            }
        };

        switch (columnTypeId) {
            case 'DATE':
            case 'START_DATE':
            case 'END_DATE':
                format.numFmt = value?.isDateOnly ? 'dd/mm/yyyy' : 'dd/mm/yyyy h:mm';
                format.alignment.horizontal = 'right';
                break;
                
            case 'RATING':
            case 'NUMBER':
                format.numFmt = '0';
                format.alignment.horizontal = 'right';
                break;
                
            case 'PERCENTAGE':
                format.numFmt = '0.00%';
                format.alignment.horizontal = 'right';
                break;
                
            case 'MULTI_LINE_TEXT':
                format.numFmt = '@';
                format.alignment.wrapText = true;
                format.alignment.vertical = 'top';
                break;
                
            case 'SINGLE_LINE_TEXT':
            case 'SELECT':
            case 'STATUS':
            default:
                // Use default format values
                break;
        }

        // console.log(`Format decision for column type '${columnTypeId}':`, {
        //     format,
        //     numFmtExact: `Setting numFmt to: "${format.numFmt}"`,
        //     value,
        //     reason: this._getFormatReason(columnTypeId)
        // });

        return format;
    }

    static applyFormatToCell(cell, columnTypeId, value = null) {
        // console.log('Formatting cell:', {
        //     address: cell.address,
        //     columnType: columnTypeId,
        //     currentFormat: {
        //         numFmtExact: `Current numFmt: "${cell.numFmt}"`,
        //         alignment: cell.alignment
        //     }
        // });
        
        const format = this.getFormatForColumnType(columnTypeId, value);
        
        // Apply the format to the cell
        cell.numFmt = format.numFmt;
        cell.alignment = { ...format.alignment }; // Create a new object to avoid reference issues
        
        // console.log('Applied format:', {
        //     address: cell.address,
        //     columnType: columnTypeId,
        //     newFormat: {
        //         numFmtExact: `New numFmt: "${cell.numFmt}"`,
        //         alignment: cell.alignment
        //     }
        // });
        
        return cell;
    }

    static _getFormatReason(columnTypeId) {
        switch (columnTypeId) {
            case 'DATE':
            case 'START_DATE':
            case 'END_DATE':
                return 'Date format with right alignment for better readability';
            case 'RATING':
            case 'NUMBER':
                return 'Numeric format with right alignment for numbers';
            case 'PERCENTAGE':
                return 'Percentage format with right alignment';
            case 'MULTI_LINE_TEXT':
                return 'Text format with word wrap and top alignment for multi-line content';
            case 'SINGLE_LINE_TEXT':
            case 'SELECT':
            case 'STATUS':
                return 'Default text format with standard alignment';
            default:
                return 'Default text format applied';
        }
    }
}