import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/outline';

/**
 * SidePanel - A slide-in panel that pushes main content instead of overlaying
 * 
 * @param {Object} props
 * @param {string} [props.position="right"] - Panel opens from "left" or "right"
 * @param {string} [props.width="30%"] - Width of the panel (fixed or percentage)
 * @param {number} [props.animationDuration=300] - Transition duration in milliseconds
 * @param {boolean} [props.closeOnOutsideClick=false] - Whether clicking outside closes the panel
 * @param {boolean} [props.persistent=false] - If true, remains open until explicitly closed
 * @param {boolean} [props.isOpen=false] - Controls the panel's visibility
 * @param {function} [props.onClose] - Callback function when panel closes
 * @param {function} [props.onOpen] - Callback function when panel opens
 * @param {React.ReactNode} props.children - Content to be rendered inside the panel
 */
const SidePanel = ({
  position = 'right',
  width = '30%',
  animationDuration = 300,
  closeOnOutsideClick = false,
  persistent = false,
  isOpen = false,
  onClose = () => {},
  onOpen = () => {},
  children,
}) => {
  const panelRef = useRef(null);
  const panelId = useRef(`side-panel-${Math.random().toString(36).substr(2, 9)}`);
  
  // Calculate adjusted width for header
  const calculateAdjustedWidth = () => {
    // Convert width to a value we can work with
    let numericWidth;
    let unit;
    
    if (width.endsWith('%')) {
      numericWidth = parseFloat(width);
      unit = '%';
    } else if (width.endsWith('px')) {
      numericWidth = parseFloat(width);
      unit = 'px';
    } else if (width.endsWith('rem')) {
      numericWidth = parseFloat(width);
      unit = 'rem';
    } else {
      // Default to pixels if no unit specified
      numericWidth = parseFloat(width);
      unit = 'px';
    }
    
    // Subtract 2px for border and 1rem (16px) for margin equivalent to m-4
    if (unit === 'px') {
      return `${numericWidth - 2 - 16*2}px`;
    } else if (unit === 'rem') {
      return `${numericWidth - 0.125 - 1*2}rem`; // 0.125rem = 2px, 1rem = m-4
    } else {
      // For percentage, we can't directly subtract pixels
      // This is an approximation
      return `calc(${width} - 2px - 1rem)`;
    }
  };
  
  const adjustedWidth = calculateAdjustedWidth();
  
  // Handle ESC key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen && !persistent) {
        onClose();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, persistent, onClose]);
  
  // Handle outside clicks if enabled
  useEffect(() => {
    if (!closeOnOutsideClick) return;
    
    const handleOutsideClick = (e) => {
      if (
        isOpen && 
        !persistent && 
        panelRef.current && 
        !panelRef.current.contains(e.target) &&
        !e.target.closest('[data-open-panel-button="true"]')
      ) {
        onClose();
      }
    };
    
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen, closeOnOutsideClick, persistent, onClose]);

  // Apply margin to main content when panel is open
  useEffect(() => {
    const nextDiv = document.querySelector('body > div#__next');
    if (nextDiv) {
      nextDiv.style.transition = `margin ${animationDuration}ms ease-in-out`;
      nextDiv.style[position === 'left' ? 'marginLeft' : 'marginRight'] = isOpen ? width : '0';
    }
    
    document.body.style.overflowX = 'hidden';
    
    return () => {
      if (nextDiv) {
        nextDiv.style[position === 'left' ? 'marginLeft' : 'marginRight'] = '';
      }
      document.body.style.overflowX = '';
    };
  }, [isOpen, position, width, animationDuration]);

  // Panel styles
  const panelStyle = {
    maxWidth: isOpen ? width : '0px',
    width: isOpen ? '100%' : '0px',
    transition: `max-width ${animationDuration}ms ease-in-out, width ${animationDuration}ms ease-in-out`,
    position: 'relative',
    top: 0,
    bottom: 0,
    [position]: 0,
    zIndex: 50,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  };

  // Always render the panel, but control its width
  return (
    <aside 
      ref={panelRef}
      id={panelId.current}
      style={panelStyle}
      className=""
      aria-labelledby={`${panelId.current}-title`}
      role="dialog"
      aria-hidden={!isOpen}
    >
      <div className={`m-4 overflow-y-auto h-[calc(100vh-137px-32px)] overflow-scroll rounded-3xl border border-gray-200 relative`}>
        {/* Header - now sticky with frosted glass effect */}
        <div className={`sticky top-0 py-4 flex justify-between items-center z-10 backdrop-blur-md bg-white/70 border-b border-gray-100`} style={{ width: adjustedWidth }}>
          {!persistent && (
            <button
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 rounded-full"
              onClick={onClose}
              aria-label="Close panel"
            >
              <XMarkIcon className="ml-4 h-5 w-5" />
            </button>
          )}
        </div>
        
        {/* Content */}
        <div className="flex-1 overflow-y-auto" style={{ width: adjustedWidth }}>
          {children}
        </div>
      </div>
    </aside>
  );
};

SidePanel.propTypes = {
  position: PropTypes.oneOf(['left', 'right']),
  width: PropTypes.string,
  animationDuration: PropTypes.number,
  closeOnOutsideClick: PropTypes.bool,
  persistent: PropTypes.bool,
  resizable: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default SidePanel;
