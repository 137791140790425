import React from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export const SheetRequirementsView = ({ isOpen, onClose, requirements }) => {
    if (!requirements || !Array.isArray(requirements) || requirements.length === 0) {
        return null;
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className="relative z-50"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-md rounded-lg bg-white p-6 shadow-xl">
                    <div className="flex items-start gap-4">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />
                        </div>
                        
                        <div>
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                                Missing Required Columns
                            </Dialog.Title>

                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    This view requires the following columns:
                                </p>
                                
                                <ul className="mt-2 list-disc list-inside text-sm text-gray-600">
                                    {requirements.map((req, index) => (
                                        <li key={index} className="mt-1">
                                            {req.column}: {req.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="mt-4 flex justify-end">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    onClick={onClose}
                                >
                                    Got it
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};