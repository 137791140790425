import { makeAutoObservable, action, runInAction } from 'mobx';
import * as ExcelJS from 'exceljs';
import { v4 as uuidv4 } from 'uuid';

export class WorkbookViewModel {
    view = 'columns';
    isSheetTypeDialogOpen = false;
    isSaving = false;

    constructor(stores) {
        this.stores = stores;
        makeAutoObservable(this, {
            stores: false,
            isSaving: true
        });
    }

    handleNewWorkbook = action(async () => {
        console.log('Creating new workbook');
        const workbook = new ExcelJS.Workbook();
        
        workbook.properties.title = 'Untitled Workbook';
        workbook.id = uuidv4();
        workbook.isNewWorkbook = true;
        
        const sheet = workbook.addWorksheet('Sheet 1');
        sheet.sheetType = 'unknown';
        
        this.stores.workbookStore.addWorkbook(workbook);
        this.stores.fileSystemStore.markUnsaved();
        this.openSheetTypeDialog();
    });

    handleFileOpen = action(async (droppedFile = null) => {
        try {
            let file, handle;
            
            if (droppedFile) {
                file = droppedFile;
                handle = null;
            } else {
                const result = await this.stores.fileSystemStore.openFile();
                file = result.file;
                handle = result.handle;
            }

            if (file) {
                const workbook = new ExcelJS.Workbook();
                workbook.isNewWorkbook = false;
                
                if (file.name.toLowerCase().endsWith('.csv')) {
                    const text = await file.text();
                    const worksheet = workbook.addWorksheet('Sheet 1');
                    await worksheet.csv.parse(text);
                    workbook.isCSV = true;
                    workbook.properties.title = file.name.replace(/\.csv$/, '');
                    workbook.originalFileName = file.name;
                } else {
                    await workbook.xlsx.load(await file.arrayBuffer());
                    workbook.originalFileName = file.name;
                    
                    if (!workbook.properties.title) {
                        workbook.properties.title = file.name.replace(/\.xlsx$/, '');
                    }
                }
                
                workbook.id = uuidv4();
                this.stores.workbookStore.addWorkbook(workbook);
                this.stores.fileSystemStore.setCurrentFileHandle(handle);
            }
        } catch (error) {
            console.error('Error opening workbook:', error);
        }
    });

    get currentWorkbook() {
        return this.stores.workbookStore.currentWorkbook;
    }

    get isSaved() {
        return this.stores?.fileSystemStore?.isSaved ?? true;
    }

    get hasEditPermission() {
        return this.stores.fileSystemStore.hasEditPermission;
    }

    setView = action((newView) => {
        this.view = newView;
    });

    openSheetTypeDialog = action(() => {
        this.isSheetTypeDialogOpen = true;
    });

    closeSheetTypeDialog = action(() => {
        this.isSheetTypeDialogOpen = false;
    });

    handleSave = action(async () => {
        if (!this.currentWorkbook) return;
        
        try {
            runInAction(() => {
                this.isSaving = true;
            });
            
            await this.stores.fileSystemStore.saveWorkbook(
                this.currentWorkbook,
                `${this.currentWorkbook.name || 'workbook'}.xlsx`
            );
        } catch (error) {
            console.error('Error saving workbook:', error);
        } finally {
            runInAction(() => {
                this.isSaving = false;
            });
        }
    });

    handleSaveAs = action(async () => {
        if (!this.currentWorkbook) return;
        
        try {
            await this.stores.fileSystemStore.saveWorkbookAs(
                this.currentWorkbook,
                this.currentWorkbook.name || 'workbook.xlsx'
            );
        } catch (error) {
            console.error('Error saving workbook:', error);
        }
    });

    updateWorkbookName = action((newName) => {
        if (!this.currentWorkbook || !newName) return;
        if (!this.currentWorkbook.isNewWorkbook) {
            console.log('Cannot update name of uploaded workbook');
            return;
        }

        try {
            const formattedName = this.formatFileName(newName);
            console.log('Updating workbook name:', { oldName: this.currentWorkbook.properties.title, newName: formattedName });
            
            runInAction(() => {
                this.currentWorkbook.properties.title = formattedName;
                if (this.stores?.fileSystemStore) {
                    this.stores.fileSystemStore.markUnsaved();
                }
            });
        } catch (error) {
            console.error('Error updating workbook name:', error);
        }
    });

    handleSaveAsCSV = action(async () => {
        const currentSheet = this.stores.sheetStore.currentSheet;
        if (!this.currentWorkbook || !currentSheet) return;
        
        try {
            await this.stores.fileSystemStore.saveWorkbookAsCSV(
                currentSheet,
                this.currentWorkbook.name?.replace(/\.xlsx$/, '') || 'workbook'
            );
        } catch (error) {
            console.error('Error saving workbook as CSV:', error);
        }
    });

    formatFileName = (name) => {
        let formatted = name.replace(/[<>:"\/\\|?*\x00-\x1F]/g, '');
        formatted = formatted.replace(/\s+/g, ' ');
        formatted = formatted.trim();
        formatted = formatted.slice(0, 255);
        return formatted || 'Untitled Workbook';
    };
}   