import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

export const ColumnRowValidationEditor = observer(({ 
    column,
    onUpdateValidation
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);

    // Update options when column validation options change
    useEffect(() => {
        setOptions(column.validationOptions || []);
    }, [column.validationOptions]);

    if (!column.type.hasCustomValidation) {
        return null;
    }

    const handleAddOption = () => {
        if (newOption && !options.includes(newOption)) {
            const newOptions = [...options, newOption];
            setOptions(newOptions);
            setNewOption('');
        }
    };

    const handleRemoveOption = (index) => {
        setOptions(options.filter((_, i) => i !== index));
        if (editingIndex === index) {
            setEditingIndex(null);
        }
    };

    const handleEditOption = (index) => {
        setEditingIndex(index);
        setNewOption(options[index]);
    };

    const handleUpdateOption = () => {
        if (newOption && !options.includes(newOption)) {
            const newOptions = [...options];
            newOptions[editingIndex] = newOption;
            setOptions(newOptions);
            setNewOption('');
            setEditingIndex(null);
        }
    };

    const handleMoveOption = (index, direction) => {
        const newOptions = [...options];
        const newIndex = index + direction;
        
        if (newIndex >= 0 && newIndex < newOptions.length) {
            [newOptions[index], newOptions[newIndex]] = [newOptions[newIndex], newOptions[index]];
            setOptions(newOptions);
            
            if (editingIndex === index) {
                setEditingIndex(newIndex);
            } else if (editingIndex === newIndex) {
                setEditingIndex(index);
            }
        }
    };

    const handleSave = async () => {
        try {
            await onUpdateValidation(options);
            setIsEditing(false);
            setNewOption('');
            setEditingIndex(null);
        } catch (error) {
            console.error('Error saving validation options:', error);
            // You might want to show an error message to the user here
        }
    };

    const handleCancel = () => {
        setOptions(column.validationOptions || []);
        setIsEditing(false);
        setNewOption('');
        setEditingIndex(null);
    };

    const getTotalLength = () => options.join(', ').length;
    const isNearLimit = getTotalLength() > 255;

    return (
        <div className="mt-2 p-2 border rounded">
            <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium">Validation Options</span>
                <button
                    onClick={() => isEditing ? handleCancel() : setIsEditing(true)}
                    className="text-blue-600 text-sm"
                >
                    {isEditing ? 'Cancel' : 'Edit'}
                </button>
            </div>

            {isNearLimit && (
                <div className="mb-2 p-2 bg-yellow-50 border border-yellow-200 rounded text-sm text-yellow-700">
                    ⚠️ Warning: Combined option length exceeds 255 characters. While this will work in our app, 
                    some spreadsheet editors like Excel might have issues with longer validation lists.
                </div>
            )}

            {isEditing ? (
                <div className="space-y-2">
                    <div className="flex gap-2">
                        <input
                            type="text"
                            value={newOption}
                            onChange={(e) => setNewOption(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    if (editingIndex !== null) {
                                        handleUpdateOption();
                                    } else {
                                        handleAddOption();
                                    }
                                }
                            }}
                            placeholder={editingIndex !== null ? "Edit option" : "Add new option"}
                            className="flex-1 px-2 py-1 border rounded"
                        />
                        <button
                            onClick={() => editingIndex !== null ? handleUpdateOption() : handleAddOption()}
                            className="px-3 py-1 bg-green-500 text-white rounded"
                        >
                            {editingIndex !== null ? 'Update' : 'Add'}
                        </button>
                    </div>
                    <ul className="space-y-1">
                        {options.map((option, index) => (
                            <li key={index} className="flex justify-between items-center gap-2">
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() => handleMoveOption(index, -1)}
                                        disabled={index === 0}
                                        className="text-blue-600 hover:bg-blue-50 px-1 rounded disabled:opacity-50"
                                    >
                                        ↑
                                    </button>
                                    <button
                                        onClick={() => handleMoveOption(index, 1)}
                                        disabled={index === options.length - 1}
                                        className="text-blue-600 hover:bg-blue-50 px-1 rounded disabled:opacity-50"
                                    >
                                        ↓
                                    </button>
                                    <span>{option}</span>
                                </div>
                                <div className="flex gap-2">
                                    <button
                                        onClick={() => handleEditOption(index)}
                                        className="text-blue-600 hover:bg-blue-50 px-2 rounded"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleRemoveOption(index)}
                                        className="text-red-600 hover:bg-red-50 px-2 rounded"
                                    >
                                        ×
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <button
                        onClick={handleSave}
                        disabled={newOption.length > 0}
                        className="w-full px-3 py-1 bg-blue-500 text-white rounded disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Save Changes
                    </button>
                </div>
            ) : (
                <ul className="text-sm text-gray-600">
                    {column.validationOptions.map((option, index) => (
                        <li key={index}>{option}</li>
                    ))}
                </ul>
            )}
        </div>
    );
});