import { FileSystemStore } from './FileSystemStore';
import { DragDropStore } from './DragDropStore';
import { WorkbookStore } from './WorkbookStore';
import { SheetStore } from './SheetStore';
import { SheetDataStore } from './SheetDataStore';
import React from 'react';

export class RootStore {
    constructor() {
        this.workbookStore = new WorkbookStore(this);
        this.sheetStore = new SheetStore(this);
        this.fileSystemStore = new FileSystemStore(this);
        this.sheetDataStore = new SheetDataStore(this);
        
        // Debug initialization
        console.log('RootStore initialized:', {
            hasWorkbookStore: !!this.workbookStore,
            hasSheetStore: !!this.sheetStore,
            hasSheetDataStore: !!this.sheetDataStore
        });
    }
}

// Create a context for the stores
export const StoreContext = React.createContext(null);

export const useStores = () => {
    const context = React.useContext(StoreContext);
    if (!context) {
        throw new Error('useStores must be used within StoreProvider');
    }
    return context;
};