import React, { useRef, useEffect, useState } from 'react';

const InputPercentage = ({ value, onChange, onBlur, onEnter, onTab }) => {
  const editorRef = useRef(null);
  const isInternalChange = useRef(false);
  const lastInternalHtml = useRef('');
  const [isEditing, setIsEditing] = useState(false);
  const [rawInput, setRawInput] = useState('');

  const submitChanges = () => {
    if (editorRef.current) {
      const text = editorRef.current.textContent || '';
      
      // Convert to number or null if empty
      if (text.trim() === '') {
        onChange(null);
        return;
      }

      const numValue = Number(text);
      if (isNaN(numValue)) {
        // Revert to last valid value if invalid number
        editorRef.current.textContent = lastInternalHtml.current;
        return;
      }
      
      onChange(numValue / 100); // Convert to decimal for percentage
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      submitChanges();
      onTab?.(e);
      return;
    }

    // Prevent any alphabetical characters immediately
    if (/^[a-zA-Z]$/.test(e.key)) {
        e.preventDefault();
        return;
    }

    // Prevent non-numeric input more strictly
    const allowedKeys = [
        'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 
        'Tab', 'Enter', '.', '-'
    ];
    const isNumber = /^[0-9]$/.test(e.key);
    const isModifierKey = e.ctrlKey || e.metaKey;
    const isAllowedKey = allowedKeys.includes(e.key);
    
    if (!isNumber && !isAllowedKey && !isModifierKey) {
        e.preventDefault();
        return;
    }

    // Prevent multiple decimal points
    if (e.key === '.' && editorRef.current?.textContent?.includes('.')) {
      e.preventDefault();
      return;
    }

    // Prevent multiple minus signs or minus sign in middle of number
    if (e.key === '-' && (
      editorRef.current?.textContent?.includes('-') || 
      editorRef.current?.textContent?.length > 0
    )) {
      e.preventDefault();
      return;
    }

    // Prevent TableView from handling these keys while editing
    if (e.key === 'Enter' || 
        e.key === 'ArrowUp' || 
        e.key === 'ArrowDown' || 
        e.key === 'ArrowLeft' || 
        e.key === 'ArrowRight' || 
        e.key === 'Tab') {
      e.stopPropagation();
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      submitChanges();
      e.target.blur();
      onEnter?.();
      return;
    }

    // Only prevent specific formatting shortcuts
    if ((e.ctrlKey || e.metaKey) && 
        (e.key === 'b' || 
         e.key === 'i' || 
         e.key === 'u')) {
      e.preventDefault();
    }
    
    // Mark as editing when user types
    if (isNumber || isAllowedKey) {
      setIsEditing(true);
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      const content = editorRef.current.textContent || '';
      // Validate input but don't submit
      if (content && !/^-?\d*\.?\d*$/.test(content)) {
        editorRef.current.textContent = lastInternalHtml.current;
        return;
      }
      
      lastInternalHtml.current = content;
      setRawInput(content); // Update the raw input state
    }
  };

  const handleBlur = () => {
    submitChanges();
    setIsEditing(false);
    onBlur?.();
  };

  useEffect(() => {
    if (editorRef.current && !isInternalChange.current && !isEditing) {
      if (value !== null && value !== undefined) {
        const displayValue = String(value * 100);
        editorRef.current.textContent = displayValue;
        lastInternalHtml.current = displayValue;
        setRawInput(displayValue);
      } else {
        editorRef.current.textContent = '';
        lastInternalHtml.current = '';
        setRawInput('');
      }
    }
    isInternalChange.current = false;
  }, [value, isEditing]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
      
      if (value !== null && value !== undefined) {
        const initialValue = String(value * 100);
        editorRef.current.textContent = initialValue;
        lastInternalHtml.current = initialValue;
        setRawInput(initialValue);
      }
      
      const range = document.createRange();
      const selection = window.getSelection();
      
      range.selectNodeContents(editorRef.current);
      
      selection.removeAllRanges();
      selection.addRange(range);
      
      setIsEditing(true);
    }
  }, []);

  useEffect(() => {
    if (window.initialKeystroke && editorRef.current) {
      editorRef.current.textContent = window.initialKeystroke;
      lastInternalHtml.current = window.initialKeystroke;
      setRawInput(window.initialKeystroke);
      
      const range = document.createRange();
      const selection = window.getSelection();
      
      range.selectNodeContents(editorRef.current);
      range.collapse(false);
      
      selection.removeAllRanges();
      selection.addRange(range);
      
      window.initialKeystroke = '';
    }
  }, []);

  return (
    <div
      ref={editorRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onBlur={handleBlur}
      suppressContentEditableWarning
      className="w-full p-0 outline-none resize-none whitespace-nowrap overflow-hidden"
    />
  );
};

export default InputPercentage;
