export class FileSystemService {
    isFileSystemAccessSupported() {
        return 'showOpenFilePicker' in window && 'showSaveFilePicker' in window;
    }

    async downloadWorkbook(workbook, fileName) {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { 
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
    }

    async openFile() {
        try {
            if (this.isFileSystemAccessSupported()) {
                const [fileHandle] = await window.showOpenFilePicker({
                    types: [{
                        description: 'Excel Files',
                        accept: {
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                        }
                    }, {
                        description: 'CSV Files',
                        accept: {
                            'text/csv': ['.csv']
                        }
                    }],
                    multiple: false
                });

                const file = await fileHandle.getFile();
                return { file, handle: fileHandle };
            } else {
                return new Promise((resolve, reject) => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.xlsx,.csv';
                    input.onchange = (event) => {
                        if (event.target.files?.[0]) {
                            resolve({ file: event.target.files[0], handle: null });
                        } else {
                            reject(new Error('No file selected'));
                        }
                    };
                    input.click();
                });
            }
        } catch (error) {
            console.error('Error in openFile:', error);
            throw error;
        }
    }
}