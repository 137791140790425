import { makeObservable, observable, computed, action } from 'mobx';

export class SearchableViewModel {
    searchTerm = '';
    
    constructor() {
        makeObservable(this, {
            searchTerm: observable,
            setSearchTerm: action,
            clearSearch: action,
            filteredData: computed
        });
    }

    setSearchTerm(term) {
        this.searchTerm = term;
    }

    clearSearch() {
        this.searchTerm = '';
    }

    // Helper method to check if a value matches search term
    _matchesSearch(value, searchTerm) {
        if (!searchTerm) return true;
        if (!value) return false;
        
        const normalizedSearch = searchTerm.toLowerCase().trim();
        const normalizedValue = String(value).toLowerCase();
        
        return normalizedValue.includes(normalizedSearch);
    }

    // To be implemented by child classes
    get searchableData() {
        throw new Error('searchableData getter must be implemented');
    }

    // Generic filtered data getter
    get filteredData() {
        if (!this.searchTerm) return this.searchableData;

        return this.filterDataBySearch(this.searchableData);
    }

    // Can be overridden by child classes for custom filtering logic
    filterDataBySearch(data) {
        throw new Error('filterDataBySearch must be implemented');
    }
}