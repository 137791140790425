import { makeObservable } from 'mobx';
import { BaseViewModel } from './BaseViewModel';

export class TimelineViewModel extends BaseViewModel {
    constructor(sheetDataStore) {
        super(sheetDataStore);
        makeObservable(this, {
            // Add observable properties and actions here
        });
    }

    get timelineItems() {
        const startColumn = this.columns.find(col => col.type.id === 'START_DATE');
        const endColumn = this.columns.find(col => col.type.id === 'END_DATE');

        return this.data.map(row => ({
            id: row.id,
            start: row[startColumn?.id],
            end: row[endColumn?.id],
            title: row[this.columns[0].id],
            status: row.status,
            assignee: row.assignee
        }));
    }

    updateTimelineItem = (itemId, updates) => {
        Object.entries(updates).forEach(([key, value]) => {
            this.sheetDataStore.updateCell(itemId, key, value);
        });
    };
}