import React, { useRef, useEffect, useState } from 'react';

const InputURL = ({ value, onChange, onBlur, onEnter, onTab, initialValue }) => {
  const editorRef = useRef(null);
  const lastInternalValue = useRef('');
  const [isValid, setIsValid] = useState(true);
  const isInternalChange = useRef(false);

  const validateUrl = (url) => {
    if (!url || url.trim() === '') return true;
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const submitChanges = () => {
    if (editorRef.current) {
      const text = editorRef.current.textContent || '';
      
      if (text.trim() === '') {
        onChange(null);
        setIsValid(true);
        return;
      }

      if (validateUrl(text)) {
        const finalUrl = text.match(/^https?:\/\//) ? text : `https://${text}`;
        onChange({
          text: text,
          hyperlink: finalUrl
        });
        setIsValid(true);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (isValid) {
        submitChanges();
        e.target.blur();
        onEnter?.();
      }
      return;
    }

    if (['Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
      e.stopPropagation();
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      if (isValid) {
        submitChanges();
        onTab?.(e);
      }
      return;
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      const text = editorRef.current.textContent || '';
      lastInternalValue.current = text;
      setIsValid(validateUrl(text));
    }
  };

  const handleBlur = () => {
    if (isValid) {
      submitChanges();
    }
    onBlur?.();
  };

  useEffect(() => {
    if (editorRef.current && !isInternalChange.current) {
      const textContent = value?.text || '';
      if (textContent !== lastInternalValue.current) {
        editorRef.current.textContent = textContent;
        lastInternalValue.current = textContent;
        setIsValid(validateUrl(textContent));
      }
    }
    isInternalChange.current = false;
  }, [value]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
      
      const initialText = initialValue || window.initialKeystroke || '';
      
      if (initialText && !value?.text) {
        editorRef.current.textContent = initialText;
        window.initialKeystroke = null;
        handleInput();
      } else {
        editorRef.current.textContent = value?.text || '';
      }
      
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editorRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [initialValue]);

  return (
    <div
      ref={editorRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onBlur={handleBlur}
      suppressContentEditableWarning
      style={{
        backgroundColor: !isValid ? '#fee2e2' : 'transparent',
        transition: 'background-color 0.2s'
      }}
      className="w-full p-0 outline-none resize-none whitespace-nowrap overflow-hidden"
    />
  );
};

export default InputURL;