import React from 'react';

export const SearchBoxComponent = ({ 
    value, 
    onChange, 
    onClear,
    placeholder = 'Search...',
    className = '' 
}) => {
    return (
        <div className={`relative ${className}`}>
            <input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                className="w-full px-4 py-2 pr-10 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {value && (
                <button
                    onClick={onClear}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                    ×
                </button>
            )}
        </div>
    );
};