import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ColumnTypes } from '../../models/SheetModel';
import Badge from '../Components/Badge/Badge';
import Avatar from '../Components/Avatar/Avatar';
import Textarea from '../Components/Textarea/Textarea';
import InputText from '../Components/Input/InputText';
import {RichText}  from '../Components/RichText/RichText';
import InputNumber from '../Components/Input/InputNumber';
import InputPercentage from '../Components/Input/InputPercentage';
import { InputRating } from '../Components/Input/InputRating';
import { InputCheckbox } from '../Components/Input/InputCheckbox';
import InputURL from '../Components/Input/InputUrl';
import InputEmail from '../Components/Input/InputEmail';
import InputDate from '../Components/Input/InputDate';
import Combobox from '../Components/Combobox/Combobox';

const formatCellValue = (value) => {
    if (value === null || value === undefined) {
        return '';
    }

    // Handle Date objects
    if (value instanceof Date) {
        const year = value.getFullYear();
        const month = String(value.getMonth() + 1).padStart(2, '0');
        const day = String(value.getDate()).padStart(2, '0');
        return {
            text: `${day}/${month}/${year}`,
            date: `${year}-${month}-${day}`,
            isDateOnly: true
        };
    }

    // Handle objects with text property (like our date format objects)
    if (typeof value === 'object' && value !== null) {
        if (value.text !== undefined) {
            return value.text;
        }
        if (value.value !== undefined) {
            return value.value;
        }
        // For other objects, try to convert to string
        return String(value);
    }

    // For all other types, convert to string
    return String(value);
};

export const TableCell = observer(({ column, value, onChange, isSelected, onSelect, style, onUpdateValidation }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [immediateValue, setImmediateValue] = useState('');
    const cellRef = useRef(null);
    const inputRef = useRef(null);
    const comboboxInputRef = useRef(null);
    const clipboard = useRef(null);
    const tableContainerRef = useRef(null);
    
    // Add state to force combobox to open
    const [comboboxOpen, setComboboxOpen] = useState(false);

    // Find and set the table container reference when the component mounts
    // or when the cell reference changes
    useEffect(() => {
        if (cellRef.current) {
            // Find the closest table container
            // First try to find the overflow-x-auto container (scrollable table container)
            let container = cellRef.current.closest('.overflow-x-auto');
            
            // If not found, try to find the table element itself
            if (!container) {
                container = cellRef.current.closest('table');
            }
            
            // If still not found, try to find any parent with position relative or absolute
            if (!container) {
                let parent = cellRef.current.parentElement;
                while (parent) {
                    const position = window.getComputedStyle(parent).position;
                    if (position === 'relative' || position === 'absolute') {
                        container = parent;
                        break;
                    }
                    parent = parent.parentElement;
                }
            }
            
            // Set the container reference
            if (container) {
                tableContainerRef.current = container;
            }
        }
    }, [cellRef.current]);

    // Reset combobox open state when cell is deselected
    useEffect(() => {
        if (!isSelected) {
            setComboboxOpen(false);
        }
    }, [isSelected]);

    // Focus the combobox input when dropdown opens
    useEffect(() => {
        if (comboboxOpen && comboboxInputRef.current) {
            // Small delay to ensure the DOM is ready
            setTimeout(() => {
                comboboxInputRef.current.focus();
            }, 50);
        }
    }, [comboboxOpen]);

    // Add effect to handle keydown events when cell is selected
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Skip if cell is not selected
            if (!isSelected) return;
            
            // Skip handling if modifier keys are pressed
            if (e.metaKey || e.ctrlKey || e.altKey) return;
            
            // Skip navigation keys
            const navigationKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape'];
            if (navigationKeys.includes(e.key)) return;

            const typeId = column.type?.id || 'SINGLE_LINE_TEXT';

            // Handle combobox fields separately
            if (typeId === 'SELECT' || typeId === 'STATUS' || typeId === 'PERSON') {
                e.preventDefault();
                e.stopPropagation();

                if (e.key === 'Backspace' || e.key === 'Delete') {
                    onChange(null);
                    setComboboxOpen(true);
                    setImmediateValue('');
                    return;
                }

                if (e.key.length === 1 || e.key === ' ') {
                    onChange(null);
                    setImmediateValue(e.key);
                    setComboboxOpen(true);
                }
                return;
            }

            // Only handle keydown if not editing (let inputs handle their own keystrokes when editing)
            if (isEditing) return;

            // Handle backspace/delete when cell is only selected (not editing)
            if (e.key === 'Backspace' || e.key === 'Delete') {
                e.preventDefault();
                e.stopPropagation();
                onChange(null);
                return;
            }

            // For single character keys or space
            if (e.key.length === 1 || e.key === ' ') {
                e.preventDefault();
                e.stopPropagation();

                // Start editing with the first keystroke
                setIsEditing(true);
                setImmediateValue(e.key);
                
                // Set initialKeystroke for all input types
                window.initialKeystroke = e.key;
                
                onChange(null);
            }
        };

        if (isSelected) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isSelected, isEditing, column.type?.id]);

    const handleTabKey = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // First submit any pending changes
        if (isEditing) {
            setIsEditing(false);
            setImmediateValue('');
        }
        
        // Then simulate Tab key press to trigger the cell navigation
        const tabEvent = new KeyboardEvent('keydown', { 
            key: 'Tab',
            bubbles: true,
            cancelable: true,
            shiftKey: e.shiftKey
        });
        cellRef.current.dispatchEvent(tabEvent);
    };

    const handleInputChange = (newValue) => {
        onChange(newValue);
        if (column.type?.id !== 'MULTI_LINE_TEXT') {
            setIsEditing(false);
        }
        setImmediateValue('');
    };

    const handleDoubleClick = () => {
        setIsEditing(true);
        
        // Automatically open combobox on double-click for SELECT, STATUS, and PERSON columns
        if (column.type?.id === 'SELECT' || column.type?.id === 'STATUS' || column.type?.id === 'PERSON') {
            setComboboxOpen(true);
        }
    };

    const handleComboboxChange = (selectedOption) => {
        // Handle the selection immediately
        const newValue = selectedOption && selectedOption.value;
        onChange(newValue);
        
        // Close the combobox after selection
        setTimeout(() => {
            setComboboxOpen(false);
            setIsEditing(false);
            setImmediateValue('');
        }, 100);
    };

    // Check if the current value is a valid option
    const isValidOption = React.useMemo(() => {
        if (!column?.validationOptions || !value) return false;
        return column.validationOptions.includes(value);
    }, [column?.validationOptions, value]);

    // Handle updating validation options
    const handleUpdateValidationOptions = (option, action) => {
        if (!column || !column.validationOptions) return;
        
        let newOptions = [...column.validationOptions];
        
        if (action === 'create' && option && !newOptions.includes(option)) {
            // Add the new option
            newOptions.push(option);
        } else if (action === 'remove' && option) {
            // Remove the option
            newOptions = newOptions.filter(opt => opt !== option);
        }
        
        // Call the onUpdateValidation prop
        if (onUpdateValidation) {
            onUpdateValidation(newOptions);
        }
    };

    const renderInput = React.useCallback(() => {
        if (!column) {
            console.warn('TableCell: Missing column');
            return null;
        }

        const typeId = column.type?.id || 'SINGLE_LINE_TEXT';

        switch (typeId) {
            case 'MULTI_LINE_TEXT':
                return isSelected && isEditing ? (
                    <Textarea
                        value={immediateValue || value || ''}
                        onChange={handleInputChange}
                        onBlur={() => {
                            setIsEditing(false);
                            setImmediateValue('');
                        }}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                    />
                ) : (
                    <RichText 
                        value={value}
                        onTab={handleTabKey}
                    />
                );

            case 'URL':
                return isSelected && isEditing ? (
                    <InputURL
                        value={value}
                        onChange={handleInputChange}
                        onBlur={() => setIsEditing(false)}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                        initialValue={immediateValue}
                    />
                ) : value ? (
                    <a 
                        href={value.hyperlink} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {value.text}
                    </a>
                ) : null;

            case 'EMAIL':
                return isSelected && isEditing ? (
                    <InputEmail
                        value={value}
                        onChange={handleInputChange}
                        onBlur={() => setIsEditing(false)}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                        initialValue={immediateValue}
                    />
                ) : value ? (
                    <a 
                        href={value.hyperlink} 
                        className="text-blue-600 hover:underline"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {value.text}
                    </a>
                ) : null;

            case 'SELECT':
            case 'STATUS':
                const options = column.validationOptions?.map(option => ({ 
                    value: option, 
                    label: option 
                })) || [];
                
                const valueArray = value ? [{ value, label: value }] : [];
                const hasOptions = options.length > 0;
                const placeholder = hasOptions ? "Select..." : "Create...";
                
                if (isSelected) {
                    return (
                        <div className="relative w-full h-full">
                            {comboboxOpen ? (
                                <Combobox
                                    options={options}
                                    value={valueArray}
                                    onChange={handleComboboxChange}
                                    onCreateOption={(newOption) => {
                                        if (newOption) {
                                            onChange(newOption.label);
                                            handleUpdateValidationOptions(newOption.label, 'create');
                                        }
                                        setComboboxOpen(false);
                                        setImmediateValue('');
                                    }}
                                    onRemoveOption={(option) => {
                                        handleUpdateValidationOptions(option.value, 'remove');
                                    }}
                                    placeholder={placeholder}
                                    displayType="badge"
                                    withBorder={false}
                                    showFocusRing={false}
                                    allowCreation={true}
                                    allowRemoval={true}
                                    multiSelect={false}
                                    showDropdownButton={false}
                                    isOpen={true}
                                    initialInputValue={immediateValue}
                                    onOpenChange={(isOpen) => {
                                        setComboboxOpen(isOpen);
                                        if (!isOpen) {
                                            setImmediateValue('');
                                        }
                                    }}
                                    containerRef={document.body}
                                    stopPropagation={true}
                                    inputRef={comboboxInputRef}
                                    onEscape={() => {
                                        setComboboxOpen(false);
                                        setImmediateValue('');
                                    }}
                                />
                            ) : (
                                <div 
                                    className="w-full h-full flex items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setComboboxOpen(true);
                                    }}
                                >
                                    {value ? (
                                        <Badge text={value} />
                                    ) : (
                                        <span className="text-gray-400">{placeholder}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return value ? <Badge text={value} /> : null;
                }

            case 'RATING':
                return (
                    <InputRating
                        value={value || 0}
                        onChange={handleInputChange}
                        onBlur={() => setIsEditing(false)}
                    />
                );

            case 'PERSON':
                const personOptions = column.validationOptions?.map(option => ({ 
                    value: option, 
                    label: option 
                })) || [];
                
                const personValueArray = value ? [{ value, label: value }] : [];
                
                if (isSelected) {
                    return (
                        <div className="relative w-full h-full">
                            {comboboxOpen ? (
                                <Combobox
                                    options={personOptions}
                                    value={personValueArray}
                                    onChange={handleComboboxChange}
                                    onCreateOption={(newOption) => {
                                        if (newOption) {
                                            onChange(newOption.label);
                                            handleUpdateValidationOptions(newOption.label, 'create');
                                        } else {
                                            onChange(null);
                                        }
                                        setTimeout(() => {
                                            setComboboxOpen(false);
                                            setIsEditing(false);
                                            setImmediateValue('');
                                        }, 100);
                                    }}
                                    onRemoveOption={(option) => {
                                        handleUpdateValidationOptions(option.value, 'remove');
                                    }}
                                    placeholder={value ? value : "Select..."}
                                    displayType="avatar"
                                    withBorder={false}
                                    showFocusRing={false}
                                    allowCreation={true}
                                    allowRemoval={true}
                                    multiSelect={false}
                                    showDropdownButton={false}
                                    isOpen={true}
                                    initialInputValue={immediateValue}
                                    onOpenChange={(isOpen) => {
                                        setComboboxOpen(isOpen);
                                        if (!isOpen) {
                                            setIsEditing(false);
                                            setImmediateValue('');
                                        }
                                    }}
                                    containerRef={document.body}
                                    stopPropagation={true}
                                    inputRef={comboboxInputRef}
                                    onEscape={() => {
                                        setComboboxOpen(false);
                                        setImmediateValue('');
                                    }}
                                />
                            ) : (
                                <div 
                                    className="w-full h-full flex items-center cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setComboboxOpen(true);
                                    }}
                                >
                                    {value ? (
                                        <Avatar name={value} size="xs" />
                                    ) : (
                                        <span className="text-gray-400">Select...</span>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return value ? <Avatar name={value} size="xs" /> : null;
                }

            case 'CHECKBOX':
                return (
                    <InputCheckbox
                        ref={inputRef}
                        checked={!!value}
                        onChange={handleInputChange}
                        isSelected={isSelected}
                    />
                );
            case 'NUMBER':
                return isSelected && isEditing ? (
                    <InputNumber
                        value={immediateValue || value || ''}
                        onChange={handleInputChange}
                        onBlur={() => {
                            setIsEditing(false);
                            setImmediateValue('');
                        }}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                    />
                ) : (
                    <RichText 
                        value={formatCellValue(value)}
                        onTab={handleTabKey}
                    />
                );
            case 'PERCENTAGE':
                return isSelected && isEditing ? (
                    <InputPercentage
                        value={immediateValue || value || ''}
                        onChange={handleInputChange}
                        onBlur={() => {
                            setIsEditing(false);
                            setImmediateValue('');
                        }}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                    />
                ) : (
                    <RichText value={value !== null && value !== undefined ? `${(value * 100).toFixed(2).replace(/\.00$/, '').replace('.', ',')}%` : ''} />
                );

            case 'DATE':
            case 'START_DATE':
            case 'END_DATE':
                return isSelected && isEditing ? (
                    <InputDate
                        value={value}
                        onChange={handleInputChange}
                        onBlur={() => setIsEditing(false)}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                        initialValue={immediateValue}
                    />
                ) : (
                    <RichText 
                    value={value?.text || ''}
                        onTab={handleTabKey}
                    />
                );

            default:
                return isSelected && isEditing ? (
                    <InputText
                        value={immediateValue || value || ''}
                        onChange={handleInputChange}
                        onBlur={() => {
                            setIsEditing(false);
                            setImmediateValue('');
                        }}
                        onEnter={() => {
                            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
                            cellRef.current.dispatchEvent(enterEvent);
                        }}
                        onTab={handleTabKey}
                    />
                ) : (
                    <RichText 
                        value={formatCellValue(value)}
                        onTab={handleTabKey}
                    />
                );
        }
    }, [column, value, onChange, isSelected, isEditing, immediateValue, comboboxOpen]);

    return (
        <td 
            ref={(el) => {
                cellRef.current = el;
            }}
            className="relative border px-1.5 py-0.5 text-xs leading-tight"
            onClick={(e) => {
                // Stop propagation to prevent any parent handlers from firing
                e.stopPropagation();
                
                onSelect();
                // Don't automatically open the combobox anymore
                if (column.type?.id === 'SELECT' || column.type?.id === 'STATUS' || column.type?.id === 'PERSON') {
                    setIsEditing(true);
                    // Don't set comboboxOpen to true here
                }
            }}
            onDoubleClick={(e) => {
                e.stopPropagation();
                handleDoubleClick();
                // The combobox opening is now handled in handleDoubleClick
            }}
            onKeyDown={(e) => {
                // Skip handling if modifier keys are pressed
                if (e.metaKey || e.ctrlKey) {
                    return; // Let the browser handle shortcuts like Cmd/Ctrl+S
                }
                
                // Handle backspace/delete directly on the cell for immediate feedback
                if ((e.key === 'Backspace' || e.key === 'Delete') && 
                    isSelected && 
                    !comboboxOpen && 
                    (column.type?.id === 'SELECT' || column.type?.id === 'STATUS' || column.type?.id === 'PERSON') &&
                    value) {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(null);
                }
            }}
            style={{
                ...style,
                isolation: 'isolate',
                position: 'relative',
            }}
        >
            {renderInput()}
            {isSelected && (
                <div 
                    className="absolute -inset-[2px] pointer-events-none" 
                    style={{ 
                        zIndex: 9998, // Lower than dropdown z-index
                        position: 'absolute',
                    }}
                >
                    <div className="absolute inset-0 border-2 border-indigo-600"></div>
                    <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-indigo-600 rounded-full"></div>
                </div>
            )}
        </td>
    );
});