/**
 * Helper class for managing Excel cell outline levels. Basically if a row is a child of another row and enables expand and minimization. In Excel, the outline level of a row can only be one level deeper than the row above it.
 */
export default class CellOutlineLevelHelper {
    /**
     * Validates and adjusts an outline level based on the row above
     * @param {number} currentLevel - The outline level to validate
     * @param {number} rowAboveLevel - The outline level of the row above
     * @returns {number} - The validated outline level
     */
    static validateOutlineLevel(currentLevel, rowAboveLevel) {
        const validatedLevel = Math.min(currentLevel, rowAboveLevel + 1);
        if (validatedLevel !== currentLevel) {
            console.log(`Adjusting outline level from ${currentLevel} to ${validatedLevel}`);
        }
        return validatedLevel;
    }

    /**
     * Gets the outline level for a specific Excel row
     * @param {ExcelRow} row - The Excel worksheet row
     * @returns {number} - The outline level (defaults to 0)
     */
    static getOutlineLevel(row) {
        return row?.outlineLevel || 0;
    }

    /**
     * Validates and gets the outline level for a row being moved
     * @param {Worksheet} worksheet - The Excel worksheet
     * @param {number} targetRowNumber - The target row number (1-based)
     * @param {number} currentLevel - The current outline level
     * @returns {number} - The validated outline level
     */
    static validateMovingRowLevel(worksheet, targetRowNumber, currentLevel) {
        // Get the row above the target position
        const rowAbove = targetRowNumber > 2 ? worksheet.getRow(targetRowNumber - 1) : null;
        const rowAboveLevel = this.getOutlineLevel(rowAbove);
        
        // Get the row below the target position to maintain parent-child relationships
        const rowBelow = worksheet.getRow(targetRowNumber + 1);
        const rowBelowLevel = this.getOutlineLevel(rowBelow);
        
        console.log(`[OutlineLevel] Validating row ${targetRowNumber} with level ${currentLevel}:`, {
            rowAboveLevel,
            rowBelowLevel,
            currentLevel
        });
        
        // If the current level is 0, we should allow it to be placed anywhere
        // Level 0 items are top-level and can be freely moved
        if (currentLevel === 0) {
            return 0;
        }
        
        // A row can only be one level deeper than the row above it
        let validatedLevel = Math.min(currentLevel, rowAboveLevel + 1);
        
        if (validatedLevel !== currentLevel) {
            this.logOutlineLevelChange(
                'Adjust',
                targetRowNumber,
                currentLevel,
                validatedLevel,
                `Cannot be more than one level deeper than row above (level ${rowAboveLevel})`
            );
        }
        
        // If this row has children below it (next row has higher level),
        // ensure we don't reduce the level so much that it breaks the parent-child relationship
        if (rowBelowLevel > validatedLevel + 1) {
            const previousValidated = validatedLevel;
            validatedLevel = rowBelowLevel - 1;
            
            this.logOutlineLevelChange(
                'Adjust',
                targetRowNumber,
                previousValidated,
                validatedLevel,
                `Must maintain parent-child relationship with row below (level ${rowBelowLevel})`
            );
        }
        
        return validatedLevel;
    }

    /**
     * Copies outline level from one row to another
     * @param {ExcelRow} sourceRow - The source Excel row
     * @param {ExcelRow} targetRow - The target Excel row
     */
    static copyOutlineLevel(sourceRow, targetRow) {
        targetRow.outlineLevel = this.getOutlineLevel(sourceRow);
    }

    static logOutlineLevelChange(operation, rowId, oldLevel, newLevel, reason) {
        console.log(
            `[OutlineLevel] ${operation}: Row ${rowId} level changed from ${oldLevel} to ${newLevel}` +
            (reason ? ` (Reason: ${reason})` : '')
        );
    }
}