import React, { useRef, useEffect } from 'react';

const Textarea = ({ value, onChange, onBlur, onEnter, onTab }) => {
  const editorRef = useRef(null);
  const updateTimeoutRef = useRef(null);
  const isInternalChange = useRef(false);
  const lastInternalHtml = useRef('');

  const submitChanges = () => {
    if (editorRef.current) {
      const richTextParts = convertToRichText(editorRef.current);
      lastInternalHtml.current = editorRef.current.innerHTML;
      onChange(richTextParts);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent TableView from handling these keys while editing
    if (e.key === 'Enter' || 
        e.key === 'ArrowUp' || 
        e.key === 'ArrowDown' || 
        e.key === 'ArrowLeft' || 
        e.key === 'ArrowRight' || 
        e.key === 'Tab') {
      e.stopPropagation(); // Stop event from reaching TableView
    }

    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        submitChanges();
        e.target.blur();
        onEnter?.();  // Call onEnter callback if provided
      } else {
        // Shift+Enter - add line break
        e.preventDefault();
        document.execCommand('insertLineBreak');
        handleInput();
      }
      return;
    }

    if (e.ctrlKey || e.metaKey) {
      switch (e.key.toLowerCase()) {
        case 'b':
          e.preventDefault();
          document.execCommand('bold', false);
          break;
        case 'i':
          e.preventDefault();
          document.execCommand('italic', false);
          break;
        case 'u':
          e.preventDefault();
          document.execCommand('underline', false);
          break;
        default:
          break;
      }
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      onTab?.(e);
      return;
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      // Store current content
      lastInternalHtml.current = editorRef.current.innerHTML;
      
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }

      updateTimeoutRef.current = setTimeout(() => {
        isInternalChange.current = true;
        submitChanges();
      }, 300);
    }
  };

  const handleBlur = () => {
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }
    submitChanges();
    onBlur?.();  // Call onBlur callback if provided
  };

  const convertToRichText = (element) => {
    const richTextParts = [];
    
    // Handle plain text first
    if (!element.innerHTML.includes('<')) {
      return [{
        text: element.textContent,
        font: {}
      }];
    }
    
    const walker = document.createTreeWalker(
      element,
      NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
      null,
      false
    );

    let node;
    while (node = walker.nextNode()) {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent;
        if (text.trim() || text.includes('\n')) {  // Include empty lines
          const font = {};
          let parent = node.parentElement;
          
          while (parent && parent !== element) {
            if (parent.tagName === 'B' || getComputedStyle(parent).fontWeight === 'bold') {
              font.bold = true;
            }
            if (parent.tagName === 'I' || getComputedStyle(parent).fontStyle === 'italic') {
              font.italic = true;
            }
            if (parent.tagName === 'U' || getComputedStyle(parent).textDecoration.includes('underline')) {
              font.underline = true;
            }
            parent = parent.parentElement;
          }

          richTextParts.push({
            text,
            font
          });
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'BR') {
        // Add explicit line breaks
        richTextParts.push({
          text: '\n',
          font: {}
        });
      }
    }

    return richTextParts.length > 0 ? richTextParts : [{ text: '', font: {} }];
  };

  useEffect(() => {
    if (editorRef.current && !isInternalChange.current) {
      let htmlContent = '';
      
      if (Array.isArray(value)) {
        htmlContent = value.map(part => {
          let text = part.text;
          if (part.font) {
            if (part.font.bold) text = `<b>${text}</b>`;
            if (part.font.italic) text = `<i>${text}</i>`;
            if (part.font.underline) text = `<u>${text}</u>`;
          }
          return text;
        }).join('');
      } else if (value?.richText) {
        htmlContent = value.richText.map(part => {
          let text = part.text;
          if (part.font) {
            if (part.font.bold) text = `<b>${text}</b>`;
            if (part.font.italic) text = `<i>${text}</i>`;
            if (part.font.underline) text = `<u>${text}</u>`;
          }
          return text;
        }).join('');
      } else {
        htmlContent = value || '';
      }

      // Only update if content is different from last internal change
      if (htmlContent !== lastInternalHtml.current) {
        editorRef.current.innerHTML = htmlContent;
      }
    }
    isInternalChange.current = false;
  }, [value]);

  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  // Add new useEffect for autofocus
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
      
      // Create a range and set cursor to the end
      const range = document.createRange();
      const selection = window.getSelection();
      
      // Set range to end of content
      range.selectNodeContents(editorRef.current);
      range.collapse(false); // false means collapse to end
      
      // Apply the selection
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div
      ref={editorRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onBlur={handleBlur}
      suppressContentEditableWarning
      className="w-full p-0 outline-none resize-none whitespace-pre-wrap break-words"
    />
  );
};

export default Textarea;
