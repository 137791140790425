import React from 'react';
import { observer } from 'mobx-react-lite';

export const SheetViewTabs = observer(({ viewModel, activeView, onViewChange }) => {
    return (
        <div className="flex space-x-1 border-b border-zinc-200 bg-white px-4">
            {viewModel.availableViews.map(view => {
                const requirements = viewModel.checkViewRequirements(view.id);
                const hasWarning = !requirements.valid;
                
                return (
                    <button
                        key={view.id}
                        onClick={() => {
                            if (hasWarning) {
                                viewModel.showViewRequirementsModal(view.id);
                            } else {
                                onViewChange(view.id);
                            }
                        }}
                        className={`
                            flex items-center gap-2 px-4 py-2 border-b-2 
                            ${activeView === view.id 
                                ? 'border-zinc-900 text-zinc-900 font-medium' 
                                : 'border-transparent text-zinc-600 hover:text-zinc-900'
                            }
                        `}
                    >
                        <span className="text-xs">{view.icon}</span>
                        <span className='text-sm'>{view.label}</span>
                        {hasWarning && (
                            <span className="w-4 h-4 flex items-center justify-center rounded-full bg-amber-100 text-amber-600 text-xs">
                                !
                            </span>
                        )}
                    </button>
                );
            })}
        </div>
    );
});