import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone';

export const WorkbookSelector = observer(({ viewModel }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles?.length > 0) {
                const file = acceptedFiles[0];
                try {
                    await viewModel.handleFileOpen(file);
                } catch (error) {
                    console.error('Error handling dropped file:', error);
                }
            }
        },
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'text/csv': ['.csv']
        },
        multiple: false
    });

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50 p-4">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                <h1 className="text-2xl font-bold mb-6 text-center">Welcome to Workbook</h1>
                
                <div className="space-y-4">
                    <button
                        onClick={() => viewModel.handleNewWorkbook()}
                        className="w-full px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Create New Workbook
                    </button>
                    
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">or</span>
                        </div>
                    </div>

                    <div
                        {...getRootProps()}
                        className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10
                            ${isDragActive ? 'bg-blue-50' : ''}`}
                    >
                        <div className="text-center">
                            <input {...getInputProps()} />
                            <div className="flex text-sm leading-6 text-gray-600">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        viewModel.handleFileOpen();
                                    }}
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                    Upload a file
                                </button>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600">XLSX or CSV up to 10MB</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});