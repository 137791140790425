import { makeAutoObservable, action, runInAction } from 'mobx';
import * as ExcelJS from 'exceljs';
import { FileSystemService } from '../services/FileSystemService';

export class FileSystemStore {
    fileHandles = {};
    currentFileHandle = null;
    isSaved = true;
    fileSystemService = new FileSystemService();

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            rootStore: false,
            fileHandles: true,
            currentFileHandle: true,
            fileSystemService: false
        });
    }

    openFile = async () => {
        return await this.fileSystemService.openFile();
    };

    isFileSystemAccessSupported = () => {
        return this.fileSystemService.isFileSystemAccessSupported();
    };

    saveWorkbook = async (workbook, suggestedFileName = 'workbook.xlsx') => {
        console.log('Saving workbook:', { workbook, suggestedFileName, currentHandle: this.currentFileHandle });
        
        let fileName;
        if (workbook.isNewWorkbook) {
            fileName = `${workbook.properties.title}.xlsx`;
        } else {
            fileName = workbook.originalFileName || suggestedFileName;
        }

        if (this.isFileSystemAccessSupported()) {
            try {
                let handle = this.currentFileHandle;

                if (!handle) {
                    handle = await window.showSaveFilePicker({
                        suggestedName: fileName,
                        types: [{
                            description: 'Excel Files',
                            accept: {
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
                            }
                        }]
                    });
                }

                const hasPermission = await this.verifyPermission(handle, true);
                if (!hasPermission) {
                    throw new Error('Write permission denied');
                }

                const writable = await handle.createWritable();
                const buffer = await workbook.xlsx.writeBuffer();
                await writable.write(buffer);
                await writable.close();
                
                runInAction(() => {
                    this.isSaved = true;
                    this.currentFileHandle = handle;
                    this.fileHandles[fileName] = handle;
                });
                
                return true;
            } catch (error) {
                console.error('Error saving with File System API:', error);
                if (this.currentFileHandle) {
                    this.currentFileHandle = null;
                    return this.saveWorkbook(workbook, fileName);
                }
            }
        }

        try {
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
            
            runInAction(() => {
                this.isSaved = true;
            });
            
            return true;
        } catch (error) {
            console.error('Error saving workbook:', error);
            return false;
        }
    };

    verifyPermission = async (fileHandle, readWrite) => {
        const options = readWrite ? { mode: 'readwrite' } : {};
        
        try {
            await fileHandle.requestPermission(options);
            return true;
        } catch (error) {
            console.error('Permission denied:', error);
            return false;
        }
    };

    markUnsaved = action((value = false) => {
        this.isSaved = value;
    });

    clearCurrentFileHandle = action(() => {
        this.currentFileHandle = null;
    });

    saveWorkbookAs = async (workbook, fileName = 'workbook.xlsx') => {
        this.clearCurrentFileHandle();
        return this.saveWorkbook(workbook, fileName);
    };

    saveWorkbookAsCSV = async (sheet, fileName) => {
        try {
            // Check if sheet is empty or undefined
            if (!sheet) {
                console.warn('No sheet provided for CSV export');
                return false;
            }

            // Get formatted file name from workbook
            const workbook = sheet.workbook;
            const suggestedName = workbook.isNewWorkbook 
                ? workbook.properties.title 
                : (workbook.originalFileName || fileName).replace(/\.(xlsx|csv)$/, '');

            const handle = await window.showSaveFilePicker({
                suggestedName: `${suggestedName}.csv`,
                types: [{
                    description: 'CSV Files',
                    accept: {
                        'text/csv': ['.csv']
                    }
                }]
            });

            const hasPermission = await this.verifyPermission(handle, true);
            if (!hasPermission) {
                throw new Error('Write permission denied');
            }

            let csvData;
            try {
                // Handle empty sheets
                if (sheet.rowCount === 0) {
                    csvData = '';  // Empty CSV for empty sheet
                } else {
                    csvData = await sheet.csv.write();
                }
            } catch (error) {
                console.warn('Error generating CSV, falling back to empty CSV:', error);
                csvData = '';
            }

            const writable = await handle.createWritable();
            await writable.write(csvData);
            await writable.close();
            
            runInAction(() => {
                this.isSaved = true;
            });
            
            return true;
        } catch (error) {
            console.error('Error saving CSV:', error);
            return false;
        }
    };

    setCurrentFileHandle = action((handle) => {
        this.currentFileHandle = handle;
    });
}