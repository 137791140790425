import { makeObservable } from 'mobx';
import { BaseViewModel } from './BaseViewModel';

export class CalendarViewModel extends BaseViewModel {
    constructor(sheetDataStore) {
        super(sheetDataStore);
        makeObservable(this, {
            // Add observable properties and actions here
        });
    }

    get events() {
        const dateColumn = this.columns.find(col => col.type.id === 'DATE');
        const endDateColumn = this.columns.find(col => col.type.id === 'END_DATE');

        return this.data.map(row => ({
            id: row.id,
            start: row[dateColumn?.id],
            end: row[endDateColumn?.id] || row[dateColumn?.id],
            title: row[this.columns[0].id] // Using first column as title
        }));
    }

    updateEvent = (eventId, updates) => {
        Object.entries(updates).forEach(([key, value]) => {
            this.sheetDataStore.updateCell(eventId, key, value);
        });
    };
}