import React, { useEffect } from 'react';

export const InputRating = ({ value, onChange, onBlur, initialValue }) => {
    // Process initialValue on mount
    useEffect(() => {
        if (initialValue) {
            const num = Number(initialValue);
            if (!isNaN(num) && num >= 1 && num <= 5) {
                onChange(num);
            }
        }
    }, [initialValue, onChange]);

    // Process window.initialKeystroke
    useEffect(() => {
        if (window.initialKeystroke) {
            const num = Number(window.initialKeystroke);
            if (!isNaN(num) && num >= 1 && num <= 5) {
                onChange(num);
                window.initialKeystroke = null;
            }
        }
    }, [onChange]);

    const handleKeyDown = (e) => {
        const num = Number(e.key);
        if (!isNaN(num) && num >= 1 && num <= 5) {
            onChange(num);
        } else if (e.key === '0' || e.key === 'Backspace' || e.key === 'Delete') {
            onChange(0);
        }
    };

    const [hoverRating, setHoverRating] = React.useState(0);

    return (
        <div 
            className="flex gap-0.5 text-lg" 
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
        >
            {[1,2,3,4,5].map(rating => (
                <button
                    key={rating}
                    onClick={(e) => {
                        e.preventDefault();
                        onChange(value === rating ? 0 : rating);
                    }}
                    onMouseEnter={() => setHoverRating(rating)}
                    onMouseLeave={() => setHoverRating(0)}
                    className={`${
                        rating <= (hoverRating || value || 0)
                            ? 'text-yellow-400' 
                            : 'text-gray-300'
                    } hover:text-yellow-400 transition-colors duration-50 outline-none`}
                >
                    ★
                </button>
            ))}
        </div>
    );
};