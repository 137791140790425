import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ColumnTypes } from '../../models/SheetModel';
import { ColumnRowValidationEditor } from '../Components/Column/ColumnRowValidationEditor';
import Combobox from '../Components/Combobox/Combobox';
import SelectDropdown from '../Components/Dropdown/SelectDropdown';
import { 
    ArrowLeftIcon, 
    ArrowRightIcon, 
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    PlusIcon, 
    TrashIcon,
    EllipsisVerticalIcon,
    CheckIcon
} from '@heroicons/react/20/solid';

// Import outline icons instead of solid
import {
    Bars2Icon,           // New for single line text
    DocumentTextIcon,    // For multi-line text
    AtSymbolIcon,        // New for email
    LinkIcon,
    CalendarIcon,
    RocketLaunchIcon,    // New for start date
    FlagIcon,            // For end date
    ListBulletIcon,
    TagIcon,             // New for status
    UserIcon,
    StarIcon,
    CheckCircleIcon,
    HashtagIcon,
    CurrencyDollarIcon,   // For percentage (no DivideIcon available)
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon
} from '@heroicons/react/24/outline';

// Map emoji icons to Heroicons
const iconMap = {
    '📝': <Bars2Icon className="h-4 w-4" />,              // Changed for single line text
    '📄': <DocumentTextIcon className="h-4 w-4" />,       // Changed for multi-line text
    '✉️': <AtSymbolIcon className="h-4 w-4" />,           // Changed for email
    '🔗': <LinkIcon className="h-4 w-4" />,
    '📅': <CalendarIcon className="h-4 w-4" />,
    '🏁': <RocketLaunchIcon className="h-4 w-4" />,       // Changed for start date
    '🏆': <FlagIcon className="h-4 w-4" />,               // Changed for end date
    '📋': <ListBulletIcon className="h-4 w-4" />,
    '🔄': <TagIcon className="h-4 w-4" />,                // Changed for status
    '👤': <UserIcon className="h-4 w-4" />,
    '⭐': <StarIcon className="h-4 w-4" />,
    '✅': <CheckCircleIcon className="h-4 w-4" />,
    '%': <CurrencyDollarIcon className="h-4 w-4" />,      // Using CurrencyDollarIcon for percentage
    '#': <HashtagIcon className="h-4 w-4" />
};

// Helper function to get the appropriate icon
const getIcon = (emojiIcon) => {
    return iconMap[emojiIcon] || <span>{emojiIcon}</span>;
};

export const TableHeader = observer(({ 
    column, 
    onUpdateHeading, 
    onUpdateType, 
    onDelete,
    onMoveLeft,
    onMoveRight,
    onAddLeft,
    onAddRight,
    onDecreaseWidth,
    onIncreaseWidth,
    isFirst,
    isLast,
    searchActive,
    onUpdateValidation
}) => {
    const [typeComboboxOpen, setTypeComboboxOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [actionsDropdownOpen, setActionsDropdownOpen] = useState(false);
    const containerRef = useRef(null);
    const comboboxInputRef = useRef(null);
    const headingRef = useRef(null);

    // Initialize editing value when editing starts
    useEffect(() => {
        if (isEditing && headingRef.current) {
            headingRef.current.textContent = column.heading;
            placeCaretAtEnd(headingRef.current);
        }
    }, [isEditing, column.heading]);

    // Convert ColumnTypes to options format for Combobox
    const typeOptions = Object.values(ColumnTypes).map(type => ({
        value: type.id,
        label: type.label,
        icon: type.icon,
        emojiIcon: type.icon
    }));

    // Custom render function for column type options
    const renderTypeOption = (option, { isSelected, isHighlighted }) => (
        <div className="flex items-center gap-2 py-0.5">
            <span className="text-gray-500">{getIcon(option.emojiIcon)}</span>
            <span className="text-xs">{option.label}</span>
            {isSelected && (
                <CheckIcon className="h-4 w-4 ml-auto text-purple-500" />
            )}
        </div>
    );

    // Custom render function for the selected type
    const renderSelectedType = (option) => (
        <div className="flex items-center gap-2">
            <span className="text-gray-500">{getIcon(option.emojiIcon)}</span>
            <span className="text-xs">{option.label}</span>
        </div>
    );

    const handleTypeChange = (selectedOption) => {
        if (selectedOption && selectedOption.value) {
            onUpdateType(column.id, ColumnTypes[selectedOption.value]);
            setTypeComboboxOpen(false);
        }
    };

    const handleIconClick = () => {
        setTypeComboboxOpen(true);
        // Give time for state to update before focusing
        setTimeout(() => {
            if (comboboxInputRef.current) {
                comboboxInputRef.current.focus();
            }
        }, 10);
    };

    // Place cursor at the end of content when focusing
    const placeCaretAtEnd = (element) => {
        if (!element) return;
        
        const range = document.createRange();
        const selection = window.getSelection();
        
        range.selectNodeContents(element);
        range.collapse(false); // false means collapse to end
        
        selection.removeAllRanges();
        selection.addRange(range);
    };

    const handleHeadingFocus = () => {
        setIsEditing(true);
        // Place cursor at end of text
        setTimeout(() => placeCaretAtEnd(headingRef.current), 0);
    };

    const handleHeadingBlur = () => {
        setIsEditing(false);
        if (headingRef.current) {
            const newHeading = headingRef.current.textContent || '';
            // Only update if the heading actually changed
            if (newHeading !== column.heading) {
                onUpdateHeading(column.id, newHeading);
            }
        }
    };

    const handleHeadingKeyDown = (e) => {
        if (!isEditing) return;
        
        e.stopPropagation();
        
        if (e.key === 'Enter') {
            e.preventDefault();
            headingRef.current.blur();
        } else if (e.key === 'Escape') {
            e.preventDefault();
            // Revert to original value
            if (headingRef.current) {
                headingRef.current.textContent = column.heading;
            }
            headingRef.current.blur();
        }
    };

    // Prevent propagation of events when editing
    const stopPropagation = (e) => {
        if (isEditing) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    // Create options for the column actions dropdown with proper icons
    const getColumnActionOptions = () => {
        const options = [];
        
        // Move options
        options.push(
            {
                label: "Move left",
                icon: <ArrowLeftIcon className="h-4 w-4" />,
                value: "moveLeft",
                disabled: isFirst
            },
            {
                label: "Move right",
                icon: <ArrowRightIcon className="h-4 w-4" />,
                value: "moveRight",
                disabled: isLast
            }
        );
        
        // Add divider
        options.push({ type: "divider" });
        
        // Width adjustment options with new icons
        options.push(
            {
                label: "Decrease width",
                icon: <ArrowsPointingInIcon className="h-4 w-4" />,
                value: "decreaseWidth"
            },
            {
                label: `Current width: ${column.width}px`,
                icon: <span className="h-4 w-4 flex items-center justify-center text-xs">↔</span>,
                value: "widthInfo",
                disabled: true
            },
            {
                label: "Increase width",
                icon: <ArrowsPointingOutIcon className="h-4 w-4" />,
                value: "increaseWidth"
            }
        );
        
        // Add divider
        options.push({ type: "divider" });
        
        // Add column options
        options.push(
            {
                label: "Insert left",
                icon: <ArrowUturnLeftIcon className="h-4 w-4" />,
                value: "addLeft",
                disabled: searchActive
            },
            {
                label: "Insert right",
                icon: <ArrowUturnRightIcon className="h-4 w-4" />,
                value: "addRight",
                disabled: searchActive
            }
        );
        
        // Add divider
        options.push({ type: "divider" });
        
        // Delete option
        options.push({
            label: "Delete column",
            icon: <TrashIcon className="h-4 w-4" />,
            value: "delete",
            destructive: true,
            disabled: searchActive
        });
        
        return options;
    };
    
    // Handle column action selection
    const handleColumnActionSelect = (action) => {
        switch (action) {
            case "moveLeft":
                onMoveLeft(column.id);
                break;
            case "moveRight":
                onMoveRight(column.id);
                break;
            case "decreaseWidth":
                onDecreaseWidth(column.id);
                break;
            case "increaseWidth":
                onIncreaseWidth(column.id);
                break;
            case "addLeft":
                onAddLeft(column.id);
                break;
            case "addRight":
                onAddRight(column.id);
                break;
            case "delete":
                onDelete(column.id);
                break;
            default:
                break;
        }
    };

    // Add this function to properly position the dropdown
    const positionTypeDropdown = () => {
        if (!typeComboboxOpen || !containerRef.current) return;
        
        // Get the position of the icon that was clicked
        const iconElement = containerRef.current.querySelector('[title*="Click to change type"]');
        if (!iconElement) return;
        
        const iconRect = iconElement.getBoundingClientRect();
        
        // Force the dropdown to appear below the icon
        const dropdownElement = document.querySelector('#combobox-listbox');
        if (dropdownElement) {
            dropdownElement.style.position = 'fixed';
            dropdownElement.style.top = `${iconRect.bottom + 4}px`;
            dropdownElement.style.left = `${iconRect.left}px`;
            dropdownElement.style.minWidth = '200px';
        }
    };

    // Use this effect to position the dropdown after it opens
    useEffect(() => {
        if (typeComboboxOpen) {
            // Small delay to ensure the dropdown is rendered
            setTimeout(positionTypeDropdown, 50);
            
            // Add scroll event listener to reposition dropdown when scrolling
            const handleScroll = () => {
                positionTypeDropdown();
            };
            
            window.addEventListener('scroll', handleScroll, true);
            
            // Clean up the event listener when dropdown closes
            return () => {
                window.removeEventListener('scroll', handleScroll, true);
            };
        }
    }, [typeComboboxOpen]);

    return (
        <div 
            className="flex flex-col gap-2 group"
            ref={containerRef}
            onMouseDown={isEditing ? stopPropagation : undefined}
            onMouseUp={isEditing ? stopPropagation : undefined}
            onClick={isEditing ? stopPropagation : undefined}
        >
            <div className="flex items-center gap-0 text-sm">
                {/* Make the icon look clickable */}
                <span 
                    className="w-7 h-7 flex items-center justify-center text-sm rounded border border-transparent hover:border-gray-200 hover:bg-gray-100 text-gray-400 hover:text-gray-500 hover:bg-white cursor-pointer transition-all duration-150"
                    title={`${column.type.label} - Click to change type`}
                    onClick={handleIconClick}
                >
                    {getIcon(column.type.icon)}
                </span>
                
                <div
                    ref={headingRef}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    onFocus={handleHeadingFocus}
                    onBlur={handleHeadingBlur}
                    onKeyDown={handleHeadingKeyDown}
                    onClick={stopPropagation}
                    onMouseDown={stopPropagation}
                    onMouseUp={stopPropagation}
                    onTouchStart={stopPropagation}
                    onTouchEnd={stopPropagation}
                    onTouchMove={stopPropagation}
                    className={`pl-1 text-xs text-gray-900 tracking-tight font-bold text-left outline-none flex-grow ${isEditing ? '' : 'cursor-text'}`}
                    style={{ 
                        whiteSpace: 'nowrap', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis',
                        position: 'relative',
                        zIndex: isEditing ? 1000 : 'auto'
                    }}
                >
                    {column.heading}
                </div>
                
                {/* Ellipsis menu button */}
                <div className="ml-auto flex items-center h-full ring-0 focus:ring-0">
                    <SelectDropdown
                        placeholder=""
                        options={getColumnActionOptions()}
                        onChange={handleColumnActionSelect}
                        stopPropagation={true}
                        showDropdownButton={false}
                        onOpenChange={setActionsDropdownOpen}
                        isOpen={actionsDropdownOpen}
                        customTrigger={
                            <div className={`h-7 w-7 flex items-center justify-center rounded border ${actionsDropdownOpen ? 'border-gray-200 bg-gray-100' : 'border-transparent hover:border-gray-200 hover:bg-white'} cursor-pointer transition-all duration-150 ring-0 focus:ring-0`}>
                                <EllipsisVerticalIcon 
                                    className={`h-4 w-4 ${actionsDropdownOpen ? 'text-gray-500' : 'text-transparent group-hover:text-gray-400 hover:text-gray-500'}`}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
            
            {/* Combobox for column type selection - make it visible but with zero opacity */}
            <div className="absolute" style={{ opacity: 0, pointerEvents: typeComboboxOpen ? 'auto' : 'none' }}>
                <Combobox
                    options={typeOptions}
                    value={[{ 
                        value: column.type.id, 
                        label: column.type.label, 
                        icon: column.type.icon,
                        emojiIcon: column.type.icon 
                    }]}
                    onChange={handleTypeChange}
                    renderOption={renderTypeOption}
                    renderSelectedItem={renderSelectedType}
                    placeholder="Select column type..."
                    isOpen={typeComboboxOpen}
                    onOpenChange={setTypeComboboxOpen}
                    containerRef={document.body}
                    showDropdownButton={false}
                    inputRef={comboboxInputRef}
                    withBorder={false}
                    maxHeight={250}
                    stopPropagation={true}
                    position="bottom"
                    customStyles={{
                        option: "py-1.5 px-3 text-xs",
                        dropdown: "text-xs shadow-lg"
                    }}
                />
            </div>
            
            {/* {column.type.hasCustomValidation && (
                <ColumnRowValidationEditor
                    column={column}
                    onUpdateValidation={(options) => onUpdateValidation(column.id, options)}
                />
            )} */}
        </div>
    );
});