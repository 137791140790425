import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ChevronDownIcon, XMarkIcon, PlusIcon, CheckIcon } from '@heroicons/react/20/solid';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';

const Combobox = ({
  options = [],
  placeholder = 'Select...',
  onChange,
  multiSelect = false,
  isOpen: controlledIsOpen,
  onOpenChange,
  renderOption,
  maxHeight = 250,
  allowCreation = false,
  onCreateOption,
  allowRemoval = false,
  onRemoveOption,
  renderSelectedItem,
  displayType = 'default', // 'default', 'avatar', 'badge'
  showFocusRing = false,
  withBorder = false,
  containerRef = null,
  stopPropagation = false,
  inputRef = null,
  value = [],
  onEscape,
  showDropdownButton = true,
  initialInputValue = '',
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isOpenState = controlledIsOpen !== undefined ? controlledIsOpen : internalIsOpen;
  
  const [isClosing, setIsClosing] = useState(false);
  
  const setIsOpenState = (newState) => {
    if (isOpenState && !newState) {
      // Start closing animation
      setIsClosing(true);
      // Wait for animation to complete before actually closing
      setTimeout(() => {
        setInternalIsOpen(false);
        setIsClosing(false);
        if (onOpenChange) {
          onOpenChange(false);
        }
      }, 150); // Match this to your animation duration
    } else {
      setInternalIsOpen(newState);
      if (onOpenChange) {
        onOpenChange(newState);
      }
    }
  };

  const [searchTerm, setSearchTerm] = useState(initialInputValue);
  const [selectedItems, setSelectedItems] = useState(Array.isArray(value) ? value : []);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const comboboxRef = useRef(null);
  const internalInputRef = useRef(null);
  const effectiveInputRef = inputRef || internalInputRef;
  const listboxRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [dropdownHeight, setDropdownHeight] = useState(0);

  // Manage internal options state
  const [internalOptions, setInternalOptions] = useState(options);
  
  // Update internal options when external options change
  useEffect(() => {
    setInternalOptions(options);
  }, [options]);

  // Filter options based on search term
  const getFilteredOptions = () => {
    if (!searchTerm) return internalOptions;
    return internalOptions.filter(option => 
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Check if we can create a new option
  const canCreateOption = allowCreation && 
    searchTerm.trim() !== '' && 
    !getFilteredOptions().some(option => 
      option.label.toLowerCase() === searchTerm.toLowerCase()
    );

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        setIsOpenState(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsOpenState(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [setIsOpenState]);

  // Reset highlighted index when filtered options change or dropdown opens
  useEffect(() => {
    if (isOpenState) {
      const optionsLength = getFilteredOptions().length + (canCreateOption ? 1 : 0);
      if (optionsLength > 0 && (highlightedIndex < 0 || highlightedIndex >= optionsLength)) {
        setHighlightedIndex(0);
      }
    }
  }, [getFilteredOptions, isOpenState, canCreateOption, highlightedIndex]);

  // Scroll highlighted item into view
  // useEffect(() => {
  //   if (isOpenState && listboxRef.current && highlightedIndex >= 0) {
  //     const highlightedElement = listboxRef.current.children[highlightedIndex];
  //     if (highlightedElement) {
  //       highlightedElement.scrollIntoView({
  //         block: 'nearest',
  //         inline: 'nearest',
  //       });
  //     }
  //   }
  // }, [highlightedIndex, isOpenState]);

  // Force the dropdown to open when the component mounts if controlledIsOpen is true
  useEffect(() => {
    if (controlledIsOpen === true) {
      // Small delay to ensure DOM is ready
      const timer = setTimeout(() => {
        updateDropdownPosition();
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [controlledIsOpen]);
  
  // Function to update dropdown position
  const updateDropdownPosition = () => {
    // Use the combobox container's position instead of the input element
    if (!comboboxRef.current) return;
    
    // Get the container element's position
    const containerRect = comboboxRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Calculate minimum width
    const minWidth = Math.max(200, containerRect.width);
    
    // Get actual dropdown height if available, otherwise estimate
    const actualDropdownHeight = dropdownHeight || (() => {
      const filteredOptions = getFilteredOptions();
      const optionCount = Math.max(1, filteredOptions.length + (canCreateOption ? 1 : 0));
      const optionHeight = 36; // Average height of an option
      const dropdownPadding = 16; // Top and bottom padding
      return Math.min(maxHeight, optionCount * optionHeight + dropdownPadding);
    })();
    
    // Calculate available space below and above the container
    const spaceBelow = viewportHeight - (containerRect.bottom - window.scrollY);
    const spaceAbove = containerRect.top - window.scrollY;
    
    let top, left, width;
    
    // Determine if dropdown should appear above or below
    if (actualDropdownHeight > spaceBelow && spaceAbove > spaceBelow && spaceAbove >= actualDropdownHeight) {
      // Position above if there's more space and enough to fit + 4px buffer
      top = containerRect.top - actualDropdownHeight + window.scrollY - 4;
    } else {
      // Position below (default) + 4px buffer
      top = containerRect.bottom + window.scrollY + 4;
      
      // If there's not enough space below but we're still positioning below,
      // adjust the max height to fit in the available space
      if (actualDropdownHeight > spaceBelow) {
        // Leave a small buffer (10px) from the bottom of the viewport
        const adjustedMaxHeight = spaceBelow - 10;
        if (adjustedMaxHeight >= 100) { // Ensure a minimum usable height
          maxHeight = adjustedMaxHeight;
        }
      }
    }
    
    // Calculate horizontal position
    left = containerRect.left + window.scrollX;
    width = minWidth;
    
    // Ensure dropdown doesn't extend beyond right edge of viewport
    if (left + width > viewportWidth + window.scrollX) {
      // Align to right edge of the container
      left = containerRect.right - width + window.scrollX;
      
      // If still off-screen, align to right edge of viewport
      if (left < window.scrollX) {
        left = window.scrollX + 10; // 10px buffer from left edge
      }
    }
    
    setDropdownPosition({
      top,
      left,
      width,
    });
  };
  
  // Update dropdown position when open state changes
  useEffect(() => {
    if (isOpenState) {
      updateDropdownPosition();
      
      // Add event listeners for repositioning
      window.addEventListener('resize', updateDropdownPosition);
      
      // Use a more targeted approach for scroll events
      const scrollHandler = (e) => {
        // Don't prevent default scrolling, just update position
        updateDropdownPosition();
      };
      
      window.addEventListener('scroll', scrollHandler, true);
      
      return () => {
        window.removeEventListener('resize', updateDropdownPosition);
        window.removeEventListener('scroll', scrollHandler, true);
      };
    }
  }, [isOpenState]);

  // Add a separate effect to handle content size changes
  useEffect(() => {
    if (isOpenState && listboxRef.current) {
      // Initial position update
      updateDropdownPosition();
      
      // Use ResizeObserver to detect content size changes
      const resizeObserver = new ResizeObserver(() => {
        updateDropdownPosition();
      });
      
      resizeObserver.observe(listboxRef.current);
      
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [isOpenState, getFilteredOptions().length, canCreateOption]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (!isOpenState) {
      setIsOpenState(true);
    }
  };

  // Handle selecting an option
  const handleSelectOption = (option) => {
    if (multiSelect) {
      // For multi-select, add to selected items if not already selected
      if (!selectedItems.some(item => item.value === option.value)) {
        const newSelectedItems = [...selectedItems, option];
        setSelectedItems(newSelectedItems);
        onChange?.(newSelectedItems);
      }
    } else {
      // For single-select, replace selected items
      setSelectedItems([option]);
      onChange?.(option);
    }
    setSearchTerm('');
    if (!multiSelect) {
      setIsOpenState(false);
    }
  };

  // Handle creating a new option
  const handleCreateOption = () => {
    if (!searchTerm.trim()) return;
    
    const newOption = {
      value: searchTerm.trim(),
      label: searchTerm.trim()
    };
    
    onCreateOption?.(newOption);
    setSearchTerm('');
    if (!multiSelect) {
      setIsOpenState(false);
    }
  };

  // Handle key events
  const handleKeyDown = (e) => {
    // Check if the input is focused - only then should we prioritize text editing
    const isInputFocused = document.activeElement === effectiveInputRef.current;
    
    // Handle Escape key regardless of focus state
    if (e.key === 'Escape') {
      if (stopPropagation) {
        e.stopPropagation();
      }
      setIsOpenState(false);
      onEscape?.();
      return;
    }
    
    // If input is focused, prioritize text editing shortcuts
    if (isInputFocused) {
      // Handle backspace when input is empty and there are selected items
      if (e.key === 'Backspace' && searchTerm === '' && selectedItems.length > 0) {
        e.preventDefault();
        e.stopPropagation();
        // Remove the last selected item
        const newSelectedItems = selectedItems.slice(0, -1);
        setSelectedItems(newSelectedItems);
        onChange?.(multiSelect ? newSelectedItems : null);
        return;
      }
      
      // Allow all text editing shortcuts to work normally
      // This includes: Shift+Arrow, Cmd/Ctrl+Arrow, Shift+Home/End, etc.
      const isTextEditingKey = (
        // Arrow keys with any modifier for selection/navigation
        ((e.key === 'ArrowLeft' || e.key === 'ArrowRight' || 
          e.key === 'ArrowUp' || e.key === 'ArrowDown') && 
         (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey)) ||
        // Home/End keys for start/end of line
        e.key === 'Home' || e.key === 'End' ||
        // Delete/Backspace for text deletion
        e.key === 'Delete' || e.key === 'Backspace' ||
        // Any key with Cmd/Ctrl for shortcuts like copy/paste/select all
        (e.metaKey || e.ctrlKey)
      );
      
      if (isTextEditingKey) {
        // Stop propagation to prevent parent components from handling these keys
        e.stopPropagation();
        // Let the browser handle these keys normally
        return;
      }
      
      // For unmodified arrow keys, we can still use them for dropdown navigation
      if (e.key === 'ArrowDown' && !e.shiftKey && !e.metaKey && !e.ctrlKey && !e.altKey) {
        e.preventDefault(); // Prevent cursor movement
        e.stopPropagation(); // Stop propagation to parent components
        if (!isOpenState) {
          setIsOpenState(true);
        } else {
          setHighlightedIndex((prevIndex) => {
            const filteredOptions = getFilteredOptions();
            const optionsLength = filteredOptions.length + (canCreateOption ? 1 : 0);
            const nextIndex = prevIndex + 1;
            return nextIndex >= optionsLength ? 0 : nextIndex;
          });
        }
      } else if (e.key === 'ArrowUp' && !e.shiftKey && !e.metaKey && !e.ctrlKey && !e.altKey) {
        e.preventDefault(); // Prevent cursor movement
        e.stopPropagation(); // Stop propagation to parent components
        if (!isOpenState) {
          setIsOpenState(true);
        } else {
          setHighlightedIndex((prevIndex) => {
            const filteredOptions = getFilteredOptions();
            const optionsLength = filteredOptions.length + (canCreateOption ? 1 : 0);
            const nextIndex = prevIndex - 1;
            return nextIndex < 0 ? optionsLength - 1 : nextIndex;
          });
        }
      } else if (e.key === 'Enter' && isOpenState) {
        e.preventDefault(); // Prevent form submission
        e.stopPropagation(); // Stop propagation to parent components
        const filteredOptions = getFilteredOptions();
        if (filteredOptions.length > 0 && highlightedIndex < filteredOptions.length) {
          const selectedOption = filteredOptions[highlightedIndex];
          handleSelectOption(selectedOption);
        } else if (canCreateOption && highlightedIndex === filteredOptions.length) {
          handleCreateOption();
        }
      } else if (e.key === 'Tab' && isOpenState) {
        // Close dropdown on Tab but don't prevent default behavior
        setIsOpenState(false);
      } else {
        // For any other key when input is focused, stop propagation
        // This prevents the table from handling these keys
        e.stopPropagation();
      }
    } else {
      // If input is not focused, handle navigation keys normally for the dropdown
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        if (!isOpenState) {
          setIsOpenState(true);
        } else {
          setHighlightedIndex((prevIndex) => {
            const filteredOptions = getFilteredOptions();
            const optionsLength = filteredOptions.length + (canCreateOption ? 1 : 0);
            const nextIndex = prevIndex + 1;
            return nextIndex >= optionsLength ? 0 : nextIndex;
          });
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        if (!isOpenState) {
          setIsOpenState(true);
        } else {
          setHighlightedIndex((prevIndex) => {
            const filteredOptions = getFilteredOptions();
            const optionsLength = filteredOptions.length + (canCreateOption ? 1 : 0);
            const nextIndex = prevIndex - 1;
            return nextIndex < 0 ? optionsLength - 1 : nextIndex;
          });
        }
      } else if (e.key === 'Enter' && isOpenState) {
        e.preventDefault();
        e.stopPropagation();
        const filteredOptions = getFilteredOptions();
        if (filteredOptions.length > 0 && highlightedIndex < filteredOptions.length) {
          const selectedOption = filteredOptions[highlightedIndex];
          handleSelectOption(selectedOption);
        } else if (canCreateOption && highlightedIndex === filteredOptions.length) {
          handleCreateOption();
        }
      }
    }
  };

  const handleSelectItem = (option, e) => {
    // Ensure we stop propagation immediately
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    console.log('Selecting item:', option);
    
    if (multiSelect) {
      const isSelected = selectedItems.some(item => item.value === option.value);
      const newSelectedItems = isSelected
        ? selectedItems.filter(item => item.value !== option.value)
        : [...selectedItems, option];
      
      setSelectedItems(newSelectedItems);
      onChange?.(newSelectedItems);
    } else {
      setSelectedItems([option]);
      onChange?.(option);
      setIsOpenState(false);
    }
    setSearchTerm('');
  };

  const removeItem = (option, e) => {
    e.stopPropagation();
    const newSelectedItems = selectedItems.filter(item => item.value !== option.value);
    setSelectedItems(newSelectedItems);
    onChange?.(multiSelect ? newSelectedItems : null);
  };

  const toggleDropdown = () => {
    const newIsOpen = !isOpenState;
    setIsOpenState(newIsOpen);
    if (newIsOpen) {
      effectiveInputRef.current?.focus();
    }
  };

  const renderSelectedItemContent = (item) => {
    if (renderSelectedItem) {
      return renderSelectedItem(item);
    }

    switch (displayType) {
      case 'avatar':
        return <Avatar name={item.label} size="xs" showName={true} />;
      case 'badge':
        return <Badge text={item.label} />;
      default:
        return item.label;
    }
  };

  // Handle removing an option from the options list
  const handleRemoveOption = (option, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    // Check if the option is currently selected
    const isSelected = selectedItems.some(item => item.value === option.value);
    
    // Don't remove if the option is selected
    if (isSelected) {
      return;
    }
    
    // Update internal options state
    if (allowRemoval) {
      setInternalOptions(prevOptions => 
        prevOptions.filter(item => item.value !== option.value)
      );
    }
    
    // Call the callback to notify parent component
    if (onRemoveOption) {
      onRemoveOption(option);
    }
  };

  const defaultRenderOption = (option, { isSelected, isHighlighted }) => {
    const optionContent = (() => {
      switch (displayType) {
        case 'avatar':
          return (
            <Avatar 
              name={option.label} 
              size="xs" 
              subtitle={option.subtitle}
            />
          );
        case 'badge':
          return <Badge text={option.label} />;
        default:
          return <span className="text-xs">{option.label}</span>;
      }
    })();

    return (
      <div className="flex items-center justify-between w-full">
        {optionContent}
        <div className="flex items-center ml-2">
          {isSelected && (
            <CheckIcon className="h-4 w-4 text-purple-600" />
          )}
          {allowRemoval && !isSelected && (
            <XMarkIcon 
              className="h-4 w-4 text-gray-400 hover:text-red-500 cursor-pointer" 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleRemoveOption(option, e);
              }}
              onMouseDown={(e) => {
                // This is crucial to prevent the parent's onMouseDown from firing
                e.preventDefault();
                e.stopPropagation();
              }}
              aria-label={`Remove ${option.label}`}
            />
          )}
        </div>
      </div>
    );
  };

  // Render dropdown with improved positioning and animation
  const renderDropdown = () => {
    if (!isOpenState && !isClosing) return null;
    
    const dropdownElement = (
      <div 
        id="combobox-listbox"
        ref={(el) => {
          listboxRef.current = el;
          // Measure the actual height after rendering
          if (el) {
            const actualHeight = el.getBoundingClientRect().height;
            if (actualHeight !== dropdownHeight) {
              setDropdownHeight(actualHeight);
              // Update position with the new height
              updateDropdownPosition();
            }
          }
        }}
        className={`z-[9999] bg-white border border-gray-200 rounded-md shadow-lg text-xs`}
        style={{ 
          maxHeight: `${maxHeight}px`, 
          overflowY: 'auto',
          width: `${dropdownPosition.width}px`,
          position: containerRef ? 'absolute' : 'fixed',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          display: 'block',
          minWidth: '200px', // Ensure minimum width
          opacity: isClosing ? 0 : 1,
          transform: isClosing ? 'translateY(-10px)' : 'translateY(0)',
          transition: 'opacity 150ms ease-in-out, transform 150ms ease-in-out',
          animation: !isClosing ? 'dropdownOpen 150ms ease-in-out' : undefined,
        }}
        role="listbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {getFilteredOptions().length === 0 && !canCreateOption ? (
          <div className="p-1.5 text-xs text-gray-500">No options found</div>
        ) : (
          <>
            {getFilteredOptions().map((option, index) => {
              const isHighlighted = index === highlightedIndex;
              const isSelected = selectedItems.some(item => item.value === option.value);
              
              return (
                <div
                  key={option.value}
                  role="option"
                  aria-selected={isSelected}
                  className={`py-1.5 px-3 cursor-pointer text-xs ${
                    isHighlighted ? 'bg-gray-50' : ''
                  } ${isSelected ? '' : ''}`}
                  onMouseDown={(e) => {
                    // Use onMouseDown instead of onClick to prevent focus issues
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelectItem(option, e);
                  }}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {renderOption ? 
                    renderOption(option, { isSelected, isHighlighted, onRemove: allowRemoval ? () => handleRemoveOption(option) : undefined }) : 
                    defaultRenderOption(option, { isSelected, isHighlighted })
                  }
                </div>
              );
            })}
            
            {/* Create option */}
            {canCreateOption && (
              <div
                role="option"
                className={`px-3 py-1.5 cursor-pointer text-xs ${
                  highlightedIndex === getFilteredOptions().length ? 'bg-blue-50' : ''
                }`}
                onMouseDown={(e) => {
                  // Use onMouseDown instead of onClick to prevent focus issues
                  e.preventDefault();
                  e.stopPropagation();
                  handleCreateOption();
                }}
                onMouseEnter={() => setHighlightedIndex(getFilteredOptions().length)}
              >
                <div className="flex items-center text-blue-600">
                  <PlusIcon className="h-3 w-3 mr-2" />
                  <span>Create "{searchTerm}"</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
    
    // Add keyframes for the opening animation
    const keyframes = `
      @keyframes dropdownOpen {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `;
    
    // If containerRef is provided, render directly in the container
    if (containerRef && containerRef.current) {
      return ReactDOM.createPortal(
        <>
          <style>{keyframes}</style>
          {dropdownElement}
        </>, 
        containerRef.current
      );
    }
    
    // Otherwise use portal to document.body
    return ReactDOM.createPortal(
      <>
        <style>{keyframes}</style>
        {dropdownElement}
      </>, 
      document.body
    );
  };

  // Ensure selected items are displayed correctly
  useEffect(() => {
    if (Array.isArray(value) && value.length > 0) {
      setSelectedItems(value);
    }
  }, [value]);

  // Function to handle removing an item (for multiSelect)
  const handleRemoveItem = (itemToRemove) => {
    const newSelectedItems = selectedItems.filter(item => item.value !== itemToRemove.value);
    setSelectedItems(newSelectedItems);
    onChange?.(multiSelect ? newSelectedItems : null);
  };

  // Default render function for selected items
  const defaultRenderSelectedItem = (item, onRemove, displayType) => {
    switch (displayType) {
      case 'avatar':
        return (
          <div className="flex items-center pr-1">
            <Avatar size="xs" name={item.label} />
            {multiSelect && (
              <XMarkIcon 
                className="h-4 w-4 ml-1 text-gray-500 hover:text-gray-700 cursor-pointer" 
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              />
            )}
          </div>
        );
      case 'badge':
        return (
          <div className="flex items-center pr-1">
          <Badge 
            text={item.label}
            onRemove={multiSelect ? onRemove : undefined}
          />
          </div>
        );
      default:
        return (
          <div className="flex items-center bg-gray-100 rounded-md px-2 py-0.5">
            <span className="text-xs">{item.label}</span>
            {multiSelect && (
              <XMarkIcon 
                className="h-4 w-4 ml-1 text-gray-500 hover:text-gray-700 cursor-pointer" 
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove();
                }}
              />
            )}
          </div>
        );
    }
  };

  // Effect to handle initialInputValue changes
  useEffect(() => {
    if (initialInputValue) {
      setSearchTerm(initialInputValue);
      if (inputRef?.current) {
        inputRef.current.focus();
        // Position cursor at end of input
        inputRef.current.setSelectionRange(
          initialInputValue.length,
          initialInputValue.length
        );
      }
    }
  }, [initialInputValue]);

  return (
    <div ref={comboboxRef} className="flex relative w-full h-full">
      <div 
        className={`flex items-center w-full p-0 text-xs ${
          withBorder ? 'border border-gray-300 rounded-md px-2 py-1.5' : ''
        } ${
          showFocusRing ? 'focus-within:ring-2 focus-within:ring-purple-500 focus-within:border-purple-500' : ''
        }`}
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
          if (!isOpenState) {
            setIsOpenState(true);
            effectiveInputRef.current?.focus();
          }
        }}
      >

        {/* Selected items */}
        <div className="flex flex-wrap gap-1">
          {selectedItems.map((item) => (
            <div key={item.value} className="flex items-center">
              {renderSelectedItem ? (
                renderSelectedItem(item, () => handleRemoveItem(item))
              ) : (
                defaultRenderSelectedItem(item, () => handleRemoveItem(item), displayType)
              )}
            </div>
          ))}
        </div>

        {/* Input */}
        <div className="flex-auto">
          <input
            ref={effectiveInputRef}
            type="text"
            className="w-full border-none focus:ring-0 focus:outline-none bg-transparent text-xs"
            placeholder={selectedItems.length === 0 ? placeholder : ''}
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => {
              if (!isOpenState) {
                setIsOpenState(true);
              }
            }}
            role="combobox"
            aria-controls="combobox-listbox"
            aria-expanded={isOpenState}
            aria-haspopup="listbox"
            aria-autocomplete="list"
          />
        </div>
        
        {/* Dropdown button - only show if showDropdownButton is true */}
        {showDropdownButton && (
          <button
            type="button"
            onClick={toggleDropdown}
            className="flex items-center justify-center p-0 text-gray-400 hover:text-gray-600"
            aria-label={isOpenState ? 'Close dropdown' : 'Open dropdown'}
          >
            <ChevronDownIcon 
              className={`h-4 w-4 transition-transform ${isOpenState ? 'rotate-180' : ''}`} 
            />
          </button>
        )}

     </div>

      {/* Render dropdown with improved positioning */}
      {renderDropdown()}
    </div>
  );
};

Combobox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  multiSelect: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  renderOption: PropTypes.func,
  renderSelectedItem: PropTypes.func,
  maxHeight: PropTypes.number,
  allowCreation: PropTypes.bool,
  onCreateOption: PropTypes.func,
  allowRemoval: PropTypes.bool,
  onRemoveOption: PropTypes.func,
  displayType: PropTypes.oneOf(['default', 'avatar', 'badge']),
  showFocusRing: PropTypes.bool,
  withBorder: PropTypes.bool,
  containerRef: PropTypes.object,
  stopPropagation: PropTypes.bool,
  inputRef: PropTypes.object,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onEscape: PropTypes.func,
  topOffset: PropTypes.number,
  leftOffset: PropTypes.number,
  cellRect: PropTypes.object,
  showDropdownButton: PropTypes.bool,
  initialInputValue: PropTypes.string,
};

export default Combobox; 