import React from 'react';
import { RootStore, StoreContext } from '../stores/RootStore';

export const StoreProvider = ({ children }) => {
    const rootStore = React.useMemo(() => new RootStore(), []);

    return (
        <StoreContext.Provider value={rootStore}>
            {children}
        </StoreContext.Provider>
    );
};