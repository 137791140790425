import React from 'react';
import { Dialog } from '@headlessui/react';
import {
    ViewColumnsIcon,
    ListBulletIcon,
    CalendarIcon,
} from '@heroicons/react/24/outline';

const sheetTypes = [
    {
        id: 'kanban',
        name: 'Kanban Board',
        description: 'Organize tasks in columns',
        icon: ViewColumnsIcon,
    },
    {
        id: 'list',
        name: 'List View',
        description: 'Simple list of items',
        icon: ListBulletIcon,
    },
    {
        id: 'calendar',
        name: 'Calendar',
        description: 'Calendar view of items',
        icon: CalendarIcon,
    },
];

export const SheetTypeDialog = ({ isOpen, onClose, onSelect }) => {
    return (
        <Dialog 
            open={isOpen} 
            onClose={onClose}
            className="fixed inset-0 z-50 overflow-y-auto"
        >
            <div className="flex items-center justify-center min-h-screen px-4">
                <Dialog.Overlay className="fixed inset-0 bg-black/30" />
                
                <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-auto p-6">
                    <Dialog.Title className="text-lg font-semibold text-zinc-900">
                        Select Sheet Type
                    </Dialog.Title>
                    
                    <Dialog.Description className="mt-2 text-sm text-zinc-500">
                        Choose a type for this sheet. You can change this later.
                    </Dialog.Description>
                    
                    <div className="mt-6 space-y-2">
                        {sheetTypes.map(type => (
                            <button
                                key={type.id}
                                onClick={() => onSelect(type.id)}
                                className="w-full flex items-start p-3 text-left rounded-lg hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-zinc-500"
                            >
                                <type.icon className="h-6 w-6 text-zinc-400 mt-0.5" />
                                <div className="ml-3">
                                    <div className="font-medium text-zinc-900">{type.name}</div>
                                    <div className="text-sm text-zinc-500">{type.description}</div>
                                </div>
                            </button>
                        ))}
                    </div>

                    <div className="mt-6 flex justify-end">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-sm font-medium text-zinc-700 hover:text-zinc-900"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};