import React from 'react';
import { observer } from 'mobx-react-lite';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { PlusIcon } from '@heroicons/react/24/outline';
import { SheetBarSelector } from './SheetBarSelector';

export const SheetBar = observer(({ sheetVM, onAddSheet, maxSheets }) => {
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex === destinationIndex) return;

        sheetVM.moveSheet(sourceIndex, destinationIndex);
    };

    // Add logging to debug
    console.log('SheetBar render:', { 
        selectedIndex: sheetVM?.selectedSheetIndex,
        visibleSheets: sheetVM?.visibleSheets
    });

    // Early return if sheetVM or visibleSheets is not available
    if (!sheetVM?.visibleSheets) {
        return null;
    }

    return (
        <div className="flex items-center border-t border-zinc-200 px-4 py-2 bg-white">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="sheet-tabs" direction="horizontal">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="flex items-center overflow-x-auto"
                        >
                            {sheetVM.visibleSheets.map((sheet, index) => (
                                <SheetBarSelector
                                    key={sheet.id || index}
                                    sheet={sheet}
                                    index={index}
                                    selected={index === sheetVM.selectedSheetIndex}
                                    onSelect={sheetVM.selectSheet}
                                    onRemove={sheetVM.visibleSheets.length > 1 ? sheetVM.removeSheet : undefined}
                                    onRename={sheetVM.updateSheetName}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {sheetVM.visibleSheets.length < maxSheets && (
                <button
                    onClick={onAddSheet}
                    className="ml-2 p-1.5 rounded-lg hover:bg-zinc-100 
                               transition-colors flex items-center justify-center"
                >
                    <PlusIcon className="h-4 w-4 text-zinc-600" />
                </button>
            )}
        </div>
    );
});