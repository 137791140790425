import { makeObservable } from 'mobx';

export class BaseViewModel {
    constructor(sheetDataStore) {
        if (!sheetDataStore) {
            throw new Error('BaseViewModel requires sheetDataStore');
        }
        this.sheetDataStore = sheetDataStore;
        makeObservable(this, {
            sheetDataStore: false
        });
    }

    get data() {
        return this.sheetDataStore.currentData;
    }

    get columns() {
        return this.sheetDataStore.columns;
    }

    get isLoading() {
        return this.sheetDataStore.isLoading;
    }
}