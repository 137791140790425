import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import { ColumnTypes } from '../models/SheetModel';
import { SearchableViewModel } from './SearchableViewModel';

export class TableViewModel extends SearchableViewModel {
    isProcessing = false;
    selectedRows = new Set();

    constructor(sheetDataStore) {
        // Call super() first
        super();
        
        if (!sheetDataStore) {
            throw new Error('SheetDataStore is required for TableViewModel');
        }
        
        this.sheetDataStore = sheetDataStore;
        
        // Bind all methods that need 'this' context
        this.addColumn = this.addColumn.bind(this);
        this.updateColumnHeading = this.updateColumnHeading.bind(this);
        this.updateColumnType = this.updateColumnType.bind(this);
        this.deleteColumn = this.deleteColumn.bind(this);
        this.updateCell = this.updateCell.bind(this);
        this.addRow = this.addRow.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.moveColumnLeft = this.moveColumnLeft.bind(this);
        this.moveColumnRight = this.moveColumnRight.bind(this);
        this.addColumnLeft = this.addColumnLeft.bind(this);
        this.addColumnRight = this.addColumnRight.bind(this);
        this.updateColumnValidation = this.updateColumnValidation.bind(this);
        this.duplicateRows = this.duplicateRows.bind(this);
        this.moveRowsToPosition = this.moveRowsToPosition.bind(this);
        
        makeObservable(this, {
            sheetDataStore: false,
            isLoading: computed,
            error: computed,
            columns: computed,
            rows: computed,
            availableColumnTypes: computed,
            isReady: computed,
            isEmpty: computed,
            addColumn: action,
            updateColumnHeading: action,
            updateColumnType: action,
            deleteColumn: action,
            updateRow: action,
            deleteRows: action,
            updateCell: action,
            saveChanges: action,
            logState: action,
            addRow: action,
            moveRowUp: action,
            moveRowDown: action,
            addRowAbove: action,
            addRowBelow: action,
            duplicateRows: action,
            moveRowToPosition: action,
            moveRowsToPosition: action,
            moveColumnLeft: action,
            moveColumnRight: action,
            addColumnLeft: action,
            addColumnRight: action,
            moveColumnToPosition: action,
            updateColumnValidation: action,
            isProcessing: observable,
            indentRow: action,
            outdentRow: action,
            selectedRowsAreConsecutive: computed,
            selectedRows: observable,
            setSelectedRows: action,
            clearSelectedRows: action,
            decreaseColumnWidth: action,
            increaseColumnWidth: action,
            updateColumnWidth: action,
        });

        // Load initial state
        if (this.sheetDataStore.currentSheet === null && !this.sheetDataStore.isLoading) {
            console.log('TableViewModel: Triggering initial load');
            const selectedSheet = this.sheetDataStore.rootStore.sheetStore.selectedSheet;
            if (selectedSheet) {
                this.sheetDataStore.loadSheet(selectedSheet.name);
            }
        }
    }

    // Computed properties with null checks
    get isLoading() {
        const loading = this.sheetDataStore?.isLoading ?? false;
        console.log('TableViewModel: isLoading check:', loading);
        return loading;
    }

    get error() {
        return this.sheetDataStore?.error ?? null;
    }

    get columns() {
        const cols = this.sheetDataStore?.currentSheet?.columns ?? [];
        // Map over the columns and create new objects with adjusted width
        const adjustedCols = cols.map(col => ({
            ...col,
            // Convert Excel units to pixels for display and ensure minimum width of 150px
            width: Math.max(150, Math.round((col.width || this.sheetDataStore.constructor.defaultColumnWidth) * 
                            this.sheetDataStore.constructor.columnWidthMultiplier))
        }));
        console.log('TableViewModel: Getting columns:', adjustedCols);
        return adjustedCols;
    }

    get rows() {
        return Array.from(this.sheetDataStore?.currentSheet?.rows?.entries() ?? []);
    }

    get availableColumnTypes() {
        return Object.values(ColumnTypes);
    }

    get isReady() {
        const hasWorkbook = !!this.sheetDataStore?.service;
        const ready = hasWorkbook && !this.isLoading && this.sheetDataStore?.currentSheet !== null;
        
        console.log('TableViewModel: isReady check:', {
            hasWorkbook,
            isLoading: this.isLoading,
            hasCurrentSheet: this.sheetDataStore?.currentSheet !== null,
            isReady: ready
        });
        
        return ready;
    }

    get isEmpty() {
        return this.isReady && this.columns.length === 0 && this.rows.length === 0;
    }

    // Methods with store checks
    async addColumn() {
        if (!this.sheetDataStore) return;
        try {
            const newHeading = `Column ${this.columns.length + 1}`;
            const column = await this.sheetDataStore.addColumn(newHeading, ColumnTypes.SINGLE_LINE_TEXT);
            console.log('Column added:', column);
            return column;
        } catch (error) {
            console.error('Error adding column:', error);
            throw error;
        }
    }

    async updateColumnHeading(columnId, newHeading) {
        if (!this.sheetDataStore) return;
        await this.sheetDataStore.updateColumnHeading(columnId, newHeading);
    }

    async updateColumnType(columnId, newType) {
        if (!this.sheetDataStore) return;
        await this.sheetDataStore.updateColumnType(columnId, newType);
    }

    async deleteColumn(columnId) {
        if (!this.sheetDataStore) return;
        await this.sheetDataStore.deleteColumn(columnId);
    }

    async addRow(initialData = {}) {
        if (!this.sheetDataStore) return;
        try {
            const rowId = await this.sheetDataStore.addRow(initialData);
            console.log('Row added:', rowId);
            return rowId;
        } catch (error) {
            console.error('Error adding row:', error);
            throw error;
        }
    }

    async updateRow(rowId, updates) {
        if (!this.sheetDataStore) return;
        try {
            await this.sheetDataStore.updateRow(rowId, updates);
            console.log('Row updated:', rowId);
        } catch (error) {
            console.error('Error updating row:', error);
            throw error;
        }
    }

    async updateCell(rowId, columnId, value) {
        console.log('TableViewModel updateCell:', { rowId, columnId, value }); // Debug log
        if (!this.sheetDataStore) {
            console.warn('No sheetDataStore available'); // Debug log
            return;
        }

        // Special handling for date values
        const column = this.columns.find(col => col.id === columnId);
        if (column?.type?.id === 'DATE' || column?.type?.id === 'START_DATE' || column?.type?.id === 'END_DATE') {
            if (value && typeof value === 'object' && value.date) {
                // If it's a date-only value (from InputDate component)
                if (value.isDateOnly) {
                    // Parse the date components
                    const [year, month, day] = value.date.split('-').map(Number);
                    // Create a new UTC date at midnight
                    const utcDate = new Date(Date.UTC(year, month - 1, day));
                    // Format as ISO string and keep only the date part
                    value = {
                        ...value,
                        date: utcDate.toISOString().split('T')[0]
                    };
                }
            }
        }

        await this.sheetDataStore.updateCell(rowId, columnId, value);
    }

    async saveChanges() {
        if (!this.sheetDataStore) return;
        await this.sheetDataStore.saveChanges();
    }

    // Add debug method
    logState() {
        console.log('TableViewModel State:', {
            isLoading: this.isLoading,
            hasSheet: this.sheetDataStore?.currentSheet !== null,
            columnsLength: this.columns.length,
            rowsLength: this.rows.length,
            sheetDataStore: this.sheetDataStore
        });
    }

    async moveRowUp(rowId) {
        if (!this.sheetDataStore) return;
        try {
            const rowIds = Array.from(this.sheetDataStore.currentSheet.rows.keys());
            const currentIndex = rowIds.indexOf(rowId);
            if (currentIndex > 0) {
                await this.sheetDataStore.moveRowToPosition(rowId, currentIndex - 1);
                console.log('Row moved up:', rowId);
            }
        } catch (error) {
            console.error('Error moving row up:', error);
            throw error;
        }
    }

    async moveRowDown(rowId) {
        if (!this.sheetDataStore) return;
        try {
            const rowIds = Array.from(this.sheetDataStore.currentSheet.rows.keys());
            const currentIndex = rowIds.indexOf(rowId);
            if (currentIndex < rowIds.length - 1) {
                await this.sheetDataStore.moveRowToPosition(rowId, currentIndex + 1);
                console.log('Row moved down:', rowId);
            }
        } catch (error) {
            console.error('Error moving row down:', error);
            throw error;
        }
    }

    async addRowAbove(rowId, initialData = {}) {
        if (!this.sheetDataStore) return;
        try {
            const newRowId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: rowId,
                position: 'above'
            });
            console.log('Row added above:', newRowId);
            return newRowId;
        } catch (error) {
            console.error('Error adding row above:', error);
            throw error;
        }
    }

    async addRowBelow(rowId, initialData = {}) {
        if (!this.sheetDataStore) return;
        try {
            const newRowId = await this.sheetDataStore.addRow(initialData, {
                relativeTo: rowId,
                position: 'below'
            });
            console.log('Row added below:', newRowId);
            return newRowId;
        } catch (error) {
            console.error('Error adding row below:', error);
            throw error;
        }
    }

    async moveColumnLeft(columnId) {
        if (!this.sheetDataStore) return;
        try {
            const currentIndex = this.columns.findIndex(col => col.id === columnId);
            if (currentIndex > 0) {
                await this.sheetDataStore.moveColumnToPosition(columnId, currentIndex - 1);
                console.log('Column moved left:', columnId);
            }
        } catch (error) {
            console.error('Error moving column left:', error);
            throw error;
        }
    }

    async moveColumnRight(columnId) {
        if (!this.sheetDataStore) return;
        try {
            const currentIndex = this.columns.findIndex(col => col.id === columnId);
            if (currentIndex < this.columns.length - 1) {
                await this.sheetDataStore.moveColumnToPosition(columnId, currentIndex + 1);
                console.log('Column moved right:', columnId);
            }
        } catch (error) {
            console.error('Error moving column right:', error);
            throw error;
        }
    }

    async addColumnLeft(columnId) {
        if (!this.sheetDataStore) return;
        try {
            const newHeading = `Column ${this.columns.length + 1}`;
            const column = await this.sheetDataStore.addColumn(
                newHeading, 
                ColumnTypes.SINGLE_LINE_TEXT,
                { relativeTo: columnId, position: 'left' }
            );
            console.log('Column added left:', column);
            return column;
        } catch (error) {
            console.error('Error adding column left:', error);
            throw error;
        }
    }

    async addColumnRight(columnId) {
        if (!this.sheetDataStore) return;
        try {
            const newHeading = `Column ${this.columns.length + 1}`;
            const column = await this.sheetDataStore.addColumn(
                newHeading, 
                ColumnTypes.SINGLE_LINE_TEXT,
                { relativeTo: columnId, position: 'right' }
            );
            console.log('Column added right:', column);
            return column;
        } catch (error) {
            console.error('Error adding column right:', error);
            throw error;
        }
    }

    // New method for future drag-and-drop support
    async moveColumnToPosition(columnId, targetIndex) {
        if (!this.sheetDataStore) return;
        try {
            await this.sheetDataStore.moveColumnToPosition(columnId, targetIndex);
            console.log('Column moved to position:', { columnId, targetIndex });
        } catch (error) {
            console.error('Error moving column to position:', error);
            throw error;
        }
    }

    // New method for future drag-and-drop support
    async moveRowToPosition(rowId, targetIndex) {
        if (!this.sheetDataStore) return;
        try {
            await this.sheetDataStore.moveRowToPosition(rowId, targetIndex);
            console.log('Row moved to position:', { rowId, targetIndex });
        } catch (error) {
            console.error('Error moving row to position:', error);
            throw error;
        }
    }

    // Implement required methods from SearchableViewModel
    get searchableData() {
        return this.rows;
    }

    filterDataBySearch(data) {
        if (!this.searchTerm) return data;
        
        return data.filter(([rowId, rowData]) => {
            return Object.entries(rowData).some(([columnHeading, cellData]) => {
                return this._matchesSearch(cellData.value, this.searchTerm);
            });
        });
    }

    async updateColumnValidation(columnId, options) {
        console.log('TableViewModel: Updating column validation:', { columnId, options });
        
        if (!this.sheetDataStore) {
            console.error('No sheetDataStore available');
            return;
        }

        try {
            await this.sheetDataStore.updateColumnValidationOptions(columnId, options);
            console.log('Column validation updated successfully');
        } catch (error) {
            console.error('Error updating column validation:', error);
            throw error;
        }
    }

    async deleteRows(rowIds) {
        if (!this.sheetDataStore) return;
        try {
            runInAction(() => {
                this.isProcessing = true;
            });
            
            await this.sheetDataStore.deleteRows(rowIds);
            console.log('Rows deleted:', rowIds);
        } catch (error) {
            console.error('Error deleting rows:', error);
            throw error;
        } finally {
            runInAction(() => {
                this.isProcessing = false;
            });
        }
    }

    async duplicateRows(rowIds) {
        if (!this.sheetDataStore) return;
        
        // Convert to array if single rowId
        const rowIdsArray = Array.isArray(rowIds) ? rowIds : [rowIds];
        
        // Check row limit
        if (rowIdsArray.length > 250) {
            console.warn('Cannot duplicate more than 250 rows at once');
            return;
        }

        try {
            runInAction(() => {
                this.isProcessing = true;
            });
            
            await this.sheetDataStore.duplicateRows(rowIds);
            console.log('Rows duplicated:', rowIds);
        } catch (error) {
            console.error('Error duplicating rows:', error);
            throw error;
        } finally {
            runInAction(() => {
                this.isProcessing = false;
            });
        }
    }

    // Add new method to handle multiple row moves
    async moveRowsToPosition(rowIds, targetIndex) {
        if (!this.sheetDataStore) return;
        try {
            // Batch updates
            runInAction(() => {
                this.isProcessing = true;
            });
            
            // Consider implementing batch updates in your store
            await this.sheetDataStore.moveRowsToPosition(rowIds, targetIndex);
            
            runInAction(() => {
                this.isProcessing = false;
            });
        } catch (error) {
            console.error('Error moving rows to position:', error);
            throw error;
        }
    }

    getActualRowNumber(rowId) {
        // Get the index from the full dataset (not filtered)
        const index = this.rows.findIndex(([id]) => id === rowId);
        // Add 2 because: +1 for zero-based index and +1 for header row
        return index + 2;
    }

    async indentRow(rowId) {
        if (!this.sheetDataStore) return;
        try {
            const rowData = this.sheetDataStore.currentSheet.rows.get(rowId);
            const currentLevel = rowData._outlineLevel || 0;
            await this.sheetDataStore.setRowOutlineLevel(rowId, currentLevel + 1);
            console.log('Row indented:', rowId);
        } catch (error) {
            console.error('Error indenting row:', error);
            throw error;
        }
    }

    async outdentRow(rowId) {
        if (!this.sheetDataStore) return;
        try {
            const rowData = this.sheetDataStore.currentSheet.rows.get(rowId);
            const currentLevel = rowData._outlineLevel || 0;
            await this.sheetDataStore.setRowOutlineLevel(rowId, currentLevel - 1);
            console.log('Row outdented:', rowId);
        } catch (error) {
            console.error('Error outdenting row:', error);
            throw error;
        }
    }

    async batchIndentRows(rowIds) {
        if (!this.sheetDataStore) return;
        try {
            await this.sheetDataStore.batchSetRowOutlineLevels(rowIds, 'indent');
            console.log('Rows indented:', rowIds);
        } catch (error) {
            console.error('Error indenting rows:', error);
            throw error;
        }
    }

    async batchOutdentRows(rowIds) {
        if (!this.sheetDataStore) return;
        try {
            await this.sheetDataStore.batchSetRowOutlineLevels(rowIds, 'outdent');
            console.log('Rows outdented:', rowIds);
        } catch (error) {
            console.error('Error outdenting rows:', error);
            throw error;
        }
    }

    setSelectedRows(rows) {
        this.selectedRows = new Set(rows);
    }

    clearSelectedRows() {
        this.selectedRows.clear();
    }

    get selectedRowsAreConsecutive() {
        if (!this.sheetDataStore?.currentSheet || this.selectedRows.size === 0) return false;
        return this.sheetDataStore._areRowsConsecutive(Array.from(this.selectedRows));
    }

    async updateColumnWidth(columnId, excelUnits) {
        if (!this.sheetDataStore) return;
        
        // Ensure width is a valid number
        if (isNaN(excelUnits)) {
            console.warn('Invalid width value:', excelUnits);
            return;
        }
        
        // Constrain to min/max Excel units
        const constrainedExcelUnits = Math.max(
            this.sheetDataStore.constructor.minColumnWidth,
            Math.min(this.sheetDataStore.constructor.maxColumnWidth, excelUnits)
        );
        
        try {
            // Update the store with Excel units
            await this.sheetDataStore.updateColumnWidth(columnId, constrainedExcelUnits);
            
            // Force a reaction to update the UI
            runInAction(() => {
                const column = this.sheetDataStore.currentSheet.columns.find(col => col.id === columnId);
                if (column) {
                    column.width = constrainedExcelUnits;
                }
            });

            console.log('Column width updated:', { 
                columnId, 
                excelUnits: constrainedExcelUnits,
                pixelWidth: constrainedExcelUnits * this.sheetDataStore.constructor.columnWidthMultiplier 
            });
        } catch (error) {
            console.error('Error updating column width:', error);
            throw error;
        }
    }

    async decreaseColumnWidth(columnId) {
        const column = this.columns.find(col => col.id === columnId);
        if (column && !isNaN(column.width)) {
            // Get current Excel units and decrease by 1
            const currentExcelUnits = column.width / this.sheetDataStore.constructor.columnWidthMultiplier;
            await this.updateColumnWidth(columnId, currentExcelUnits - 2);
        }
    }

    async increaseColumnWidth(columnId) {
        const column = this.columns.find(col => col.id === columnId);
        if (column && !isNaN(column.width)) {
            // Get current Excel units and increase by 1
            const currentExcelUnits = column.width / this.sheetDataStore.constructor.columnWidthMultiplier;
            await this.updateColumnWidth(columnId, currentExcelUnits + 2);
        }
    }
}