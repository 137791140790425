import React from 'react';
import { StoreProvider } from './providers/StoreProvider';
import { WorkbookView } from './views/Workbook/WorkbookView';
import { WorkbookViewModel } from './viewModels/WorkbookViewModel';
import { observer } from 'mobx-react-lite';
import { useStores } from './stores/RootStore';
import { DragDropContext } from 'react-beautiful-dnd';

const AppContent = observer(() => {
    const stores = useStores();
    const viewModel = React.useMemo(() => new WorkbookViewModel(stores), [stores]);

    const onDragEnd = (result) => {
        if (!result.destination) return;
        stores.dragDropStore.handleDragEnd(result);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <WorkbookView viewModel={viewModel} />
        </DragDropContext>
    );
});

const App = () => {
    return (
        <StoreProvider>
            <AppContent />
        </StoreProvider>
    );
};

export default App;