import React, { useRef, useEffect } from 'react';

const InputText = ({ value, onChange, onBlur, onEnter, onTab }) => {
  const editorRef = useRef(null);
  const updateTimeoutRef = useRef(null);
  const isInternalChange = useRef(false);
  const lastInternalHtml = useRef('');

  const submitChanges = () => {
    if (editorRef.current) {
      const text = editorRef.current.textContent || '';
      lastInternalHtml.current = text;
      onChange(text);
    }
  };

  const handleKeyDown = (e) => {
    // Prevent TableView from handling these keys while editing
    if (e.key === 'Enter' || 
        e.key === 'ArrowUp' || 
        e.key === 'ArrowDown' || 
        e.key === 'ArrowLeft' || 
        e.key === 'ArrowRight' || 
        e.key === 'Tab') {
      e.stopPropagation();
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      submitChanges();
      e.target.blur();
      onEnter?.();
      return;
    }

    if (e.key === 'Tab') {
      e.preventDefault();
      e.stopPropagation();
      submitChanges();
      onTab?.(e);
      return;
    }

    // Only prevent specific formatting shortcuts
    if ((e.ctrlKey || e.metaKey) && 
        (e.key === 'b' || 
         e.key === 'i' || 
         e.key === 'u')) {
      e.preventDefault();
    }
  };

  const handleInput = () => {
    if (editorRef.current) {
      // Store current content
      lastInternalHtml.current = editorRef.current.textContent || '';
    }
  };

  const handleBlur = () => {
    if (editorRef.current) {
      isInternalChange.current = true;
      submitChanges();
    }
    onBlur?.();
  };

  useEffect(() => {
    if (editorRef.current && !isInternalChange.current) {
      let textContent = '';
      
      try {
        if (typeof value === 'string') {
          textContent = value;
        } else if (Array.isArray(value)) {
          textContent = value.map(part => {
            if (typeof part === 'object' && part !== null) {
              return part.text || '';
            }
            return String(part || '');
          }).join('');
        } else if (typeof value === 'object' && value !== null) {
          if (value.richText) {
            textContent = value.richText.map(part => part?.text || '').join('');
          } else if (value.value !== undefined) {
            textContent = String(value.value);
          } else if (value.text !== undefined) {
            textContent = String(value.text);
          }
        }

        if (!textContent && value !== undefined && value !== null) {
          textContent = String(value);
        }
      } catch (error) {
        console.error('Error parsing value in InputText:', error);
        textContent = '';
      }

      if (textContent !== lastInternalHtml.current) {
        editorRef.current.textContent = textContent;
        lastInternalHtml.current = textContent;
      }
    }
    isInternalChange.current = false;
  }, [value]);

  useEffect(() => {
    return () => {
      if (updateTimeoutRef.current) {
        clearTimeout(updateTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
      
      // Add support for initialKeystroke similar to InputEmail and InputURL
      if (window.initialKeystroke) {
        editorRef.current.textContent = window.initialKeystroke;
        window.initialKeystroke = null;
        // Update internal state
        lastInternalHtml.current = editorRef.current.textContent || '';
      }
      
      const range = document.createRange();
      const selection = window.getSelection();
      
      range.selectNodeContents(editorRef.current);
      range.collapse(false);
      
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, []);

  return (
    <div
      ref={editorRef}
      contentEditable
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      onBlur={handleBlur}
      suppressContentEditableWarning
      className="w-full p-0 outline-none resize-none whitespace-nowrap overflow-hidden"
    />
  );
};

export default InputText;
