import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { SheetView } from '../Sheet/SheetView';
import { SheetBar } from '../Sheet/SheetBar';
import { SheetTypeDialog } from '../Sheet/SheetTypeDialog';
import { WorkbookNav } from './WorkbookNav';
import { WorkbookSelector } from './WorkbookSelector';
import { useStores } from '../../stores/RootStore';
import { SheetViewModel } from '../../viewModels/SheetViewModel';

export const WorkbookView = observer(({ viewModel }) => {
    const { sheetStore } = useStores();
    
    const sheetVM = useMemo(() => {
        if (!sheetStore) return null;
        return new SheetViewModel({ sheetStore });
    }, [sheetStore]);

    const currentWorkbook = viewModel.currentWorkbook;
    const showSheetSelector = currentWorkbook && !currentWorkbook.isCSV;

    useEffect(() => {
        const handleKeyboardShortcuts = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 's') {
                event.preventDefault();
                viewModel.handleSave();
            }
        };

        document.addEventListener('keydown', handleKeyboardShortcuts);
        return () => document.removeEventListener('keydown', handleKeyboardShortcuts);
    }, [viewModel]);

    if (!currentWorkbook) {
        return <WorkbookSelector viewModel={viewModel} />;
    }

    if (!sheetVM) {
        return null;
    }

    return (
        <div className="flex flex-col h-screen">
            <WorkbookNav 
                workbookName={currentWorkbook.properties.title}
                isSaved={viewModel.isSaved}
                isSaving={viewModel.isSaving}
                onSave={viewModel.handleSave}
                onSaveAsCSV={viewModel.handleSaveAsCSV}
                view={viewModel.view}
                setView={viewModel.setView}
                hasEditPermission={viewModel.hasEditPermission}
                onNameChange={viewModel.updateWorkbookName}
                isCSV={currentWorkbook.isCSV}
                isNewWorkbook={currentWorkbook.isNewWorkbook}
            />

            <div className="flex-grow flex flex-col">
                {showSheetSelector && (
                    <SheetBar 
                        sheetVM={sheetVM}
                        onAddSheet={sheetVM.addSheet}
                        maxSheets={sheetVM.MAX_SHEETS}
                    />
                )}

                <SheetView
                    sheet={sheetVM.currentSheet}
                    view={viewModel.view}
                    onUpdate={(update) => {
                        if (update.type === 'select_type') {
                            viewModel.openSheetTypeDialog();
                        }
                    }}
                />
            </div>

            {showSheetSelector && (
                <SheetTypeDialog
                    isOpen={viewModel.isSheetTypeDialogOpen}
                    onClose={() => viewModel.closeSheetTypeDialog()}
                    onSelect={(type) => {
                        sheetVM.handleSheetTypeSelection(type);
                        viewModel.closeSheetTypeDialog();
                    }}
                />
            )}
        </div>
    );
});