import React, { useState, useRef, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
    ViewColumnsIcon,
    ListBulletIcon,
    CalendarIcon,
    QuestionMarkCircleIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';

const sheetTypeIcons = {
    kanban: ViewColumnsIcon,
    list: ListBulletIcon,
    calendar: CalendarIcon,
    unknown: QuestionMarkCircleIcon
};

export const SheetBarSelector = observer(({ sheet, index, selected, onSelect, onRemove, onRename }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(sheet.name || `Sheet ${index + 1}`);
    const inputRef = useRef(null);
    
    const Icon = sheetTypeIcons[sheet.sheetType] || sheetTypeIcons.unknown;
    
    const handleSelect = React.useCallback(() => {
        onSelect(index);
    }, [onSelect, index]);

    const handleRemove = React.useCallback((e) => {
        e.stopPropagation();
        onRemove(index);
    }, [onRemove, index]);

    const handleDoubleClick = (e) => {
        e.stopPropagation();
        setIsEditing(true);
        setEditValue(sheet.name || `Sheet ${index + 1}`);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (editValue.trim() && editValue !== sheet.name) {
            onRename(index, editValue);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            inputRef.current?.blur();
        } else if (e.key === 'Escape') {
            setIsEditing(false);
            setEditValue(sheet.name || `Sheet ${index + 1}`);
        }
    };

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
            inputRef.current?.select();
        }
    }, [isEditing]);
    
    return (
        <Draggable 
            draggableId={sheet.id?.toString() || index.toString()} 
            index={index}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`
                        inline-flex items-center rounded-xl px-3 py-1.5 
                        text-sm font-medium flex-shrink-0 mr-1 
                        transition-colors cursor-pointer group
                        ${selected 
                            ? 'bg-zinc-800 text-white' 
                            : 'text-zinc-600 hover:text-zinc-900 hover:bg-zinc-100'}
                        ${snapshot.isDragging ? 'ring-2 ring-zinc-500 ring-opacity-50' : ''}
                    `}
                >
                    <div onClick={handleSelect} className="flex items-center">
                        <Icon className="h-4 w-4 mr-1.5" />
                        {isEditing ? (
                            <input
                                ref={inputRef}
                                type="text"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                className={`
                                    bg-transparent border-none outline-none p-0
                                    ${selected ? 'text-white' : 'text-zinc-900'}
                                    focus:ring-0 min-w-[60px]
                                `}
                                onClick={(e) => e.stopPropagation()}
                            />
                        ) : (
                            <span onDoubleClick={handleDoubleClick}>
                                {sheet.name || `Sheet ${index + 1}`}
                            </span>
                        )}
                    </div>
                    {onRemove && (
                        <button
                            onClick={handleRemove}
                            className={`
                                ml-2 p-0.5 rounded-full opacity-0 group-hover:opacity-100
                                hover:bg-zinc-200 transition-opacity
                                ${selected ? 'hover:bg-zinc-700' : 'hover:bg-zinc-200'}
                            `}
                        >
                            <XMarkIcon className={`h-3 w-3 ${selected ? 'text-white' : ''}`} />
                        </button>
                    )}
                </div>
            )}
        </Draggable>
    );
});