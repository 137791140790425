import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';
import { SearchBoxComponent } from '../Components/Search/SearchBoxComponent';
import { SpinnerComponent } from '../Components/General/SpinnerComponent';
import _ from 'lodash';
import { useRef } from 'react';
import {
    ArrowUpIcon,
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    PlusIcon,
    DocumentDuplicateIcon,
    TrashIcon
} from '@heroicons/react/20/solid';
import SelectDropdown from '../Components/Dropdown/SelectDropdown';
import { InputCheckbox } from '../Components/Input/InputCheckbox';

const styles = `
    .drag-indicator {
        border-top: 2px solid #3b82f6;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
    }

    .dragging {
        opacity: 0.7;
        background: #eff6ff;
    }

    tr[data-is-ghost="true"] {
        opacity: 0.5;
        background: #eff6ff;
    }

    tr[data-outline-level] > td:first-child {
        position: relative;
    }

    tr[data-outline-level] > td:first-child::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: var(--outline-indent);
        background: rgba(59, 130, 246, 0.1);
    }

    .cursor-col-resize {
        cursor: col-resize;
    }

    .cursor-col-resize:hover {
        opacity: 1;
        width: 4px;
        margin-right: -2px;
        background-color: rgba(59, 130, 246, 0.5);
    }

    .table-container {
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        overflow: hidden;
    }

    table {
        table-layout: fixed;
        width: max-content;
        min-width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
    }

    th, td {
        position: relative;
        
        white-space: nowrap;
        text-overflow: ellipsis;
        width: var(--column-width) !important;
        max-width: var(--column-width) !important;
        border: 1px solid #e5e7eb;
    }

    th:not(:first-child), td:not(:first-child) {
        border-left: none;
    }
    
    tr:not(:first-child) th, tr:not(:first-child) td {
        border-top: none;
    }

    th {
        border-top: none;
    }
    
    tr:last-child th, tr:last-child td {
        border-bottom: none;
    }
    
    tr th:first-child, tr td:first-child {
        border-left: none;
    }
    
    tr th:last-child, tr td:last-child {
        border-right: none;
    }

    th:nth-child(1),
    td:nth-child(1) {
        width: 1px !important;
        min-width: 1px !important;
        max-width: max-content !important;
        white-space: nowrap;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 1px !important;
        min-width: 1px !important;
        max-width: max-content !important;
        white-space: nowrap;
    }
`;

const getExcelColumnName = (num) => {
    let columnName = '';
    while (num > 0) {
        let remainder = (num - 1) % 26;
        columnName = String.fromCharCode(65 + remainder) + columnName;
        num = Math.floor((num - 1) / 26);
    }
    return columnName || 'A';
};

export const TableView = observer(({ viewModel }) => {
    const [isInitializing, setIsInitializing] = React.useState(false);
    const [initializationFailed, setInitializationFailed] = React.useState(false);
    const [lastSelectedRow, setLastSelectedRow] = React.useState(null);
    const [selectedCell, setSelectedCell] = React.useState(null);
    const [indicatorStyle, setIndicatorStyle] = React.useState({ display: 'none' });
    
    const tableContainerRef = React.useRef(null);
    const lastHighlightedRow = React.useRef(null);
    const dragIndicatorRef = React.useRef(null);

    React.useEffect(() => {
        const styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
        return () => styleSheet.remove();
    }, []);

    React.useEffect(() => {
        const initializeEmptyTable = async () => {
            if (!viewModel.isReady) {
                return;
            }

            await new Promise(resolve => setTimeout(resolve, 100));

            if (viewModel.isEmpty) {
                setIsInitializing(true);
                setInitializationFailed(false);
                
                try {
                    console.log('Initializing empty table with 3x3 grid');
                    
                    for (let i = 1; i <= 3; i++) {
                        await viewModel.addColumn();
                    }
                    
                    for (let i = 1; i <= 3; i++) {
                        await viewModel.addRow();
                    }
                } catch (error) {
                    console.error('Error initializing empty table:', error);
                    setInitializationFailed(true);
                } finally {
                    setIsInitializing(false);
                }
            }
        };

        initializeEmptyTable();
    }, [viewModel]);

    const scrollCellIntoView = React.useCallback((rowIndex, columnIndex) => {
        const cell = document.querySelector(`[data-row="${rowIndex}"][data-col="${columnIndex}"]`);
        if (cell) {
            cell.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
    }, []);

    const handleKeyDown = React.useCallback((e) => {
        if (!selectedCell) return;

        const { rowIndex, columnIndex } = selectedCell;
        const totalColumns = viewModel.columns.length;
        const totalRows = viewModel.filteredData.length;
        let newRow = rowIndex;
        let newCol = columnIndex;

        const isCommandOrControl = e.metaKey || e.ctrlKey;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                if (isCommandOrControl) {
                    newRow = 0; // Jump to first row
                } else if (rowIndex > 0) {
                    newRow = rowIndex - 1;
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (isCommandOrControl) {
                    newRow = totalRows - 1; // Jump to last row
                } else if (rowIndex < totalRows - 1) {
                    newRow = rowIndex + 1;
                }
                break;
            case 'ArrowLeft':
                e.preventDefault();
                if (isCommandOrControl) {
                    newCol = 0; // Jump to first column
                } else if (columnIndex > 0) {
                    newCol = columnIndex - 1;
                }
                break;
            case 'ArrowRight':
                e.preventDefault();
                if (isCommandOrControl) {
                    newCol = totalColumns - 1; // Jump to last column
                } else if (columnIndex < totalColumns - 1) {
                    newCol = columnIndex + 1;
                }
                break;
            case 'Tab':
                e.preventDefault();
                if (columnIndex < totalColumns - 1) {
                    newCol = columnIndex + 1;
                } else if (rowIndex < totalRows - 1) {
                    newRow = rowIndex + 1;
                    newCol = 0;
                }
                break;
            case 'Enter':
                e.preventDefault();
                if (rowIndex < totalRows - 1) {
                    newRow = rowIndex + 1;
                }
                break;
            default:
                break; // Handle any other keys by doing nothing
        }

        if (newRow !== rowIndex || newCol !== columnIndex) {
            setSelectedCell({ rowIndex: newRow, columnIndex: newCol });
            scrollCellIntoView(newRow, newCol);
        }
    }, [selectedCell, viewModel.columns.length, viewModel.filteredData.length, scrollCellIntoView]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    React.useEffect(() => {
        const container = tableContainerRef.current;
        if (!container) return;

        const handleContainerDragOver = (e) => {
            // Only handle drag over in the bottom padding area
            const rect = container.getBoundingClientRect();
            const bottomPadding = 40; // Height of bottom padding area
            if (e.clientY > rect.bottom - bottomPadding) {
                e.preventDefault();
                // Clear other indicators
                document.querySelectorAll('.dragging-over-row, .dragging-over-row-bottom').forEach(el => {
                    el.classList.remove('dragging-over-row', 'dragging-over-row-bottom');
                });
                // Add indicator to last row
                const lastRow = container.querySelector('tbody tr:last-child');
                if (lastRow) {
                    lastRow.classList.add('dragging-over-row-bottom');
                }
            }
        };

        container.addEventListener('dragover', handleContainerDragOver);
        return () => container.removeEventListener('dragover', handleContainerDragOver);
    }, []);

    const toggleRowSelection = (rowId, isShiftKey) => {
        if (isShiftKey && lastSelectedRow !== null) {
            // Find indices of last selected and current row
            const currentIndex = viewModel.filteredData.findIndex(([id]) => id === rowId);
            const lastIndex = viewModel.filteredData.findIndex(([id]) => id === lastSelectedRow);
            
            if (currentIndex !== -1 && lastIndex !== -1) {
                // Determine range start and end
                const start = Math.min(currentIndex, lastIndex);
                const end = Math.max(currentIndex, lastIndex);
                
                // Select all rows in range
                const newSelection = new Set(viewModel.selectedRows);
                for (let i = start; i <= end; i++) {
                    const [id] = viewModel.filteredData[i];
                    newSelection.add(id);
                }
                viewModel.setSelectedRows(newSelection);
            }
        } else {
            // Normal toggle behavior
            const newSelection = new Set(viewModel.selectedRows);
            if (newSelection.has(rowId)) {
                newSelection.delete(rowId);
            } else {
                newSelection.add(rowId);
            }
            viewModel.setSelectedRows(newSelection);
            setLastSelectedRow(rowId);
        }
    };

    const clearSelection = () => {
        viewModel.clearSelectedRows();
    };

    const deleteSelected = async () => {
        await viewModel.deleteRows(Array.from(viewModel.selectedRows));
        clearSelection();
    };

    const toggleAllRows = () => {
        if (viewModel.selectedRows.size === viewModel.filteredData.length) {
            viewModel.clearSelectedRows();
        } else {
            viewModel.setSelectedRows(viewModel.filteredData.map(([rowId]) => rowId));
        }
    };

    const duplicateSelected = async () => {
        await viewModel.duplicateRows(Array.from(viewModel.selectedRows));
        clearSelection();
    };

    const canDuplicateSelection = viewModel.selectedRows.size > 0 && viewModel.selectedRows.size <= 250;

    const handleDragStart = (e, index, rowId) => {
        // Only allow drag if the row is selected or no rows are selected
        if (!viewModel.selectedRows.has(rowId) && viewModel.selectedRows.size > 0) {
            e.preventDefault();
            return;
        }
        
        e.target.classList.add('dragging');
        e.dataTransfer.setData('text/plain', JSON.stringify({
            sourceIndex: index,
            selectedRows: Array.from(viewModel.selectedRows),
            isSingleRow: viewModel.selectedRows.size === 0
        }));
    };

    const handleDragOver = React.useCallback((e) => {
        e.preventDefault();
        const row = e.target.closest('tr');
        if (!row) return;

        // Remove existing indicators
        const existingIndicator = document.querySelector('.drag-indicator');
        if (existingIndicator) {
            existingIndicator.remove();
        }

        // Create and position new indicator
        const indicator = document.createElement('div');
        indicator.className = 'drag-indicator';
        const rect = row.getBoundingClientRect();
        const mouseY = e.clientY;
        const threshold = rect.top + (rect.height / 2);
        
        indicator.style.top = `${mouseY < threshold ? rect.top : rect.bottom}px`;
        indicator.style.width = `${rect.width}px`;
        indicator.style.left = `${rect.left}px`;
        
        document.body.appendChild(indicator);
    }, []);

    const handleDragEnd = (e) => {
        e.target.classList.remove('dragging');
        // Remove any remaining indicators
        const indicator = document.querySelector('.drag-indicator');
        if (indicator) {
            indicator.remove();
        }
    };

    const handleDrop = async (e, targetIndex) => {
        e.preventDefault();
        
        // Remove the indicator
        const indicator = document.querySelector('.drag-indicator');
        if (indicator) {
            indicator.remove();
        }

        try {
            const dropData = JSON.parse(e.dataTransfer.getData('text/plain'));
            const { sourceIndex, selectedRows: draggedRows, isSingleRow } = dropData;

            // Calculate drop position
            const row = e.target.closest('tr');
            const rect = row.getBoundingClientRect();
            const mouseY = e.clientY;
            const threshold = rect.top + (rect.height / 2);
            let adjustedTargetIndex = targetIndex;
            
            if (mouseY >= threshold) {
                adjustedTargetIndex++;
            }

            // Move the rows
            if (isSingleRow) {
                const sourceId = viewModel.filteredData[sourceIndex][0];
                if (sourceIndex < adjustedTargetIndex) {
                    adjustedTargetIndex--;
                }
                await viewModel.moveRowToPosition(sourceId, adjustedTargetIndex);
            } else {
                await viewModel.moveRowsToPosition(draggedRows, adjustedTargetIndex);
            }
        } catch (error) {
            console.error('Drop error:', error);
        }
    };

    const handleColumnDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
        e.target.classList.add('dragging');
    };

    const handleColumnDragEnd = (e) => {
        e.target.classList.remove('dragging');
        document.querySelectorAll('.dragging-over-column').forEach(el => 
            el.classList.remove('dragging-over-column')
        );
    };

    const handleColumnDragOver = (e) => {
        e.preventDefault();
        const th = e.target.closest('th');
        if (th) {
            document.querySelectorAll('.dragging-over-column').forEach(el => 
                el.classList.remove('dragging-over-column')
            );
            th.classList.add('dragging-over-column');
        }
    };

    const handleColumnDrop = (e, targetIndex) => {
        e.preventDefault();
        const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
        if (sourceIndex === targetIndex) return;

        const sourceId = viewModel.columns[sourceIndex].id;
        viewModel.moveColumnToPosition(sourceId, targetIndex);
    };

    const handleColumnResize = (columnId, newWidth) => {
        const column = viewModel.columns.find(col => col.id === columnId);
        if (column) {
            column.setWidth(newWidth);
            viewModel.saveChanges(); // Trigger save to persist changes
        }
    };

    if (viewModel.isLoading) {
        return (
            <div className="p-4 flex items-center justify-center">
                <span className="text-gray-500">Loading...</span>
            </div>
        );
    }

    if (viewModel.error) {
        return (
            <div className="p-4 flex items-center justify-center">
                <span className="text-red-500">Error: {viewModel.error.message}</span>
            </div>
        );
    }

    if (!viewModel.isReady) {
        return (
            <div className="p-4 flex items-center justify-center">
                <span className="text-gray-500">No sheet selected</span>
            </div>
        );
    }

    if (isInitializing) {
        return (
            <div className="p-4 flex items-center justify-center">
                <span className="text-gray-500">Creating default table structure...</span>
            </div>
        );
    }

    if (viewModel.columns.length === 0) {
        return (
            <div className="text-center p-8  rounded-lg border-2 border-dashed border-gray-200">
                <h3 className="text-lg font-medium text-gray-700 mb-2">
                    {initializationFailed ? 'Unable to Create Default Table' : 'Empty Sheet'}
                </h3>
                <p className="text-gray-500 mb-4">
                    {initializationFailed
                        ? 'The automatic table creation failed. You can create the table structure manually.'
                        : 'Start by adding a column to create your table structure.'}
                </p>
                <button
                    onClick={() => viewModel.addColumn()}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 inline-flex items-center gap-2"
                >
                    <span>Add First Column</span>
                </button>
            </div>
        );
    }

    return (
        <div className="w-full">
            <div className="">
                <div className="flex gap-4 items-center p-4">
                    <SearchBoxComponent
                        value={viewModel.searchTerm}
                        onChange={(term) => viewModel.setSearchTerm(term)}
                        onClear={() => viewModel.clearSearch()}
                        className="w-64"
                    />
                    <button
                        onClick={() => viewModel.addColumn()}
                        disabled={!!viewModel.searchTerm}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Add Column
                    </button>
                    <button
                        onClick={() => viewModel.addRow()}
                        disabled={!!viewModel.searchTerm}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Add Row
                    </button>
                </div>
                
                {viewModel.selectedRows.size > 0 && (
                    <div className="flex gap-4 items-center p-2  rounded">
                        <span className="text-gray-600">
                            {viewModel.selectedRows.size} row{viewModel.selectedRows.size > 1 ? 's' : ''} selected
                        </span>
                        {viewModel.selectedRowsAreConsecutive && (
                            <>
                                <button
                                    onClick={() => viewModel.batchOutdentRows(Array.from(viewModel.selectedRows))}
                                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Outdent Selection ←
                                </button>
                                <button
                                    onClick={() => viewModel.batchIndentRows(Array.from(viewModel.selectedRows))}
                                    className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Indent Selection →
                                </button>
                            </>
                        )}
                        <button
                            onClick={duplicateSelected}
                            disabled={!canDuplicateSelection || viewModel.isProcessing}
                            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                        >
                            {viewModel.isProcessing ? (
                                <span className="flex items-center gap-2">
                                    <SpinnerComponent size="sm" />
                                    Processing...
                                </span>
                            ) : (
                                'Duplicate Selection'
                            )}
                        </button>
                        <button
                            onClick={deleteSelected}
                            disabled={viewModel.isProcessing}
                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50"
                        >
                            {viewModel.isProcessing ? (
                                <span className="flex items-center gap-2">
                                    <SpinnerComponent size="sm" />
                                    Processing...
                                </span>
                            ) : (
                                'Delete Selection'
                            )}
                        </button>
                        {viewModel.selectedRows.size > 250 && (
                            <span className="text-red-500 text-sm">
                                Cannot duplicate more than 250 rows at once
                            </span>
                        )}
                        <button
                            onClick={clearSelection}
                            className="px-3 py-1 0 text-white rounded hover:bg-gray-600"
                        >
                            Clear Selection
                        </button>
                    </div>
                )}
            </div>

            <div 
                ref={tableContainerRef} 
                className="overflow-x-auto pb-10 w-full"
                style={{ 
                    isolation: 'isolate',
                    maxWidth: '100%'
                }}
            >
                <div className="inline-block mx-4 table-container">
                    <table className="table-fixed">
                        <thead>
                            <tr>
                                <th className="p-0 ">
                                    {/* <div className="flex items-center gap-2">
                                        <input
                                            type="checkbox"
                                            checked={viewModel.selectedRows.size === viewModel.filteredData.length && viewModel.filteredData.length > 0}
                                            onChange={toggleAllRows}
                                            className="w-4 h-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                        />
                                    </div> */}
                                </th>
                                <th className="p-0 ">
                                    <div className="text-center text-gray-500 text-xs">
                                        
                                    </div>
                                </th>
                                {viewModel.columns.map((_, index) => (
                                    <th 
                                        key={`col-letter-${index}`} 
                                        className="p-1 text-center font-normal text-[0.6rem] text-gray-400 text-xs relative group"
                                        style={{ 
                                            width: `${viewModel.columns[index].width}px`,
                                        }}
                                        draggable={!viewModel.searchTerm}
                                        onDragStart={(e) => handleColumnDragStart(e, index)}
                                        onDragEnd={handleColumnDragEnd}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}
                                    >
                                        <span className="block group-hover:hidden">
                                            {getExcelColumnName(index + 1)}
                                        </span>
                                        {!viewModel.searchTerm && (
                                            <div 
                                                className="hidden group-hover:flex items-center justify-center text-gray-500 hover:text-gray-600 cursor-move absolute inset-0"
                                            >
                                                ⋮⋮
                                            </div>
                                        )}
                                    </th>
                                ))}
                                <th className="p-0 "></th>
                            </tr>
                            <tr>
                                <th className="px-1">
                                    <div className="text-center text-gray-400 text-xs font-normal text-[0.6rem]">
                                        1
                                    </div>
                                </th>
                                <th className="p-2 ">
                                    <div className="flex items-center gap-2">
                                        <InputCheckbox
                                            checked={viewModel.selectedRows.size === viewModel.filteredData.length && viewModel.filteredData.length > 0}
                                            onChange={toggleAllRows}
                                        />
                                    </div>
                                </th>
                                {viewModel.columns.map((column, index) => (
                                    <th 
                                        key={column.id} 
                                        className="p-1 relative overflow-hidden"
                                        style={{ 
                                            '--column-width': `${column.width}px`,
                                            width: `${column.width}px`,
                                        }}
                                        draggable={false}
                                        onDragOver={handleColumnDragOver}
                                        onDrop={(e) => handleColumnDrop(e, index)}
                                    >
                                        <TableHeader
                                            column={column}
                                            onUpdateHeading={viewModel.updateColumnHeading}
                                            onUpdateType={viewModel.updateColumnType}
                                            onDelete={viewModel.deleteColumn}
                                            onMoveLeft={viewModel.moveColumnLeft}
                                            onMoveRight={viewModel.moveColumnRight}
                                            onAddLeft={viewModel.addColumnLeft}
                                            onAddRight={viewModel.addColumnRight}
                                            isFirst={index === 0}
                                            isLast={index === viewModel.columns.length - 1}
                                            searchActive={!!viewModel.searchTerm}
                                            onUpdateValidation={viewModel.updateColumnValidation}
                                            onDecreaseWidth={(columnId) => viewModel.decreaseColumnWidth(columnId)}
                                            onIncreaseWidth={(columnId) => viewModel.increaseColumnWidth(columnId)}
                                        />
                                    </th>
                                ))}
                                <th className="p-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewModel.filteredData.map(([rowId, rowData], index) => (
                                <tr
                                    key={rowId}
                                    data-row-id={rowId}
                                    data-is-ghost={viewModel.selectedRows.has(rowId) ? "true" : "false"}
                                    draggable={!viewModel.searchTerm && (viewModel.selectedRows.has(rowId) || viewModel.selectedRows.size === 0)}
                                    onDragStart={(e) => handleDragStart(e, index, rowId)}
                                    onDragEnd={handleDragEnd}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, index)}
                                    className={`hover: ${viewModel.selectedRows.has(rowId) ? 'bg-purple-50' : ''}`}
                                    style={{
                                        paddingLeft: `${(rowData._outlineLevel || 0) * 20}px`,
                                    }}
                                >

                                    <td className="px-1.5 text-center text-gray-400 text-[0.6rem] group relative">
                                        <span className="block group-hover:hidden">
                                            {viewModel.getActualRowNumber(rowId)}
                                        </span>
                                        {!viewModel.searchTerm && (
                                            <div className="hidden group-hover:flex items-center justify-center text-gray-500 hover:text-gray-600 cursor-move absolute inset-0">
                                                ⋮⋮
                                            </div>
                                        )}
                                    </td>
                                    <td className="px-2 group">
                                        <div className="flex items-center gap-2">
                                            <InputCheckbox
                                                checked={viewModel.selectedRows.has(rowId)}
                                                onChange={(checked) => toggleRowSelection(rowId, window.event?.shiftKey)}
                                                isSelected={selectedCell?.rowIndex === index && selectedCell?.columnIndex === 0}
                                                enableKeyboardToggle={false}
                                            />
                                            {rowData._outlineLevel > 0 && (
                                                <span className="text-xs text-gray-500">
                                                    {[...Array(rowData._outlineLevel)].map((_, i) => (
                                                        <span key={i}>↳</span>
                                                    ))}
                                                </span>
                                            )}
                                        </div>
                                    </td>

                                    {viewModel.columns.map((column, colIndex) => (
                                        <TableCell
                                            key={`${rowId}-${column.id}`}
                                            column={column}
                                            value={rowData[column.heading]?.value}
                                            style={{ 
                                                '--column-width': `${column.width}px`,
                                                width: `${column.width}px`,
                                            }}
                                            onChange={(value) => 
                                                viewModel.updateCell(rowId, column.id, value)
                                            }
                                            isSelected={selectedCell?.rowIndex === index && selectedCell?.columnIndex === colIndex}
                                            onSelect={() => setSelectedCell({ rowIndex: index, columnIndex: colIndex })}
                                            onUpdateValidation={(options) => viewModel.updateColumnValidation(column.id, options)}
                                        />
                                    ))}
                                    <td className="p-2 py-0.5">
                                        <div className="flex gap-2 max-w-32">
                                            <SelectDropdown
                                                placeholder="Actions"
                                                options={[
                                                    {
                                                        label: "Move up",
                                                        icon: <ArrowUpIcon className="h-4 w-4" />,
                                                        value: "moveUp",
                                                        disabled: index === 0
                                                    },
                                                    {
                                                        label: "Move down",
                                                        icon: <ArrowDownIcon className="h-4 w-4" />,
                                                        value: "moveDown",
                                                        disabled: index === viewModel.filteredData.length - 1
                                                    },
                                                    { type: "divider" },
                                                    {
                                                        label: "Decrease indent",
                                                        icon: <ArrowLeftIcon className="h-4 w-4" />,
                                                        value: "outdent",
                                                        disabled: !rowData._outlineLevel
                                                    },
                                                    {
                                                        label: "Increase indent",
                                                        icon: <ArrowRightIcon className="h-4 w-4" />,
                                                        value: "indent",
                                                        disabled: rowData._outlineLevel >= 8
                                                    },
                                                    { type: "divider" },
                                                    {
                                                        label: "Add row above",
                                                        icon: <PlusIcon className="h-4 w-4 rotate-90" />,
                                                        value: "addAbove",
                                                        disabled: !!viewModel.searchTerm
                                                    },
                                                    {
                                                        label: "Add row below",
                                                        icon: <PlusIcon className="h-4 w-4 -rotate-90" />,
                                                        value: "addBelow",
                                                        disabled: !!viewModel.searchTerm
                                                    },
                                                    { type: "divider" },
                                                    {
                                                        label: "Duplicate row",
                                                        icon: <DocumentDuplicateIcon className="h-4 w-4" />,
                                                        value: "duplicate",
                                                        disabled: !!viewModel.searchTerm
                                                    },
                                                    { type: "divider" },
                                                    {
                                                        label: "Delete row",
                                                        icon: <TrashIcon className="h-4 w-4" />,
                                                        value: "delete",
                                                        destructive: true,
                                                        disabled: !!viewModel.searchTerm
                                                    }
                                                ]}
                                                onChange={(action) => {
                                                    switch (action) {
                                                        case "moveUp":
                                                            viewModel.moveRowUp(rowId);
                                                            break;
                                                        case "moveDown":
                                                            viewModel.moveRowDown(rowId);
                                                            break;
                                                        case "outdent":
                                                            viewModel.outdentRow(rowId);
                                                            break;
                                                        case "indent":
                                                            viewModel.indentRow(rowId);
                                                            break;
                                                        case "addAbove":
                                                            viewModel.addRowAbove(rowId);
                                                            break;
                                                        case "addBelow":
                                                            viewModel.addRowBelow(rowId);
                                                            break;
                                                        case "duplicate":
                                                            viewModel.duplicateRows(rowId);
                                                            break;
                                                        case "delete":
                                                            viewModel.deleteRows([rowId]);
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                                stopPropagation={true}
                                                buttonClassName="px-2 py-1 text-blue-600 hover:bg-blue-50 rounded focus:outline-none"
                                                buttonText="Actions"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {viewModel.filteredData.length === 0 && (
                                <tr>
                                    <td colSpan={viewModel.columns.length + 2} className="text-center p-4 text-gray-500">
                                        {viewModel.searchTerm 
                                            ? 'No matching results found.' 
                                            : 'No rows yet. Click "Add Row" to get started.'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div 
                    ref={dragIndicatorRef}
                    className="drag-indicator"
                    style={indicatorStyle}
                />
            </div>

            <button
                    onClick={() => viewModel.addRow()}
                    disabled={!!viewModel.searchTerm}
                    className="my-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Add Row
                </button>
        </div>
    );
});