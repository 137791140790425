import React from 'react';

export const RichText = ({ value, onTab }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            onTab?.(e);
        }
    };

    if (!value) return '';

    // Handle URL and EMAIL types that come as objects with text and hyperlink
    if (typeof value === 'object' && !Array.isArray(value) && value.text && value.hyperlink) {
        const isEmail = value.hyperlink.startsWith('mailto:');
        return (
            <a 
                href={value.hyperlink}
                target={isEmail ? undefined : "_blank"}
                rel={isEmail ? undefined : "noopener noreferrer"}
                className={`hover:underline ${isEmail ? 'text-purple-600' : 'text-blue-600'}`}
                onClick={(e) => {
                    e.stopPropagation();
                    if (isEmail) {
                        e.preventDefault();
                        window.location.href = value.hyperlink;
                    }
                }}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                {value.text}
            </a>
        );
    }

    const renderParts = (parts) => {
        return parts.map((part, index) => {
            let text = part.text;
            
            if (part.font) {
                if (part.font.bold) text = `<b>${text}</b>`;
                if (part.font.italic) text = `<i>${text}</i>`;
                if (part.font.underline) text = `<u>${text}</u>`;
            }
            
            return <span key={index} dangerouslySetInnerHTML={{ __html: text }} />;
        });
    };

    return (
        <div 
            className="whitespace-pre-wrap"
            onKeyDown={handleKeyDown}
            tabIndex={0}
        >
            {Array.isArray(value) 
                ? renderParts(value)
                : value.richText 
                    ? renderParts(value.richText)
                    : value
            }
        </div>
    );
};