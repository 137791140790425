import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TableView } from '../Table/TableView';
import { ListView } from '../Apps/List/ListView';
import { BoardView } from '../Apps/Board/BoardView';
import { CalendarView } from '../Apps/Calendar/CalendarView';
import { TimelineView } from '../Apps/Timeline/TimelineView';
import { TableViewModel } from '../../viewModels/TableViewModel';
import { ListViewModel } from '../../viewModels/ListViewModel';
import { BoardViewModel } from '../../viewModels/BoardViewModel';
import { CalendarViewModel } from '../../viewModels/CalendarViewModel';
import { TimelineViewModel } from '../../viewModels/TimelineViewModel';
import { SheetViewModel } from '../../viewModels/SheetViewModel';
import { useStores } from '../../stores/RootStore';
import { SheetViewTabs } from './SheetViewTabs';
import { SheetRequirementsView } from './SheetRequirementsView';

export const SheetView = observer(({ sheet }) => {
    const { sheetDataStore } = useStores();
    const [activeView, setActiveView] = React.useState('TABLE');
    const [isLoading, setIsLoading] = React.useState(false);
    
    // Initialize view models
    const tableVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new TableViewModel(sheetDataStore);
    }, [sheetDataStore]);

    const listVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new ListViewModel(sheetDataStore);
    }, [sheetDataStore]);

    const boardVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new BoardViewModel(sheetDataStore);
    }, [sheetDataStore]);

    const calendarVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new CalendarViewModel(sheetDataStore);
    }, [sheetDataStore]);

    const timelineVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new TimelineViewModel(sheetDataStore);
    }, [sheetDataStore]);

    // Initialize sheet view model
    const sheetVM = React.useMemo(() => {
        if (!sheetDataStore) return null;
        return new SheetViewModel({ sheetStore: sheetDataStore });
    }, [sheetDataStore]);

    React.useEffect(() => {
        if (sheet?.name) {
            setIsLoading(true);
            sheetDataStore.loadSheet(sheet.name)
                .finally(() => setIsLoading(false));
        }
    }, [sheet, sheetDataStore]);

    if (!sheet) {
        return (
            <div className="flex-grow flex items-center justify-center text-zinc-400">
                No sheet selected
            </div>
        );
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <div className="flex-grow flex items-center justify-center">
                    <div className="flex flex-col items-center gap-2">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-zinc-800"></div>
                        <div className="text-zinc-600">Loading sheet...</div>
                    </div>
                </div>
            );
        }

        switch (activeView) {
            case 'LIST':
                return <ListView viewModel={listVM} />;
            case 'BOARD':
                return <BoardView viewModel={boardVM} />;
            case 'CALENDAR':
                return <CalendarView viewModel={calendarVM} />;
            case 'TIMELINE':
                return <TimelineView viewModel={timelineVM} />;
            default:
                if (!tableVM) {
                    return (
                        <div className="flex-grow flex items-center justify-center text-zinc-400">
                            Unable to initialize table view
                        </div>
                    );
                }
                return <TableView viewModel={tableVM} />;
        }
    };

    return (
        <div className="flex-grow flex flex-col">
            <SheetViewTabs
                viewModel={sheetVM}
                activeView={activeView}
                onViewChange={setActiveView}
            />
            <div className="flex-grow">
                {renderContent()}
            </div>
            
            <SheetRequirementsView
                isOpen={sheetVM.isViewRequirementsModalOpen}
                onClose={sheetVM.hideViewRequirementsModal}
                requirements={sheetVM.currentRequirements}
            />
        </div>
    );
});