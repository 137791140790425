import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';
import { SearchBoxComponent } from '../../Components/Search/SearchBoxComponent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectDropdown from '../../Components/Dropdown/SelectDropdown';
import SidePanel from '../../Components/SidePanel/SidePanel';
import { InputCheckbox } from '../../Components/Input/InputCheckbox';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    PlusIcon,
    DocumentDuplicateIcon,
    TrashIcon,
    ArrowRightEndOnRectangleIcon
} from '@heroicons/react/20/solid';

export const ListView = observer(({ viewModel }) => {
    const [newItemText, setNewItemText] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [collapsedItems, setCollapsedItems] = useState(new Set());
    const newItemInputRef = useRef(null);
    const listContainerRef = useRef(null);
    const itemRefs = useRef({});
    
    // Side panel state
    const [selectedItem, setSelectedItem] = useState(null);
    const [sidePanelOpen, setSidePanelOpen] = useState(false);
    const sidePanelWidth = "40rem"; // Centralized control of side panel width
    
    // Calculate adjusted width for content inside the panel
    const calculateAdjustedWidth = () => {
        // Convert width to a value we can work with
        let numericWidth;
        let unit;
        
        if (sidePanelWidth.endsWith('%')) {
            numericWidth = parseFloat(sidePanelWidth);
            unit = '%';
        } else if (sidePanelWidth.endsWith('px')) {
            numericWidth = parseFloat(sidePanelWidth);
            unit = 'px';
        } else if (sidePanelWidth.endsWith('rem')) {
            numericWidth = parseFloat(sidePanelWidth);
            unit = 'rem';
        } else {
            // Default to pixels if no unit specified
            numericWidth = parseFloat(sidePanelWidth);
            unit = 'px';
        }
        
        // Subtract 2px for border and 1rem (16px) for margin equivalent to m-4
        if (unit === 'px') {
            return `${numericWidth - 2 - 16*2}px`;
        } else if (unit === 'rem') {
            return `${numericWidth - 0.125 - 1*2}rem`; // 0.125rem = 2px, 1rem = m-4
        } else {
            // For percentage, we can't directly subtract pixels
            // This is an approximation
            return `calc(${sidePanelWidth} - 2px - 2rem)`;
        }
    };
    
    const adjustedPanelWidth = calculateAdjustedWidth();
    
    // Track the current sheet to detect sheet changes
    const [currentSheetId, setCurrentSheetId] = useState(null);

    // Add this state to track the current edited text
    const [editingText, setEditingText] = useState('');
    // Add this state to track newly created items that should be focused
    const [newlyCreatedItemId, setNewlyCreatedItemId] = useState(null);

    // Add state for context menu
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        item: null
    });

    // Add this near the top of your component
    const [isProcessing, setIsProcessing] = useState(false);

    // Ensure list container is focused initially and after certain operations
    useEffect(() => {
        // Remove auto-focus on initial render to prevent scrolling
        // listContainerRef.current?.focus();
    }, []);

    useEffect(() => {
        viewModel.initializeSheet();
        
        // Reset state when sheet changes
        const sheetId = viewModel.sheetDataStore?.currentSheet?.id;
        if (sheetId !== currentSheetId) {
            setCurrentSheetId(sheetId);
            setNewItemText('');
            setEditingId(null);
            setSidePanelOpen(false);
            setSelectedItem(null);
        }
    }, [viewModel, viewModel.sheetDataStore?.currentSheet, currentSheetId]);

    // Add to undo stack for tracking changes
    const addToUndoStack = (action) => {
        setUndoStack(prev => [...prev, action]);
        setRedoStack([]); // Clear redo stack on new action
    };

    const handleNewItemKeyDown = async (e) => {
        if (e.key === 'Enter') {
            // Only proceed if there's actual text content
            if (newItemText.trim()) {
                await viewModel.addItem(newItemText.trim());
                setNewItemText('');
                
                // Keep focus in the new item input
                setTimeout(() => {
                    newItemInputRef.current?.focus();
                }, 0);
            } else {
                // If text is empty, just clear the input but don't add an item
                setNewItemText('');
            }
        }
    };

    const handleNewItemFocus = () => {
        // When focusing the new item input, exit editing mode
        setEditingId(null);
    };

    const handleDragEnd = async (result) => {
        if (!result.destination) return;
        
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        
        if (sourceIndex === destinationIndex) return;
        
        const draggedId = result.draggableId;
        
        try {
            // Show loading state
            setIsProcessing(true);
            
            // Simply move the item to the new position
            // No need to check outline levels or block any moves
            await viewModel.moveItemToPosition(draggedId, destinationIndex);
            
        } catch (error) {
            console.error('Error during drag and drop:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    // Simplified item click handler focusing on selecting an item when side panel is open
    const handleItemClick = (item, e) => {
        console.log("Item clicked:", item.id, item.text);
        
        // Only select the item if the side panel is already open
        if (sidePanelOpen && !e.target.closest('button, input, a, .stopPropagation')) {
            console.log("Selecting item for side panel:", item.id);
            setSelectedItem(item);
        }
    };

    // New function to open the side panel
    const openSidePanel = (item, e) => {
        e.stopPropagation();
        console.log("Opening panel for item:", item.id);
        setSelectedItem(item);
        setSidePanelOpen(true);
    };

    // Update the handleItemDoubleClick function to start editing
    const handleItemDoubleClick = (itemId, emptyText = false) => {
        // Set the editing state to this item
        setEditingId(itemId);
        
        // Find the current item to save for potential undo
        const currentItem = viewModel.filteredItems.find(item => item.id === itemId);
        if (currentItem) {
            // If emptyText is true, start with empty text, otherwise use the current item's text
            setEditingText(emptyText ? '' : (currentItem.text || ''));
            addToUndoStack({
                type: 'edit',
                id: itemId,
                prevText: currentItem.text
            });
        }
    };

    // Add this useEffect to debug and track item updates
    useEffect(() => {
        console.log("Items updated:", viewModel.filteredItems);
    }, [viewModel.filteredItems]);

    // Completely rewrite the handleItemTextUpdate function based on TableCell approach
    const handleItemTextUpdate = async (itemId, newText) => {
        try {
            console.log(`Starting update for item ${itemId} with text "${newText}"`);
            
            // Check if the text is empty or just whitespace
            if (!newText.trim()) {
                console.log('Empty text not allowed, keeping edit mode active');
                return; // Don't exit edit mode, don't update
            }
            
            // Exit edit mode FIRST before any DOM or state updates
            setEditingId(null);
            
            // Then update the text in the view model
            // This separation is critical - we're not trying to update and render in the same cycle
            setTimeout(async () => {
                try {
                    await viewModel.updateItemText(itemId, newText);
                    console.log(`Update completed for item ${itemId}`);
                } catch (error) {
                    console.error("Error updating item text:", error);
                }
            }, 0);
        } catch (error) {
            console.error("Error in handleItemTextUpdate:", error);
        }
    };

    // Add this useEffect to handle DOM recreation for updated items
    useEffect(() => {
        // Find elements that need recreation
        const elementsToRecreate = document.querySelectorAll('.needs-recreation');
        elementsToRecreate.forEach(element => {
            // Remove the class so we don't process it again
            element.classList.remove('needs-recreation');
            
            // Find the text content element
            const textElement = element.querySelector('.text-content');
            if (textElement) {
                // Clear the inner HTML and let React rebuild it
                textElement.innerHTML = '';
            }
        });
    }, [viewModel.filteredItems]);

    // Completely rewrite the toggle function to fix the bugs
    const toggleCollapseLevel = (expand) => {
        // Get all outline levels present in the data
        const outlineLevels = viewModel.filteredItems
            .map(item => item.outlineLevel || 0)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => a - b);
        
        if (expand) {
            // EXPAND: Find the lowest level that has collapsed items
            // First, get all items that are currently collapsed
            const collapsedItemIds = Array.from(collapsedItems);
            const collapsedItemsData = collapsedItemIds
                .map(id => viewModel.filteredItems.find(item => item.id === id))
                .filter(Boolean);
            
            if (collapsedItemsData.length === 0) {
                // Nothing to expand, everything is already expanded
                return;
            }
            
            // Find the lowest outline level among collapsed items
            const lowestCollapsedLevel = Math.min(
                ...collapsedItemsData.map(item => item.outlineLevel || 0)
            );
            
            // Expand only items at this lowest level
            const newCollapsedItems = new Set(
                Array.from(collapsedItems).filter(id => {
                    const item = viewModel.filteredItems.find(item => item.id === id);
                    return item && (item.outlineLevel || 0) > lowestCollapsedLevel;
                })
            );
            
            setCollapsedItems(newCollapsedItems);
            console.log(`Expanded items at level ${lowestCollapsedLevel}`);
        } else {
            // COLLAPSE: Find the highest visible level that has items with children
            
            // First, determine which items are currently visible
            const visibleItems = viewModel.filteredItems.filter((item, index) => 
                isItemVisible(item, viewModel.filteredItems, index)
            );
            
            // Get the outline levels of visible items with children
            const visibleLevelsWithChildren = visibleItems
                .filter((item, index) => {
                    const originalIndex = viewModel.filteredItems.findIndex(i => i.id === item.id);
                    return hasChildren(item, originalIndex, viewModel.filteredItems);
                })
                .map(item => item.outlineLevel || 0)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => b - a); // Sort in descending order
            
            if (visibleLevelsWithChildren.length === 0) {
                // No visible items with children to collapse
                return;
            }
            
            // Get the highest level that has items with children
            const highestVisibleLevel = visibleLevelsWithChildren[0];
            
            // Collapse all items at this level that have children
            const itemsToCollapse = viewModel.filteredItems
                .filter((item, index) => 
                    (item.outlineLevel || 0) === highestVisibleLevel && 
                    hasChildren(item, index, viewModel.filteredItems) &&
                    !collapsedItems.has(item.id)
                )
                .map(item => item.id);
            
            if (itemsToCollapse.length === 0) {
                // If no items at this level to collapse, try the next level down
                if (visibleLevelsWithChildren.length > 1) {
                    const nextLevel = visibleLevelsWithChildren[1];
                    const nextItemsToCollapse = viewModel.filteredItems
                        .filter((item, index) => 
                            (item.outlineLevel || 0) === nextLevel && 
                            hasChildren(item, index, viewModel.filteredItems) &&
                            !collapsedItems.has(item.id)
                        )
                        .map(item => item.id);
                    
                    setCollapsedItems(new Set([...Array.from(collapsedItems), ...nextItemsToCollapse]));
                    console.log(`Collapsed items at next level ${nextLevel}`);
                }
            } else {
                setCollapsedItems(new Set([...Array.from(collapsedItems), ...itemsToCollapse]));
                console.log(`Collapsed items at level ${highestVisibleLevel}`);
            }
        }
    };

    // Function to determine if an item should be visible
    const isItemVisible = (item, items, index) => {
        // Always show items with outline level 0
        if (item.outlineLevel === 0) return true;
        
        // Look backwards to find parent
        for (let i = index - 1; i >= 0; i--) {
            const potentialParent = items[i];
            
            // If we find an item with same or lower level, it's the parent or sibling
            if (potentialParent.outlineLevel < item.outlineLevel) {
                // If parent is collapsed, hide this item
                if (collapsedItems.has(potentialParent.id)) {
                    return false;
                }
                
                // If parent is visible, check if parent's parent is visible
                return isItemVisible(potentialParent, items, i);
            } else if (potentialParent.outlineLevel === item.outlineLevel) {
                // Found a sibling, so check if sibling's parent is visible
                return isItemVisible(potentialParent, items, i);
            }
        }
        
        // If no parent found, item is at top level
        return true;
    };

    // Filter items based on visibility
    const visibleItems = viewModel.filteredItems.filter((item, index) => 
        isItemVisible(item, viewModel.filteredItems, index)
    );

    // Function to check if an item has children
    const hasChildren = (item, index, items) => {
        if (index === items.length - 1) return false;
        return items[index + 1].outlineLevel > item.outlineLevel;
    };

    // Update the useEffect to handle focusing on newly created items
    useEffect(() => {
        // If we have a newly created item, focus and edit it with empty text
        if (newlyCreatedItemId && viewModel.filteredItems.some(item => item.id === newlyCreatedItemId)) {
            handleItemDoubleClick(newlyCreatedItemId, true); // Pass true to start with empty text
            setNewlyCreatedItemId(null);
            
            // Prevent scrolling when focusing on newly created items
            window.scrollTo(0, 0);
        }
    }, [viewModel.filteredItems, newlyCreatedItemId]);

    // Add debug effect to monitor panel state
    useEffect(() => {
        console.log("SidePanel open state changed:", sidePanelOpen);
        console.log("Selected item:", selectedItem);
    }, [sidePanelOpen, selectedItem]);

    // Add handler for right-click context menu
    const handleContextMenu = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        
        console.log("Right-click detected on item:", item.id);
        
        // Position the context menu at the mouse position
        // Offset the y position by 10px upward
        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY - 20, // Subtract 10px to move it higher
            item: item
        });
    };
    
    // Add handler to close context menu
    const handleCloseContextMenu = () => {
        setContextMenu({
            visible: false,
            x: 0,
            y: 0,
            item: null
        });
    };
    
    // Add effect to close context menu on any click outside
    useEffect(() => {
        if (contextMenu.visible) {
            console.log("Context menu is now visible at:", contextMenu.x, contextMenu.y);
            
            const handleClickOutside = () => {
                handleCloseContextMenu();
            };
            
            // Use setTimeout to avoid immediate closing
            const timeoutId = setTimeout(() => {
                document.addEventListener('click', handleClickOutside);
            }, 100);
            
            return () => {
                clearTimeout(timeoutId);
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [contextMenu.visible]);
    
    // Debug effect to monitor context menu state
    useEffect(() => {
        console.log("Context menu state changed:", contextMenu);
    }, [contextMenu]);

    // Create a custom component for the context menu to ensure it opens immediately
    const ContextMenu = () => {
        // Force the dropdown to be open immediately
        useEffect(() => {
            // This is a hack to trigger a click on the dropdown to open it
            const timer = setTimeout(() => {
                const dropdownTrigger = document.querySelector('.context-menu-trigger');
                if (dropdownTrigger) {
                    dropdownTrigger.click();
                }
            }, 0);
            
            return () => clearTimeout(timer);
        }, []);
        
        return (
            <div 
                className="fixed z-[9999]" 
                style={{ 
                    top: contextMenu.y,
                    left: contextMenu.x
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <SelectDropdown
                    options={[
                        {
                            label: "Decrease indent",
                            icon: <ArrowLeftIcon className="h-4 w-4" />,
                            value: "outdent",
                            disabled: !contextMenu.item.outlineLevel
                        },
                        {
                            label: "Increase indent",
                            icon: <ArrowRightIcon className="h-4 w-4" />,
                            value: "indent",
                            disabled: contextMenu.item.outlineLevel >= 7
                        },
                        { type: "divider" },
                        {
                            label: "Add item above",
                            icon: <PlusIcon className="h-4 w-4 rotate-90" />,
                            value: "addAbove",
                            disabled: !!viewModel.searchTerm
                        },
                        {
                            label: "Add item below",
                            icon: <PlusIcon className="h-4 w-4 -rotate-90" />,
                            value: "addBelow",
                            disabled: !!viewModel.searchTerm
                        },
                        { type: "divider" },
                        {
                            label: "Duplicate item",
                            icon: <DocumentDuplicateIcon className="h-4 w-4" />,
                            value: "duplicate",
                            disabled: !!viewModel.searchTerm
                        },
                        { type: "divider" },
                        {
                            label: "Delete item",
                            icon: <TrashIcon className="h-4 w-4" />,
                            value: "delete",
                            destructive: true,
                            disabled: !!viewModel.searchTerm
                        }
                    ]}
                    onChange={async (action) => {
                        switch (action) {
                            case "outdent":
                                await viewModel.outdentItem(contextMenu.item.id);
                                break;
                            case "indent":
                                await viewModel.indentItem(contextMenu.item.id);
                                break;
                            case "addAbove":
                                const aboveItemId = await viewModel.addItemAbove(contextMenu.item.id);
                                setNewlyCreatedItemId(aboveItemId);
                                break;
                            case "addBelow":
                                const belowItemId = await viewModel.addItemBelow(contextMenu.item.id);
                                setNewlyCreatedItemId(belowItemId);
                                break;
                            case "duplicate":
                                await viewModel.duplicateItem(contextMenu.item.id);
                                break;
                            case "delete":
                                await viewModel.deleteItem(contextMenu.item.id);
                                break;
                            default:
                                break;
                        }
                        handleCloseContextMenu();
                    }}
                    customTrigger={
                        <div className="context-menu-trigger hidden">Trigger</div>
                    }
                />
            </div>
        );
    };

    // Update this effect to initialize collapsed items when data is loaded
    useEffect(() => {
        if (viewModel.filteredItems && viewModel.filteredItems.length > 0) {
            // Initialize with an empty set - no items collapsed by default
            setCollapsedItems(new Set());
        }
    }, [viewModel.sheetDataStore?.currentSheet]); // Only run when the sheet changes

    return (
        <div className="w-full flex flex-row relative justify-center">

            <div className="flex w-full justify-center focus:outline-none">
                <div className="flex flex-col p-4 w-full max-w-[50rem]  h-[calc(100vh-137px)] overflow-scroll focus:outline-none ">
                    <div className="flex items-center mb-4">
                        <div className="flex-grow">
                            <SearchBoxComponent
                                value={viewModel.searchTerm}
                                onChange={viewModel.setSearchTerm}
                                placeholder="Search items..."
                            />
                        </div>
                        <div className="flex ml-2">
                            <button
                                className="p-2 text-gray-600 hover:text-blue-600 rounded-l-md border border-gray-300 hover:border-blue-400 flex items-center"
                                onClick={() => toggleCollapseLevel(false)}
                                title="Collapse one level"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clipRule="evenodd" />
                                </svg>
                            </button>
                            <button
                                className="p-2 text-gray-600 hover:text-blue-600 rounded-r-md border-t border-r border-b border-gray-300 hover:border-blue-400 flex items-center"
                                onClick={() => toggleCollapseLevel(true)}
                                title="Expand one level"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="list-droppable">
                            {(provided) => (
                                <div
                                    ref={(el) => {
                                        provided.innerRef(el);
                                        listContainerRef.current = el;
                                    }}
                                    {...provided.droppableProps}
                                    className="flex-grow focus:outline-none"
                                    tabIndex={0}
                                >
                                    {visibleItems.map((item, index) => {
                                        const originalIndex = viewModel.filteredItems.findIndex(i => i.id === item.id);
                                        const hasChildItems = hasChildren(item, originalIndex, viewModel.filteredItems);
                                        const isCollapsed = collapsedItems.has(item.id);
                                        
                                        return (
                                            <Draggable 
                                                key={item.id} 
                                                draggableId={item.id} 
                                                index={index}
                                                isDragDisabled={!!viewModel.searchTerm}
                                            >
                                                {(provided, snapshot) => {
                                                    
                                                    return (
                                                        <div
                                                            ref={(el) => {
                                                                provided.innerRef(el);
                                                                itemRefs.current[item.id] = el;
                                                            }}
                                                            {...provided.draggableProps}
                                                            data-item-id={item.id}
                                                            className={`flex items-center p-1.5 rounded transition-colors border-b border-gray-100
                                                                ${snapshot.isDragging ? 'bg-blue-50 shadow-md' : ''}
                                                                hover:bg-gray-50 cursor-pointer group`}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                marginLeft: `${item.outlineLevel * 20}px`
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Stop event from bubbling up
                                                                handleItemClick(item, e);
                                                            }}
                                                            onDoubleClick={() => handleItemDoubleClick(item.id)}
                                                            onContextMenu={(e) => handleContextMenu(e, item)}
                                                        >
                                                            {!viewModel.searchTerm && (
                                                                <div className="flex items-center">
                                                                    <div 
                                                                        {...provided.dragHandleProps}
                                                                        className="text-gray-400 hover:text-gray-600 cursor-move mr-1 opacity-0 group-hover:opacity-100 transition-opacity"
                                                                    >
                                                                        ⋮⋮
                                                                    </div>
                                                                    {(item.outlineLevel || 0) < 7 && (
                                                                        <button
                                                                            className="text-gray-400 hover:text-blue-600 mr-2 w-4 h-4 flex items-center justify-center stopPropagation opacity-0 group-hover:opacity-100 transition-opacity"
                                                                            onClick={async (e) => {
                                                                                e.stopPropagation();
                                                                                
                                                                                // If the parent is collapsed, expand it first
                                                                                if (collapsedItems.has(item.id)) {
                                                                                    setCollapsedItems(prev => {
                                                                                        const newCollapsed = new Set(prev);
                                                                                        newCollapsed.delete(item.id);
                                                                                        return newCollapsed;
                                                                                    });
                                                                                }
                                                                                
                                                                                // Then add the child item
                                                                                const childItemId = await viewModel.addChildItem(item.id);
                                                                                setNewlyCreatedItemId(childItemId);
                                                                            }}
                                                                            title="Add child item"
                                                                        >
                                                                            +
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            
                                                            {hasChildItems && (
                                                                <div 
                                                                    className="mr-2 w-4 h-4 text-xs flex items-center justify-center cursor-pointer text-gray-400 hover:text-gray-700"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        // Toggle collapse state for this specific item
                                                                        setCollapsedItems(prev => {
                                                                            const newCollapsed = new Set(prev);
                                                                            if (isCollapsed) {
                                                                                newCollapsed.delete(item.id);
                                                                            } else {
                                                                                newCollapsed.add(item.id);
                                                                            }
                                                                            return newCollapsed;
                                                                        });
                                                                    }}
                                                                    title={isCollapsed ? "Expand" : "Collapse"}
                                                                >
                                                                    {isCollapsed ? '▶' : '▼'}
                                                                </div>
                                                            )}
                                                            
                                                            {!hasChildItems && (
                                                                <div className="mr-2 w-4 h-4"></div>
                                                            )}
                                                            
                                                            {item.done !== null && (
                                                                <div 
                                                                    className="mr-2 stopPropagation"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        // Remove this line as it's causing double-toggling
                                                                        // viewModel.toggleItem(item.id);
                                                                    }}
                                                                >
                                                                    <InputCheckbox
                                                                        checked={item.done}
                                                                        onChange={() => viewModel.toggleItem(item.id)}
                                                                        isSelected={selectedItem?.id === item.id}
                                                                        enableKeyboardToggle={false}
                                                                    />
                                                                </div>
                                                            )}
                                                            
                                                            {editingId === item.id ? (
                                                                <input
                                                                    type="text"
                                                                    value={editingText}
                                                                    onChange={(e) => {
                                                                        setEditingText(e.target.value);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        handleItemTextUpdate(item.id, editingText);
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            e.preventDefault();
                                                                            handleItemTextUpdate(item.id, editingText);
                                                                        } else if (e.key === 'Escape') {
                                                                            e.preventDefault();
                                                                            setEditingId(null);
                                                                            listContainerRef.current?.focus();
                                                                        }
                                                                    }}
                                                                    autoFocus
                                                                    className="flex-grow text-sm outline-none focus:ring-0"
                                                                />
                                                            ) : (
                                                                // Use a completely different approach for rendering text
                                                                <div className="flex-grow">
                                                                    <ItemText 
                                                                        text={item.text} 
                                                                        done={item.done}
                                                                        state={item.state}
                                                                        dueDate={item.dueDate}
                                                                    />
                                                                </div>
                                                            )}
                                                            
                                                            {/* Side panel button at far right */}
                                                            <button
                                                                className="ml-1 p-1 text-gray-400 hover:text-blue-600 rounded focus:outline-none stopPropagation opacity-0 group-hover:opacity-100 transition-opacity"
                                                                onClick={(e) => openSidePanel(item, e)}
                                                                title="View item details"
                                                            >
                                                                <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                                                            </button>
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <div className="mt-4">
                        <input
                            ref={newItemInputRef}
                            value={newItemText}
                            onChange={(e) => setNewItemText(e.target.value)}
                            onKeyDown={handleNewItemKeyDown}
                            onFocus={handleNewItemFocus}
                            placeholder="Add new item..."
                            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-300"
                        />
                    </div>
                </div>
            </div>
            
            {/* Keep using the SidePanel component */}
            <SidePanel
                isOpen={sidePanelOpen}
                onClose={() => {
                    setSidePanelOpen(false);
                }}
                title={selectedItem ? `Item: ${selectedItem.text}` : "Item Details"}
                width={sidePanelWidth}
                position="right"
                resizable={false}
                animationDuration={150}
            >
                {selectedItem ? (
                    <div className="px-4" style={{ width: adjustedPanelWidth }}>
                        
                        <p className="mb-2">ID: {selectedItem.id}</p>
                        <p className="mb-2">Text: {selectedItem.text}</p>
                        <p className="mb-2">Status: {selectedItem.done ? "Completed" : "Not completed"}</p>
                        
                        <div className="mt-6 space-y-3">
                            {/* Add the actions dropdown here */}
                            <div className="mb-4">
                                <h3 className="text-sm font-medium text-gray-700 mb-2">Actions</h3>
                                <SelectDropdown
                                    placeholder="Select an action"
                                    options={[
                                        {
                                            label: "Decrease indent",
                                            icon: <ArrowLeftIcon className="h-4 w-4" />,
                                            value: "outdent",
                                            disabled: !selectedItem.outlineLevel
                                        },
                                        {
                                            label: "Increase indent",
                                            icon: <ArrowRightIcon className="h-4 w-4" />,
                                            value: "indent",
                                            disabled: selectedItem.outlineLevel >= 7
                                        },
                                        { type: "divider" },
                                        {
                                            label: "Add item above",
                                            icon: <PlusIcon className="h-4 w-4 rotate-90" />,
                                            value: "addAbove",
                                            disabled: !!viewModel.searchTerm
                                        },
                                        {
                                            label: "Add item below",
                                            icon: <PlusIcon className="h-4 w-4 -rotate-90" />,
                                            value: "addBelow",
                                            disabled: !!viewModel.searchTerm
                                        },
                                        { type: "divider" },
                                        {
                                            label: "Duplicate item",
                                            icon: <DocumentDuplicateIcon className="h-4 w-4" />,
                                            value: "duplicate",
                                            disabled: !!viewModel.searchTerm
                                        },
                                        { type: "divider" },
                                        {
                                            label: "Delete item",
                                            icon: <TrashIcon className="h-4 w-4" />,
                                            value: "delete",
                                            destructive: true,
                                            disabled: !!viewModel.searchTerm
                                        }
                                    ]}
                                    onChange={async (action) => {
                                        switch (action) {
                                            case "outdent":
                                                await viewModel.outdentItem(selectedItem.id);
                                                break;
                                            case "indent":
                                                await viewModel.indentItem(selectedItem.id);
                                                break;
                                            case "addAbove":
                                                const aboveItemId = await viewModel.addItemAbove(selectedItem.id);
                                                setNewlyCreatedItemId(aboveItemId);
                                                setSidePanelOpen(false);
                                                break;
                                            case "addBelow":
                                                const belowItemId = await viewModel.addItemBelow(selectedItem.id);
                                                setNewlyCreatedItemId(belowItemId);
                                                setSidePanelOpen(false);
                                                break;
                                            case "duplicate":
                                                await viewModel.duplicateItem(selectedItem.id);
                                                break;
                                            case "delete":
                                                await viewModel.deleteItem(selectedItem.id);
                                                setSidePanelOpen(false);
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    buttonClassName="w-full py-2 px-3 bg-white border border-gray-300 rounded-md shadow-sm text-left focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                    buttonText="Select an action..."
                                />
                            </div>
                            
                            {/* Dummy form content for scroll testing */}
                            <div className="border-t pt-4 mt-4">
                                <h2 className="text-lg font-semibold mb-4">Item Details</h2>
                                
                                {/* Text field */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Title
                                    </label>
                                    <input
                                        type="text"
                                        defaultValue={selectedItem.text}
                                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                
                                {/* Text area */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Description
                                    </label>
                                    <textarea
                                        rows={4}
                                        defaultValue="This is a detailed description of the item. You can add notes, context, or any other relevant information here."
                                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                
                                {/* Select dropdown */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Priority
                                    </label>
                                    <select className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500">
                                        <option>Low</option>
                                        <option>Medium</option>
                                        <option selected>High</option>
                                        <option>Critical</option>
                                    </select>
                                </div>
                                
                                {/* Date picker */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Due Date
                                    </label>
                                    <input
                                        type="date"
                                        defaultValue="2023-12-31"
                                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                                
                                {/* Checkboxes */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Tags
                                    </label>
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <InputCheckbox
                                                checked={true}
                                                onChange={() => {}}
                                                id="tag-work"
                                            />
                                            <label htmlFor="tag-work" className="ml-2 text-sm text-gray-700">
                                                Work
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <InputCheckbox
                                                checked={false}
                                                onChange={() => {}}
                                                id="tag-personal"
                                            />
                                            <label htmlFor="tag-personal" className="ml-2 text-sm text-gray-700">
                                                Personal
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <InputCheckbox
                                                checked={true}
                                                onChange={() => {}}
                                                id="tag-urgent"
                                            />
                                            <label htmlFor="tag-urgent" className="ml-2 text-sm text-gray-700">
                                                Urgent
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Radio buttons */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Status
                                    </label>
                                    <div className="space-y-2">
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="status-todo"
                                                name="status"
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                            />
                                            <label htmlFor="status-todo" className="ml-2 text-sm text-gray-700">
                                                To Do
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="status-in-progress"
                                                name="status"
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                                defaultChecked
                                            />
                                            <label htmlFor="status-in-progress" className="ml-2 text-sm text-gray-700">
                                                In Progress
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="radio"
                                                id="status-done"
                                                name="status"
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                            />
                                            <label htmlFor="status-done" className="ml-2 text-sm text-gray-700">
                                                Done
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Slider */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Progress (75%)
                                    </label>
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        defaultValue="75"
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                                    />
                                </div>
                                
                                {/* Color picker */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Color Label
                                    </label>
                                    <input
                                        type="color"
                                        defaultValue="#3B82F6"
                                        className="w-full h-10 p-1 border border-gray-300 rounded-md"
                                    />
                                </div>
                                
                                {/* File upload */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Attachments
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">PNG, JPG, PDF up to 10MB</p>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Assignee field with avatar */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Assigned To
                                    </label>
                                    <div className="flex items-center p-2 border border-gray-300 rounded-md">
                                        <div className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center text-white font-medium mr-2">
                                            JS
                                        </div>
                                        <span>John Smith</span>
                                    </div>
                                </div>
                                
                                {/* Comments section */}
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Comments
                                    </label>
                                    <div className="space-y-3">
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <div className="flex items-center mb-1">
                                                <div className="h-6 w-6 rounded-full bg-purple-500 flex items-center justify-center text-white text-xs font-medium mr-2">
                                                    AK
                                                </div>
                                                <span className="text-sm font-medium">Alice Kim</span>
                                                <span className="text-xs text-gray-500 ml-2">2 days ago</span>
                                            </div>
                                            <p className="text-sm text-gray-700">
                                                This looks good! Let's make sure we complete it by the end of the week.
                                            </p>
                                        </div>
                                        <div className="bg-gray-50 p-3 rounded-md">
                                            <div className="flex items-center mb-1">
                                                <div className="h-6 w-6 rounded-full bg-green-500 flex items-center justify-center text-white text-xs font-medium mr-2">
                                                    TJ
                                                </div>
                                                <span className="text-sm font-medium">Tom Johnson</span>
                                                <span className="text-xs text-gray-500 ml-2">Yesterday</span>
                                            </div>
                                            <p className="text-sm text-gray-700">
                                                I've started working on this. Should be done by Thursday.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <textarea
                                            placeholder="Add a comment..."
                                            rows={2}
                                            className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                        <button className="mt-2 px-3 py-1.5 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700">
                                            Add Comment
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="border-t pt-4 mt-4">
                                <h2 className="text-lg font-semibold mb-4">Quick Actions</h2>
                                <div className="grid grid-cols-2 gap-2">
                                    <button 
                                        className="py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                        onClick={() => viewModel.toggleItem(selectedItem.id)}
                                    >
                                        Toggle Status
                                    </button>
                                    
                                    <button 
                                        className="py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                        onClick={() => {
                                            viewModel.deleteItem(selectedItem.id);
                                            setSidePanelOpen(false);
                                        }}
                                    >
                                        Delete Item
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="p-4 text-center">
                        <p>No item selected</p>
                    </div>
                )}
            </SidePanel>
            
            {/* Add context menu */}
            {contextMenu.visible && contextMenu.item && ReactDOM.createPortal(
                <ContextMenu />,
                document.body
            )}

            {/* Add this to your JSX, perhaps right after the DragDropContext */}
            {isProcessing && (
                <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <div className="flex items-center space-x-3">
                            <svg className="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Processing...</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

// Add this component at the end of your file, outside the main component
const ItemText = React.memo(({ text, done, state, dueDate }) => {
    // Ensure text is a string
    const displayText = typeof text === 'string' ? text : '';
    
    return (
        <>
            {done ? (
                <span className="text-sm line-through text-gray-500">{displayText}</span>
            ) : (
                <span className="text-sm">{displayText}</span>
            )}
            
            {state && (
                <span className="ml-2 text-sm text-gray-500">
                    ({state})
                </span>
            )}
            {dueDate && (
                <span className="ml-2 text-sm text-gray-500">
                    Due: {new Date(dueDate).toLocaleDateString()}
                </span>
            )}
        </>
    );
});