import React from 'react';
import { observer } from 'mobx-react-lite';

export const BoardView = observer(({ viewModel }) => {
    if (!viewModel) return null;

    return (
        <div className="flex-grow p-4 overflow-auto">
            <div className="flex gap-4 h-full">
                {/* Implement cards here */}
            </div>
        </div>
    );
});