import React from 'react';
import { observer } from 'mobx-react-lite';

export const TimelineView = observer(({ viewModel }) => {
    if (!viewModel) return null;

    return (
        <div className="flex-grow p-4">
            {/* Implement timeline visualization here */}
        </div>
    );
});