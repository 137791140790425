import { makeObservable, action, observable } from 'mobx';
import { ViewTypes } from '../models/SheetModel';

export class SheetViewModel {
    MAX_SHEETS = 5;
    isViewRequirementsModalOpen = false;
    currentRequirements = null;

    constructor({ sheetStore }) {
        if (!sheetStore) {
            throw new Error('SheetViewModel requires a sheetStore');
        }
        
        this.stores = { sheetStore };
        makeObservable(this, {
            stores: false,
            isViewRequirementsModalOpen: observable,
            currentRequirements: observable,
            showViewRequirementsModal: action,
            hideViewRequirementsModal: action,
            selectSheet: action,
            removeSheet: action,
            updateSheetName: action,
            addSheet: action,
            handleSheetTypeSelection: action
        });
    }

    showViewRequirementsModal = (requirements) => {
        this.currentRequirements = Array.isArray(requirements) ? requirements : [];
        this.isViewRequirementsModalOpen = true;
    };

    hideViewRequirementsModal = () => {
        this.isViewRequirementsModalOpen = false;
        this.currentRequirements = null;
    };

    get visibleSheets() {
        return this.stores.sheetStore.visibleSheets || [];
    }

    get selectedSheetIndex() {
        return this.stores.sheetStore.selectedSheetIndex ?? 0;
    }

    get currentSheet() {
        return this.stores.sheetStore.currentSheet;
    }

    addSheet = () => {
        console.log('Adding new sheet');
        if (this.visibleSheets.length >= this.MAX_SHEETS) return;
        return this.stores.sheetStore.addSheet();
    };

    removeSheet = (index) => {
        if (this.visibleSheets.length <= 1) return;
        this.stores.sheetStore.removeSheet(index);
    };

    selectSheet = (index) => {
        console.log('Selecting sheet:', index);
        this.stores.sheetStore.selectSheet(index);
        console.log('Selected sheet index after update:', this.selectedSheetIndex);
    };

    updateSheetName = (index, newName) => {
        if (!newName?.trim()) return;
        
        const sheet = this.visibleSheets[index];
        if (!sheet) return;

        let finalName = this.getUniqueSheetName(newName, sheet.name);
        this.stores.sheetStore.updateSheetName(index, finalName);
    };

    handleSheetTypeSelection = (type) => {
        console.log('Handling sheet type selection:', { 
            type, 
            selectedSheetIndex: this.selectedSheetIndex,
            visibleSheets: this.visibleSheets.length
        });
        
        try {
            this.stores.sheetStore.setSheetType(this.selectedSheetIndex, type);
        } catch (error) {
            console.error('Error setting sheet type:', error);
        }
    };

    getUniqueSheetName = (desiredName, currentName) => {
        const sheets = this.visibleSheets;
        let name = desiredName.trim();
        let counter = 1;
        
        if (name === currentName) return name;
        
        while (sheets.some(sheet => sheet.name === name)) {
            name = `${desiredName.trim()} (${counter})`;
            counter++;
        }
        
        return name;
    };

    moveSheet = (fromIndex, toIndex) => {
        console.log('Moving sheet:', { fromIndex, toIndex });
        this.stores.sheetStore.moveSheet(fromIndex, toIndex);
    };

    get availableViews() {
        return Object.values(ViewTypes);
    }

    checkViewRequirements(viewType) {
        if (!this.currentSheet) return { valid: false, missingColumns: [] };
        
        const sheetColumns = this.currentSheet.columns;
        const requiredColumns = ViewTypes[viewType].requiredColumns;
        
        const missingColumns = requiredColumns.filter(required => {
            return !sheetColumns.some(col => col.type.id === required.type.id);
        });

        return {
            valid: missingColumns.length === 0,
            missingColumns
        };
    }

    createRequiredColumns = (viewType) => {
        const { missingColumns } = this.checkViewRequirements(viewType);
        missingColumns.forEach(column => {
            // Assuming you have a method to add columns to the sheet
            this.stores.sheetStore.addColumn(
                this.selectedSheetIndex,
                column.name,
                column.type
            );
        });
    };
}