import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { 
    DocumentTextIcon, 
    DocumentIcon, 
    EnvelopeIcon, 
    LinkIcon,
    CalendarIcon, 
    FlagIcon, 
    CheckIcon,
    ListBulletIcon,
    UserIcon,
    StarIcon,
    CheckCircleIcon,
    PercentIcon,
    HashtagIcon
} from '@heroicons/react/20/solid';

// Single source of truth for column types and their validation rules
export const ColumnTypes = {
    // Text
    SINGLE_LINE_TEXT: {
        id: 'SINGLE_LINE_TEXT',
        label: 'Single-line Text',
        validate: (value) => typeof value === 'string',
        icon: '📝'
    },
    MULTI_LINE_TEXT: {
        id: 'MULTI_LINE_TEXT',
        label: 'Multi-line Text',
        validate: (value) => typeof value === 'string',
        icon: '📄'
    },
    EMAIL: {
        id: 'EMAIL',
        label: 'Email',
        validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        icon: '✉️'
    },
    URL: {
        id: 'URL',
        label: 'URL',
        validate: (value) => {
            try {
                new URL(value);
                return true;
            } catch {
                return false;
            }
        },
        icon: '🔗'
    },

    // Dates
    DATE: {
        id: 'DATE',
        label: 'Date',
        validate: (value) => !isNaN(Date.parse(value)),
        icon: '📅'
    },
    START_DATE: {
        id: 'START_DATE',
        label: 'Start Date',
        validate: (value) => !isNaN(Date.parse(value)),
        icon: '🏁'
    },
    END_DATE: {
        id: 'END_DATE',
        label: 'End Date',
        validate: (value) => !isNaN(Date.parse(value)),
        icon: '🏆'
    },

    // Managed by data validation in cells - editable
    SELECT: {
        id: 'SELECT',
        label: 'Select',
        validate: (value, options) => !value || options.includes(value),
        hasCustomValidation: true,
        defaultOptions: [],
        icon: '📋'
    },
    STATUS: {
        id: 'STATUS',
        label: 'Status',
        validate: (value, options) => !value || options.includes(value),
        hasCustomValidation: true,
        defaultOptions: ['Not Started', 'In Progress', 'Done'],
        icon: '🔄'
    },
    PERSON: {
        id: 'PERSON',
        label: 'Person',
        validate: (value, options) => !value || options.includes(value),
        hasCustomValidation: true,
        defaultOptions: [],
        icon: '👤'
    },

    // Managed by data validation in cells - non-editable
    RATING: {
        id: 'RATING',
        label: 'Rating',
        validate: (value) => Number.isInteger(value) && value >= 0 && value <= 5,
        defaultOptions: ['0', '1', '2', '3', '4', '5'],
        icon: '⭐'
    },

    // Booleans
    CHECKBOX: {
        id: 'CHECKBOX',
        label: 'Checkbox',
        validate: (value) => typeof value === 'boolean',
        defaultOptions: ['TRUE', 'FALSE'],
        icon: '✅'
    },

    // Numbers
    PERCENTAGE: {
        id: 'PERCENTAGE',
        label: 'Percentage',
        validate: (value) => !isNaN(value) && value >= 0 && value <= 100,
        icon: '%'
    },
    NUMBER: {
        id: 'NUMBER',
        label: 'Number',
        validate: (value) => !isNaN(value),
        icon: '#'
    },
};

export const ViewTypes = {
    TABLE: {
        id: 'TABLE',
        label: 'Table',
        icon: '📊',
        requiredColumns: [], // Table view can handle any columns
        optionalColumns: [],
        description: 'View and edit data in a spreadsheet format'
    },
    LIST: {
        id: 'LIST',
        label: 'List',
        icon: '📝',
        requiredColumns: [
            { type: ColumnTypes.SINGLE_LINE_TEXT, name: 'Task' }
        ],
        optionalColumns: [
            { type: ColumnTypes.CHECKBOX, name: 'Done' },
            { type: ColumnTypes.DATE, name: 'Due Date' },
            { type: ColumnTypes.PERSON, name: 'Assignee' },
            { type: ColumnTypes.STATUS, name: 'Priority' }
        ],
        description: 'Organize tasks in a checklist format'
    },
    BOARD: {
        id: 'BOARD',
        label: 'Board',
        icon: '📋',
        requiredColumns: [
            { type: ColumnTypes.STATUS, name: 'Status' }
        ],
        optionalColumns: [
            { type: ColumnTypes.PERSON, name: 'Assignee' },
            { type: ColumnTypes.DATE, name: 'Due Date' }
        ],
        description: 'Kanban board view for managing workflows'
    },
    CALENDAR: {
        id: 'CALENDAR',
        label: 'Calendar',
        icon: '📅',
        requiredColumns: [
            { type: ColumnTypes.DATE, name: 'Date' }
        ],
        optionalColumns: [
            { type: ColumnTypes.END_DATE, name: 'End Date' }
        ],
        description: 'View items on a calendar'
    },
    TIMELINE: {
        id: 'TIMELINE',
        label: 'Timeline',
        icon: '⏱️',
        requiredColumns: [
            { type: ColumnTypes.START_DATE, name: 'Start Date' },
            { type: ColumnTypes.END_DATE, name: 'End Date' }
        ],
        optionalColumns: [
            { type: ColumnTypes.STATUS, name: 'Status' },
            { type: ColumnTypes.PERSON, name: 'Assignee' }
        ],
        description: 'Visualize items on a timeline'
    }
};

export class Column {
    constructor(heading, type = ColumnTypes.SINGLE_LINE_TEXT, validationOptions = [], width = 8.43) {
        this.id = uuidv4();
        this.heading = heading;
        this.type = type;
        this.validationOptions = type.hasCustomValidation ? 
            (validationOptions.length > 0 ? validationOptions : type.defaultOptions) : 
            [];
        this.width = width;
        makeAutoObservable(this);
    }

    isValidValue(value) {
        if (!value) return true; // Allow empty values unless specifically required
        return this.type.validate(value, this.validationOptions);
    }

    setValidationOptions(options) {
        if (this.type.hasCustomValidation) {
            this.validationOptions = options;
        }
    }

    setWidth(width) {
        this.width = Math.max(50, Math.min(1000, width)); // Constrain between 50px and 1000px
    }
}

export class Sheet {
    constructor(name) {
        this.id = uuidv4();
        this.name = name;
        this.columns = [];
        this.rows = new Map();
        this.availableViews = new Set(['TABLE']); // Start with just table view
        
        makeAutoObservable(this, {
            id: false,
            rows: true
        });
    }

    isValidColumnHeading(heading) {
        return heading && 
               typeof heading === 'string' && 
               heading.trim().length > 0 &&
               !this.columns.some(col => col.heading === heading);
    }

    addView(viewType) {
        if (ViewTypes[viewType]) {
            this.availableViews.add(viewType);
        }
    }

    removeView(viewType) {
        if (viewType !== 'TABLE') { // Don't allow removing the table view
            this.availableViews.delete(viewType);
        }
    }

    hasRequiredColumnsForView(viewType) {
        if (!ViewTypes[viewType]) return false;
        
        const requiredColumns = ViewTypes[viewType].requiredColumns;
        return requiredColumns.every(required => 
            this.columns.some(col => col.type.id === required.type.id)
        );
    }

    getMissingColumnsForView(viewType) {
        if (!ViewTypes[viewType]) return [];
        
        const requiredColumns = ViewTypes[viewType].requiredColumns;
        return requiredColumns.filter(required => 
            !this.columns.some(col => col.type.id === required.type.id)
        );
    }
}