import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/20/solid";
import Badge from "../Badge/Badge";

const SelectDropdown = ({
  value,
  options = [],
  onChange,
  placeholder = "Select...",
  position = "bottom",
  variant = "default", // default, checkbox, radio
  maxHeight = 350,
  showDropdownButton = true,
  containerRef = null,
  stopPropagation = false,
  customTrigger = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  // Handle opening and closing with animation
  const handleOpenChange = (open) => {
    if (isOpen && !open) {
      // Start closing animation
      setIsClosing(true);
      // Wait for animation to complete before actually closing
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
        // Reset highlighted index when closing
        setHighlightedIndex(-1);
      }, 150);
    } else {
      setIsOpen(open);
    }
  };

  // Update dropdown position
  const updateDropdownPosition = () => {
    if (!buttonRef.current) return;
    
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Calculate minimum width
    const minWidth = Math.max(200, buttonRect.width);
    
    // Get the actual dropdown height if available
    let actualDropdownHeight = 0;
    if (menuRef.current) {
      actualDropdownHeight = menuRef.current.getBoundingClientRect().height;
    } else {
      // Fallback to estimation if actual height is not available
      const optionCount = Math.max(1, options.length);
      const optionHeight = 36; // Average height of an option
      const dropdownPadding = 16; // Top and bottom padding
      actualDropdownHeight = Math.min(maxHeight, optionCount * optionHeight + dropdownPadding);
    }
    
    // Add a margin for better spacing
    const verticalMargin = 8;
    
    // Calculate available space below and above the button
    const spaceBelow = viewportHeight - buttonRect.bottom - verticalMargin;
    const spaceAbove = buttonRect.top - verticalMargin;
    
    let top, left, width;
    let actualPosition = position;
    
    // Determine if dropdown should appear above or below
    if (position === "auto" || position === "bottom") {
      // For auto or bottom position, check if there's enough space below
      if (actualDropdownHeight > spaceBelow && spaceAbove > spaceBelow) {
        actualPosition = "top";
      } else {
        actualPosition = "bottom";
      }
    }
    
    if (actualPosition === "top") {
      // Position above with consistent spacing
      top = buttonRect.top - actualDropdownHeight - verticalMargin;
      
      // If there's not enough space above, adjust the max height
      if (actualDropdownHeight > spaceAbove) {
        const adjustedMaxHeight = Math.max(100, spaceAbove - verticalMargin);
        setDropdownHeight(adjustedMaxHeight);
        top = verticalMargin; // Position at top with margin
      }
    } else {
      // Position below with consistent spacing
      top = buttonRect.bottom + verticalMargin;
      
      // If there's not enough space below, try to adjust height or switch to top
      if (actualDropdownHeight > spaceBelow) {
        if (spaceAbove > spaceBelow && spaceAbove >= 100) {
          // If there's more usable space above, switch to top positioning
          actualPosition = "top";
          const adjustedHeight = Math.min(actualDropdownHeight, spaceAbove - verticalMargin);
          setDropdownHeight(adjustedHeight);
          top = buttonRect.top - adjustedHeight - verticalMargin;
        } else {
          // Otherwise adjust height to fit below
          const adjustedMaxHeight = Math.max(100, spaceBelow - verticalMargin);
          setDropdownHeight(adjustedMaxHeight);
        }
      }
    }
    
    // Calculate horizontal position
    left = buttonRect.left;
    width = minWidth;
    
    // Ensure dropdown doesn't extend beyond right edge of viewport
    if (left + width > viewportWidth) {
      // Align to right edge of the button
      left = buttonRect.right - width;
      
      // If still off-screen, align to right edge of viewport
      if (left < 0) {
        left = 10; // 10px buffer from left edge
      }
    }
    
    setDropdownPosition({
      top,
      left,
      width,
    });
  };

  // Measure dropdown height after it's rendered
  useEffect(() => {
    if (isOpen && menuRef.current) {
      const height = menuRef.current.getBoundingClientRect().height;
      setDropdownHeight(height);
      // Update position after getting the real height
      updateDropdownPosition();
    }
  }, [isOpen, menuRef.current]);

  // Update position when dropdown opens
  useEffect(() => {
    if (isOpen) {
      updateDropdownPosition();
      
      // Add event listeners for repositioning
      window.addEventListener("resize", updateDropdownPosition);
      window.addEventListener("scroll", updateDropdownPosition, true);
      
      return () => {
        window.removeEventListener("resize", updateDropdownPosition);
        window.removeEventListener("scroll", updateDropdownPosition, true);
      };
    }
  }, [isOpen]);

  // Add a separate effect to handle content size changes
  useEffect(() => {
    if (isOpen && menuRef.current) {
      // Initial position update
      updateDropdownPosition();
      
      // Use ResizeObserver to detect content size changes
      const resizeObserver = new ResizeObserver(() => {
        updateDropdownPosition();
      });
      
      resizeObserver.observe(menuRef.current);
      
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [isOpen, options.length]);

  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleOpenChange(false);
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleOpenChange(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isOpen]);

  // Handle selecting an option
  const handleSelectOption = (option) => {
    if (option.disabled) return;
    
    if (variant === "checkbox") {
      // For checkbox, toggle the selected state
      const newValue = Array.isArray(value) ? [...value] : [];
      const index = newValue.findIndex(item => item === option.value);
      
      if (index >= 0) {
        newValue.splice(index, 1);
      } else {
        newValue.push(option.value);
      }
      
      onChange?.(newValue);
    } else if (variant === "radio") {
      // For radio, select only one option
      onChange?.(option.value);
    } else {
      // For default, just select the option
      onChange?.(option.value);
      handleOpenChange(false);
    }
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
        e.preventDefault();
        handleOpenChange(true);
        // Start by highlighting the first selectable option
        const firstSelectableIndex = options.findIndex(opt => opt.type !== 'divider');
        setHighlightedIndex(firstSelectableIndex >= 0 ? firstSelectableIndex : 0);
      }
      return;
    }

    // Get only selectable options (not dividers)
    const selectableOptions = options.filter(opt => opt.type !== 'divider');
    // Create a mapping from selectable index to actual index in the options array
    const selectableToActualIndex = options.reduce((map, opt, index) => {
      if (opt.type !== 'divider') {
        map.push(index);
      }
      return map;
    }, []);
    
    // Find the current selectable index based on the highlighted actual index
    const currentSelectableIndex = selectableToActualIndex.indexOf(highlightedIndex);
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (currentSelectableIndex >= 0) {
          // Move to next selectable option
          const nextSelectableIndex = (currentSelectableIndex + 1) % selectableOptions.length;
          setHighlightedIndex(selectableToActualIndex[nextSelectableIndex]);
        } else {
          // If no option is currently highlighted, highlight the first selectable option
          setHighlightedIndex(selectableToActualIndex[0]);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (currentSelectableIndex >= 0) {
          // Move to previous selectable option
          const prevSelectableIndex = (currentSelectableIndex - 1 + selectableOptions.length) % selectableOptions.length;
          setHighlightedIndex(selectableToActualIndex[prevSelectableIndex]);
        } else {
          // If no option is currently highlighted, highlight the last selectable option
          setHighlightedIndex(selectableToActualIndex[selectableOptions.length - 1]);
        }
        break;
      case 'Enter':
      case ' ':
        e.preventDefault();
        if (highlightedIndex >= 0 && options[highlightedIndex] && options[highlightedIndex].type !== 'divider') {
          handleSelectOption(options[highlightedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        handleOpenChange(false);
        break;
      case 'Tab':
        handleOpenChange(false);
        break;
      default:
        break;
    }
  };

  // Render dropdown with portal
  const renderDropdown = () => {
    if (!isOpen && !isClosing) return null;
    
    // Add keyframes for the opening animation
    const keyframes = `
      @keyframes dropdownOpen {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    `;
    
    const dropdownElement = (
      <>
        <style>{keyframes}</style>
        <div 
          ref={menuRef}
          className="z-[9999] bg-white border border-gray-200 rounded-md shadow-lg overflow-hidden text-xs"
          style={{ 
            maxHeight: `${maxHeight}px`, 
            overflowY: "auto",
            width: `${dropdownPosition.width}px`,
            position: "fixed",
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            display: "block",
            minWidth: "200px",
            opacity: isClosing ? 0 : 1,
            transform: isClosing ? "translateY(-10px)" : "translateY(0)",
            transition: "opacity 150ms ease-in-out, transform 150ms ease-in-out",
            animation: !isClosing ? "dropdownOpen 150ms ease-in-out" : undefined,
          }}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
          }}
        >
          <div className="py-1">
            {options.map((option, index) => {
              if (option.type === "divider") {
                return <div key={`divider-${index}`} className="border-t border-gray-200 my-1"></div>;
              }
              
              if (option.type === "nested" && option.items) {
                return (
                  <NestedDropdown 
                    key={`nested-${index}`}
                    option={option}
                    onSelect={handleSelectOption}
                    value={value}
                    variant={variant}
                    isHighlighted={highlightedIndex === index}
                  />
                );
              }
              
              const isSelected = variant === "checkbox" 
                ? Array.isArray(value) && value.includes(option.value)
                : value === option.value;
              
              const isHighlighted = highlightedIndex === index;
              
              return (
                <div
                  key={index}
                  className={`
                    ${option.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
                    ${option.destructive ? "text-red-600 hover:bg-red-50" : "hover:bg-gray-100"}
                    ${isSelected ? "bg-gray-50" : ""}
                    ${isHighlighted ? "bg-gray-100" : ""}
                    w-full px-3 py-1.5 text-left flex items-center text-xs
                  `}
                  onClick={() => !option.disabled && handleSelectOption(option)}
                >
                  {variant === "checkbox" && (
                    <div className="mr-2 w-3 h-3 flex items-center justify-center">
                      {isSelected && <CheckIcon className="h-3 w-3 text-purple-600" />}
                    </div>
                  )}
                  
                  {variant === "radio" && (
                    <div className="mr-2 w-3 h-3 border rounded-full flex items-center justify-center">
                      {isSelected && (
                        <div className="w-1.5 h-1.5 rounded-full bg-purple-600"></div>
                      )}
                    </div>
                  )}
                  
                  {option.icon && (
                    <span className={`mr-2 ${option.destructive ? "text-red-600" : ""}`}>
                      {option.icon}
                    </span>
                  )}
                  
                  <span className={option.destructive ? "text-red-600" : ""}>
                    {option.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
    
    // Create a portal to the document body
    return ReactDOM.createPortal(
      dropdownElement,
      document.body
    );
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left w-full">
      {customTrigger ? (
        // Use custom trigger if provided
        <div
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
            handleOpenChange(!isOpen);
          }}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          aria-haspopup="true"
          aria-expanded={isOpen}
          ref={buttonRef}
          className="focus:outline-none"
        >
          {customTrigger}
        </div>
      ) : (
        // Otherwise use the default button
        <button
          ref={buttonRef}
          type="button"
          className="inline-flex w-full justify-between items-center rounded-md border border-gray-300 bg-white px-3 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
            handleOpenChange(!isOpen);
          }}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          <span className="truncate">
            {value !== undefined && value !== null ? 
              (Array.isArray(value) && value.length > 0 ? 
                `${value.length} selected` : 
                options.find(opt => opt.value === value && opt.type !== 'divider' && opt.type !== 'nested')?.label || placeholder) : 
              placeholder}
          </span>
          {showDropdownButton && (
            <ChevronDownIcon 
              className={`ml-2 h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""}`} 
              aria-hidden="true" 
            />
          )}
        </button>
      )}

      {renderDropdown()}
    </div>
  );
};

// Nested dropdown component
const NestedDropdown = ({ option, onSelect, value, variant, isHighlighted }) => {
  const [isNestedOpen, setIsNestedOpen] = useState(false);
  const nestedRef = useRef(null);
  
  // Handle hover to open nested dropdown
  const handleMouseEnter = () => {
    if (!option.disabled) {
      setIsNestedOpen(true);
    }
  };
  
  const handleMouseLeave = () => {
    setIsNestedOpen(false);
  };
  
  return (
    <div 
      ref={nestedRef} 
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`
          ${option.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
          ${option.destructive ? "text-red-600 hover:bg-red-50" : "hover:bg-gray-100"}
          ${isHighlighted ? "bg-gray-100" : ""}
          w-full px-3 py-1.5 text-left flex items-center justify-between text-xs
        `}
      >
        <div className="flex items-center">
          {option.icon && (
            <span className={`mr-2 ${option.destructive ? "text-red-600" : ""}`}>
              {option.icon}
            </span>
          )}
          <span className={option.destructive ? "text-red-600" : ""}>
            {option.label || ""}
          </span>
        </div>
        <ChevronDownIcon 
          className="ml-2 h-3 w-3 -rotate-90" 
          aria-hidden="true" 
        />
      </div>
      
      {isNestedOpen && (
        <div className="absolute left-full top-0 mt-0 ml-1 z-[9999] bg-white border border-gray-200 rounded-md shadow-lg py-1 min-w-[200px] text-xs">
          {option.items && option.items.map((item, idx) => {
            if (item.type === "divider") {
              return <div key={`nested-divider-${idx}`} className="border-t border-gray-200 my-1"></div>;
            }
            
            const isSelected = variant === "checkbox" 
              ? Array.isArray(value) && value.includes(item.value)
              : value === item.value;
            
            return (
              <div
                key={idx}
                className={`
                  ${item.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
                  ${item.destructive ? "text-red-600 hover:bg-red-50" : "hover:bg-gray-100"}
                  ${isSelected ? "bg-gray-50" : ""}
                  w-full px-3 py-1.5 text-left flex items-center text-xs
                `}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!item.disabled) {
                    onSelect(item);
                  }
                }}
              >
                {variant === "checkbox" && (
                  <div className="mr-2 w-3 h-3 flex items-center justify-center">
                    {isSelected && <CheckIcon className="h-3 w-3 text-purple-600" />}
                  </div>
                )}
                
                {variant === "radio" && (
                  <div className="mr-2 w-3 h-3 border rounded-full flex items-center justify-center">
                    {isSelected && (
                      <div className="w-1.5 h-1.5 rounded-full bg-purple-600"></div>
                    )}
                  </div>
                )}
                
                {item.icon && (
                  <span className={`mr-2 ${item.destructive ? "text-red-600" : ""}`}>
                    {item.icon}
                  </span>
                )}
                
                <span className={item.destructive ? "text-red-600" : ""}>
                  {item.label || ""}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

SelectDropdown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        icon: PropTypes.node,
        disabled: PropTypes.bool,
        destructive: PropTypes.bool,
        type: PropTypes.oneOf(["default", "divider", "nested"]),
        items: PropTypes.array, // For nested dropdowns
      }),
      PropTypes.shape({
        type: PropTypes.oneOf(["divider"]).isRequired,
      }),
    ])
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  position: PropTypes.oneOf(["bottom", "top", "auto"]),
  variant: PropTypes.oneOf(["default", "checkbox", "radio"]),
  maxHeight: PropTypes.number,
  showDropdownButton: PropTypes.bool,
  containerRef: PropTypes.object,
  stopPropagation: PropTypes.bool,
  customTrigger: PropTypes.node,
};

export default SelectDropdown;
